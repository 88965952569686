import React from 'react';
import PropTypes from 'prop-types';
import { Alert, } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';




const WhatIsXLending = props => {
 
  return (

    <div>
     
        <Alert severity={"info"}>
                  <Typography>
                  Founded in 2020, xLending platform is provided by Win Innovation K.K, a Japan-based technology company 
                  that has developed proprietary technology to increase returns by providing margin funding 
                  for bitcoin and other digital assets. 
                  For a user to lend their digital assets on digital asset exchanges like Bitfinex, Poloniex, 
                  users are required to calculate and set a number of parameters and manually create loans on a constant basis. 
                  This results in missed interest due to the loan being priced too high or low and requires continuous monitoring by users.
                  <br/>
                <Divider/>
                xLending’s technology optimizes the lending process by having its bot facilitate the lending of funds at optimal interest rates, 
                based on an algorithm developed by the company, with constant monitoring of the digital asset marketplace. 
                This results in increased returns and less time spent monitoring lending accounts. 
                To open an account with xLending all a user needs to do is register an account and link their API credentials 
                from the exchanges to the xLending account. 
                There is no additional risk in using xLending as there is no ability for xLending (or a hacker) to withdraw or trade 
                a user’s position without a user’s private key. 
                <br/>
                <Divider/>
                <br/>
                Features of "xLending"
                <br/>
                <br/>■ Earn a decent profit with lesser risk.
                <br/>■ Lending is automatic and hassle-free. 
                <br/>■ It supports 24 kinds of virtual currency depends on the Bitfinex and Poloniex exchange.
                <br/>■ It's fixed cost to use the platform. You can choose a subscription plan according to your budget in xLending. No credit deposit needed. 
                <br/>■ It is affordable plan relative to the interest to be earned. In the case of the basic plan, if you operate one million yen worth of virtual currency (10% annual interest) for one month, you will earn 10,000 yen, so the $ 5 commission is not a big deal.
                <br/>■ We support Japanese, Chinese and English. xLending's WinInnovation Co., Ltd. is a Japanese company headquartered in Ueno, Tokyo, so it's safe. "XLending" is available in Japanese, Chinese and English, and the usage guide has been compiled for easy understanding.
                <br/>
                <br/>
                The lending mechanism is set up using the automated trading API provided by "Poloniex" or "Bitfinex". 
                After that, the loan is automatically executed using the statistical algorithm, which is a technology of xLending. 
                If you do lending, try to make good use of xLending.
                </Typography>
                </Alert>
          
    </div>

    
  );
};

WhatIsXLending.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatIsXLending;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  image: {
    width: "100%",
    height: "100%",
  },


}));


const HowMonarchPaycn = props => {
  const { className, } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
            1. 单击“Subscribe”以注册您想要的配套
            <br/>
            2. 选择Monarch Pay付款方式
            <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/payment-2.png"} width="100%" height="600"/>
          </div>
            3. 首先，您需要拥有Monarch Wallet
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-1.png"} />
          </div>
          <br/>
            4. 你可以创建新钱包或导入现有钱包
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-2.png"}  />
          </div>
          <br/>
            5. 准备好Monarch Pay钱包后，请在xLending的文本框中存储Public ETH Address。
          <br/>
            6. 单击“下一步”，按照说明进行操作
          <br/>
            7. 它将把您带向到Monarch Pay钱包中的发票。请登录到钱包
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-4.png"} />
          </div>
          <br/>
            8. 你将看到配套的价钱。之后请输入一下的资料
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-6.png"} />
          </div>
          <br/>
            9.  电子邮件地址：你在xLending中使用的电子邮件。请填写于UserName / Email的空栏里
            <br/>
            10. 用户ID：你在xLending里可以看到你的用户ID，请填写于Additional Information的空栏里。
            <br/>
            11. 最后，点击Subscribe
          
            <br/>
            <br/>
            <Divider/>
            在Monarch Pay完成付款后，请等待一段时间以便等待系统的更新
            <br/>
            如果30到60分钟后，xLending仍显示“尚未收到付款”的话，请别着急。你可以通过support@xlending.cc与我们联系。
          </Typography>
        </Alert>
    </div>

    
  );
};

HowMonarchPaycn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowMonarchPaycn;

// Replace this section with the config file of your own Firebase project. 
// const firebaseConfig = {
//   apiKey: "AIzaSyC8tGLgFRbO6RQEzB5sLmZT4lLIsCxeX3o",
//   authDomain: "cryptolending-7b810.firebaseapp.com",
//   databaseURL: "https://cryptolending-7b810.firebaseio.com",
//   projectId: "cryptolending-7b810",
//   storageBucket: "cryptolending-7b810.appspot.com",
//   messagingSenderId: "530309622166",
//   appId: "1:530309622166:web:f29c67d5d5da07cb6c15b7",
//   measurementId: "G-5QDGNL89JX"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCjs3uqRhiPLsW-qMq9QUbz7l6eYXhh9ss",
  authDomain: "xlending-cc.firebaseapp.com",
  databaseURL: "https://xlending-cc.firebaseio.com",
  projectId: "xlending-cc",
  storageBucket: "xlending-cc.appspot.com",
  messagingSenderId: "568674009023",
  appId: "1:568674009023:web:b46d26f4e531e070652b0a",
  measurementId: "G-M57FJLL9VF"
};

export default firebaseConfig;
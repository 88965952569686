import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Bitfinex
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2,0,0,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,0)
    }
  }
}));

const MarketRates = props => {
  //const { ...rest } = props;
  const classes = useStyles();

 
  return (
    <div className={classes.root}>
     
       
          <Bitfinex />
        
    </div>
  );

};

export default MarketRates;

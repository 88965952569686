import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const HowPoloniexja = props => {
  const { className, } = props;
  

  const classes = useStyles();

  return (

    <div>
      
        <Alert severity={"info"}>
            <Typography>
            1．	Poloniexのホームページにログインする　https://www.poloniex.com/
            <br/>
            2．	下記、［API Keys］をクリックする
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-1.png"} width="100%"/>
            </div>
            3．	下記、［Enable API］をクリックする
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-2.png"} width="100%"/>
            </div>
            <br/>
            4. クリック後、2段階認証、メール認証などを経て以下の画面に移動します。そして、［Create New Key］をクリックしてください。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            5．	【重要】下記のように設定してください。［Enable Trading］にチェックを入れてください。下記のように設定すると、APIがシステム外で使用できないようになります。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-4.png"} width="100%"/>
            </div>
            <br/>
            6．	上記のように発行されます
            【重要】ここで発行されたAPI KeyとAPI Key Secretは、必ず、お手元に記録をしてください。Poloniexのホームページ上では2度と確認できません。
            <br/>
            
            7．	次に、xLendingのダッシュボードに戻ります。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
            8．	上記のように、 xLendingダッシュボードの［APIの設定］をクリックします。
            <br/>
            9．	下記にように、Poloniex APIキーとシークレットコードを入力します。
            <br/>
            
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-6.png"} width="100%"/>
            </div>
            <br/>
            10．Poloniex APIキーとシークレットコードを入力したら、［テストPoloniex］をクリックしてください。
            <br/>

            11．【重要】最後に、［更新］をクリックしてください。
            <br/>
            12．PoloniexにてAPIを設定し、xLendingと連結が終了しました。

            <br/>
            
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowPoloniexja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPoloniexja;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowNotificationja = props => {
  const { className, ...rest } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          LINE通知を設定するには、まずLINEからトークンを取得する必要があります
          <br/>
          1. <a href="https://notify-bot.line.me/my/" target="blank">https://notify-bot.line.me/my/</a>にログインしてください。
          <br/>
          2．	下記のように、［トークンを発行する］をクリックしてください。
          <br/>
          
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-1.png"} width="100%"/>
          </div>
          <br/>
          3．	下記のように、［トークン名を記入してください（通知の際に表記されます）］の空欄に、xLending と記入してください。
          <br/>
          4．	下記のように、［１：１でLINE Notifyから通知を受け取る］をクリックしてください。
          <br/>
          5．	下記のように、［発行する］をクリックしてください。
          <br/>
          

          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-2.png"} width="100%"/>
          </div>
          LINE Notifyで1対1のチャットを選択します。この時点でトークンIDが生成されます
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-3.png"} width="100%"/>
          </div>
          <br/>
          6．	トークンが発行されました。下記のように、［コピー］をクリックしてください。
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-4.png"} width="100%"/>
          </div>
          <br/>
          7．	次に、xLendingのダッシュボードに戻ります。
          <br/>
          8．	上記のように、 xLendingダッシュボードの［APIの設定］をクリックします。
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-5.png"} width="100%"/>
          </div>
          <br/>
          9．	下記にように、LINEトークンを入力します。先ほど、コピーしたものを貼り付けてください。
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-6.png"} width="100%"/>
          </div>
          10. LINEトークンを入力したら、［テストLINE］をクリックしてください。
          <br/>
          11．【重要】最後に、［更新］をクリックしてください。
          <br/>
          12．LINEとxLendingと連結が終了しました。

          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowNotificationja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowNotificationja;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { AccountDetails, PasswordChange } from './components';
import firebase from "firebase/app";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    }
  }
}));

const Account= props => {
  const classes = useStyles();
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
  });

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <PasswordChange />
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';





const WhatPlatformcn = props => {
 
  return (

    <div>
     
        
        <Alert severity={"info"}>
                  <Typography>
                      
                  目前，我们対応的交易所是Poloniex和Bitfinex。
                  只要您注册登入，你就能享用我们的Bots服务，还有区块链的Compound Finance的借出服务。
                  </Typography>
                </Alert>
      
    </div>

    
  );
};

WhatPlatformcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPlatformcn;

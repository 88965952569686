import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';



const WhatPlatform = props => {
 
  return (

    <div>
        <Alert severity={"info"}>
                  <Typography>
                      
                  Currently, we support the exchanges Poloniex and Bitfinex for Basic and Premium subscription.
                  For Free user (as long as you sign up), you are able to utilize blockchain Compound Finance Lending service.
                  </Typography>
                </Alert>
              
    </div>

    
  );
};

WhatPlatform.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPlatform;

import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import PopupMessage from '../../../PopupMessage/PopupMessage'

import {
  Card,
  Typography,
  Avatar,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CardHeader,
  IconButton
} from '@material-ui/core';
import PowerOff from '@material-ui/icons/PowerOff';
import PowerOn from '@material-ui/icons/PowerSharp';
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }, 
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

const BotStatus = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const { t } = useTranslation()
  const { user } = useAuth()
  const ref = useRef(null);

  //console.log(t)
  // if (!user) {
  //   props.history.replace('/sign-in')
  // }

  const demoUser = "Nv5FRJEEtyLT9FfE5L828b4KPrz2"

  let { uid: userId } = user || {}
  const [values, setValues] = useState([])
  const [open, setOpen] = React.useState(false);
  const [popupmessage, setPopupmessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFree, setIsFree] = React.useState(true);
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    if (myuser) {
      userId = myuser.uid
    }
  });

  useEffect(() => {
    //console.log(userId)
   
    setIsLoading(true)
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
        const unsubscribe = firebase
          .firestore()
          .collection('bot_config')
          .doc(userId).onSnapshot(doc => {
          setValues(doc.data())
        })
        return () => { unsubscribe() }
      }
    });
    setIsLoading(false)

  }, [])

  useEffect(() => {
    //console.log(userId)
   
    setIsLoading(true)
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
        const unsubscribe = firebase
          .firestore()
          .collection('subscriptions')
          .doc(userId).onSnapshot(doc => {
            //console.log(doc.data())
            if(doc.data() !== undefined)
            {
              if(doc.data().expiry_date !== undefined)
              {
                let expD = moment(new Date(doc.data().expiry_date.seconds * 1000))
                let todayD = new Date()
                if(expD > todayD){
                  setIsFree(false)
                }    
              }
            }
            
        })
        return () => { unsubscribe() }
      }
    });
    setIsLoading(false)

  }, [])

  const handleClickOpenMsg = (msg, status) => {
    ref.current.handleOpen(msg, status);
  };


  let runningstat = 100
  let displayicon
  let displaymessage
  let displayStatus
  let displayTooltips
  if (values.status === "stop") {
    runningstat = 0
    displayicon = <PowerOff className={classes.icon}/>
    displaymessage = t('botsidebarnogood.label') //"Please start your bots!"
    displayStatus = t('botsidebarstop.label')
    displayTooltips = t('botstatus.startengine')
  } else if (values.status === "running") {
    displayicon = <PowerOn className={classes.icon}/>
    displaymessage = t('botsidebargood.label')
    displayStatus = t('botsidebarrunning.label')
    displayTooltips = t('botstatus.stopengine')
  }else{
    // displayicon = <PowerOn className={classes.icon}/>
    // displaymessage = t('botsidebargood.label')
    // displayStatus = <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    // displayTooltips = t('botstatus.stopengine')
    displayicon = <PowerOn className={classes.icon}/>
    displaymessage = t('botsidebargood.label')
    displayStatus = <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    displayTooltips = t('botstatus.stopengine')
 
  }
  
  const getLabelCondition = () => {

    if(isLoading){
      return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    }else{
      //return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
      return displayStatus
    }
  }


  const handleClick = event => {

    
    if(userId === demoUser)
    {
      handleClickOpenMsg("Demo User. Engine Do not start", "error")
      //handleClickOpenMsg("Please subscribe to enable your trial period", "error")
      return
    }

    let msg = ""
    if(values.status === "stop"){
      msg = t('botstatus.startengineconfirm')
    }else{
      msg = t('botstatus.stopengineonfirm')
    }

    //alert(isFree)
    if(isFree){
      handleClickOpenMsg("Please subscribe to enable your trial period", "error")
      //alert("Please subscribe to enable your trial period")
      return
    }else{
      setPopupmessage(msg)
      handleClickOpen()
    }


  };

  // const getStatusProgress = () => {
    
  //   if(values.status === "running"){
  //     return <LinearProgress color="secondary" />
  //   }else{
  //     return <LinearProgress className={classes.progress} value={0} variant="determinate" />
  //   }
  // }
  const UpdateStatus = () => {

      let newstatus = "stop"
      if (values.status === "stop") {
        newstatus = "start"
      } else {
        newstatus = "stop"
      }

      firebase.firestore().collection("bot_config").doc(userId).update({
        "status": newstatus
      })
        .then(function () {

        });
  }

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNo = () => {
    setOpen(false)
  };

  const handleYes = () => {
    UpdateStatus()
    setOpen(false)
  };

  const handleClickHelp = e => {
    e.preventDefault();  
    let desc = t('botsetup.enginedesc') //"The estimated profit is calculated based on accumulation of interest received in \n\n 'Local Symbol Amount' x 'BTC spot rate on the day' for the month x 'latest today JPY or USD rate'"
    ref.current.handleOpen(desc, "info");
  };

  return (
    <div>
    <PopupMessage ref={ref} />
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth = {'sm'}
      keepMounted
      onClose={handleNo}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle id="alert-dialog-slide-title">{"Message"}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
        <Alert severity={"warning"}>
            <AlertTitle><Typography variant="h5">{"Confirm"}</Typography></AlertTitle>
            {popupmessage}
          </Alert>
            
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          No
        </Button>
        <Button onClick={handleYes} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={getLabelCondition()}
        action={
          <IconButton 
            aria-label="settings"
            onClick={handleClickHelp}
            >
            <MoreVertIcon color="secondary" fontSize="small"/>
          </IconButton>
        }
        subheader={displaymessage}
        avatar={
          <Tooltip title= {displayTooltips} arrow='true'>
              <Avatar
                className={classes.avatar}
                onClick={handleClick}
              >
                {displayicon}
              </Avatar>
            </Tooltip>
        }
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
      />
      
    </Card>
    </div>
  );
};

BotStatus.propTypes = {
  className: PropTypes.string
};

export default BotStatus;

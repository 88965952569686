import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowNotificationcn = props => {
  const { className, ...rest } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          要设置LINE通知，您首先需要从LINE获取Token
          <br/>
          1. 登入<a href="https://notify-bot.line.me/my/" target="blank">https://notify-bot.line.me/my/</a>
          <br/>
          2．	单击[Generate Token]，如下所示。
          <br/>
          
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-1.png"} width="100%"/>
          </div>
          <br/>
          3．	如下所示，在[请输入Token名称]的空白处输入xLending。
          <br/>
          4．	单击[１：１でLINE Notify]，如下所示。
          <br/>
          5．	单击[Generate]，如下所示。 
          <br/>
          

          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-2.png"} width="100%"/>
          </div>
          使用LINE Notify选择一对一聊天。此时，将生成Token ID
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-3.png"} width="100%"/>
          </div>
          <br/>
          6．Token已发行。单击[复制]，如下所示。
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-4.png"} width="100%"/>
          </div>
          <br/>
          7．	接下来，返回到xLending
          <br/>
          8．	如上所述，单击xLending上的API设置。
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-5.png"} width="100%"/>
          </div>
          <br/>
          9．	输入您的LINE Token，如下所示。粘贴您先前Copy的内容
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-6.png"} width="100%"/>
          </div>
          10. 输入LINE Token后，单击[Test LINE]。
          <br/>
          11．[重要]最后，单击[更新]。
          <br/>
          
          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowNotificationcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowNotificationcn;

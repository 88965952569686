import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { BotsTable } from './components';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const BotsList  = props => {
  const classes = useStyles();
  const {...rest } = props;
  //const [users] = useState(mockData);

  
  const { user } = useAuth()
  const { uid: userId } = user || {}

  if (!userId) {
    props.history.replace('/sign-in')
  }


  const [symbols, setSymbols] = useState([])

  useEffect(() => {
    if (userId)
      {
        const unsubscribe = firebase
        .firestore()
        .collection('symbols_config')
        .where('user', '==', userId)
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          setSymbols(newValues);
        })
        return () => { unsubscribe() }
      }
  }, [])


  return (
    <div className={classes.root}>
      {/* <BotsToolbar /> */}
      <div className={classes.content}>
        <BotsTable symbols={symbols}/>
      </div>
    </div>
  );
};

export default BotsList;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToStartPoloniex = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            1．	Please login to Poloniex https://www.poloniex.com/
            <br/>
            2．	Click on［BALANCES］→［DEPOSITS WITHDRAWALS］as shown below:
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-1.png"} width="100%"/>
            </div>
            3．	Choose the coins you want to transfer. 【Important：Please ensure you have selected the intended coins address】
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-2.png"} width="100%"/>
            </div>
            4．【Example】As an example、we are selecting Bitcoin BTC as part of the instruction
            <br/>
            5．Please ensure you have selected BCH as follow: 
            <br/>
            6．Click on［Deposit］. You will see the address
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-3.png"} width="100%"/>
            </div>
            <br/>
            7．	Then, please copy the address. Transfer the coin from your own wallet to this address. 
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-4.png"} width="100%"/>
            </div>
            <br/>
            8．	Please ensure you are transfering to the address as shown below. 
                Click on［Wallet］→［Wallets］and it shows the following page.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-5.png"} width="100%"/>
            </div>
            <br/>
            9．	Finally, transfer the funds to the lending account for lending.
             <br/> In order to lend, the currency must be in a Lending account.
             <br/> Click [BALANCE] → [TRNSFER BALANCES].
             <br/> Enter the amount of currency in the [Tranfer] box below.
             <br/> Please select the currency you want to move. In this case, it is BTC.
             <br/> Select the source account and destination account [Lending].
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-6.png"} width="100%"/>
            </div>
           
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToStartPoloniex.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToStartPoloniex;

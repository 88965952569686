import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import { Doughnut  } from 'react-chartjs-2';
import format from 'format-number';
import appGlobalConfig from 'appGlobalConfig';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { useAuth } from "use-auth.js";

const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 350,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));


const Portfolio = props => {
  const { className, mainData, mainTickerPrices, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()

  const theme = useTheme();

  const getTotal = (arr) =>{

    let ttlBal = 0
    arr.forEach(w => {
      let balBTC = w.balanceBTC

      if(isNaN(w.balanceBTC))
      {
        //console.log(w.balanceBTC)
        balBTC = 0
      }
      ttlBal += balBTC
    });
    return ttlBal
  }

  const getBalanceDataPercent = () => {
    
    let myBalDataPercent = []
    let myPayLabel = []
    let myBalData = []

    const ttlBal = getTotal(mainData)
    mainData.forEach(p => {

      if(p.balance > 0){
        let balBTC =  p.balanceBTC
        if(isNaN(p.balanceBTC)){
          balBTC = 0
        }
        let label = p.symbol +"-"+ p.exchange.toUpperCase()
        let portRatio = xlendingUtils.roundTo(balBTC / ttlBal * 100, 1)
        myPayLabel.push(label)
        myBalDataPercent.push(portRatio)
        myBalData.push({
          symbol: p.symbol,
          key : label,
          balance: p.balance
        })
      }
      
    })

    return {myBalDataPercent, myPayLabel, myBalData}
  }

  const {myBalDataPercent, myPayLabel, myBalData} = getBalanceDataPercent()

  const getPortfolioTotal = () => {

    const tickerJPY = "BTCUSD"
    let exRateJPY = mainTickerPrices.find(x=>x.symbol === tickerJPY)
    if(exRateJPY === undefined) return
    
    const ttlBal = getTotal(mainData)
    let total = xlendingUtils.roundTo(exRateJPY.last * ttlBal, 2)
    let desc = t("simulation.portvalue")
    return format({prefix: desc + ': '+ appGlobalConfig.BaseCurrency, suffix: ''})(total)

  }

  const backgroundColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.text.primary,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.text.disabled,
    theme.palette.primary.main,
    theme.palette.secondary.main
  ]

  const getBalanceData = (key) => {

    const balanceData = myBalData
    let bal = balanceData.find(x=>x.key === key)
    return xlendingUtils.roundTo(bal.balance,2) +" "+ bal.symbol
  }

  const options = {
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
      tooltips: {
      enabled: true,
      mode: 'dataset',
      borderWidth: 2,
      callbacks: {
        label: function(tooltipItem, data) { 
          let bal = getBalanceData(data.labels[tooltipItem.index])
          return data.labels[tooltipItem.index] +" " + data.datasets[0].data[tooltipItem.index] + "%" + " Balance:" + bal;
        }
      }
    }
  };

const chartdata = {
  datasets: [
    {
      data: myBalDataPercent,//balanceDataPercent,
      backgroundColor: backgroundColors,
      borderWidth: 2,
      borderColor: theme.palette.white,
      hoverBorderColor: theme.palette.white
    }
  ],
  labels: myPayLabel//label
};

const getLoadingContent = () => {

    return <div className={classes.chartContainer}>
            <Typography variant="h5">
              {getPortfolioTotal()}
            </Typography> 
            <Doughnut
              options={options}
              data={chartdata}
            />
            
          </div>
}
  
return (
  <Card
    {...rest}
    className={clsx(classes.root, className)}
  >
    <CardContent>
      {getLoadingContent()}
    </CardContent>
  </Card>
);
};

Portfolio.propTypes = {
  className: PropTypes.string
};

export default Portfolio;

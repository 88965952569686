import React, { useState, useEffect, } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import HttpsIcon from '@material-ui/icons/Https';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import firebase from "firebase/app";
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  Typography,
  Button,
  CardActions,
  Link,
  Grid,
  CardHeader,
  Avatar
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  },
  actions: {
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center'
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(20),
    //flexBasis: '33.33%',
    //flexShrink: 0,
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: theme.typography.pxToRem(10),
    // },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  quoteContainer2: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
   

    backgroundColor: theme.palette.neutral,
    //height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    //height: '100%',
    height: '600',
    position: 'relative',
    zindex: 0, 

  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 500,
    textAlign: 'center',
  },
  // grid: {
  //    padding: theme.spacing(2, 2, 2),
  //    textAlign: 'center',
  //    justifyContent: 'center',
  //    alignItems: 'baseline',
 
  // },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none'
    // },
    height: 30,
    width: 30
  },
  avatar2: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none'
    // },
    height: 30,
    width: 30
  },
  card: {
    padding: theme.spacing(0, 0, 0),
    //width: "300",
    // textAlign: 'center',
    // justifyContent: 'center',
    //alignItems: 'baseline',
 },
 grid: {
  padding: theme.spacing(1, 1, 1, 1),
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  },
  justifyContent: 'center',
  alignItems: 'center',

},
gridsmall: {
  padding: theme.spacing(1, 1, 1, 1),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
},
 
}));


const LandingHeader = props => {
  const { className, } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  const [rates, setRates] = useState([])
  
//   useEffect(() => {
    
//     let today = new Date()
//     today.setHours(0, 0, 0, 0);
//     const unsubscribe = firebase
//     .firestore()
//     .collection('tickers')
//     .where('type', '==', "f")
//     .where('exchange', 'in', ['bitfinex'])
//     .where('ticker_time', '>=', today)
//     .orderBy("ticker_time", "desc")
//     .limit(8)
//     .onSnapshot((snapshot) => {
      
//       const newValues = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }))
//       let myRates = []

//       // console.log(newValues)
//       // console.log(newValues.length)

//       newValues.forEach(r => { 
        
//         let annAsk = 0.00
//         let finalrate = 0.00
//         if(r.last !== null){
//           finalrate = r.last
//         }else if(r.ask !== null){
//           finalrate = r.ask
//         }


//         if(r.exchange === "bitfinex" || r.exchange === "poloniex"){
//           annAsk = xlendingUtils.getAnnualRate(finalrate)
//         }else{
//           annAsk = xlendingUtils.roundTo(finalrate * 100, 2)
//         }
//         //console.log(annAsk)
//         if(annAsk !== Infinity || !r.symbol.includes("TEST")){
//           myRates.push({
//             symbol: r.symbol,
//             exchange: r.exchange,
//             rate: annAsk
//           })

//         }
//         // }
//       })

//       //console.log(myRates)

//       // array is sorted by band in descending order
//       myRates.sort(xlendingUtils.compareValues('rate', 'desc'));

//       //setSelectedSymbol(myRates[0].symbol)
//       //setSelectedExchange(myRates[0].exchange)
//       setRates(myRates);

//     })
//     return () => { unsubscribe() }
  
// }, [])

const getIcons = (symbol) => {
  //let iconname = "crypto crypto-" + symbol.toLowerCase()

  let finalsymbol = symbol
  if(symbol === "BCHSV"){
    finalsymbol = "BSV"
  }
  if(symbol === "BCHABC"){
    finalsymbol = "BCH"
  }
  if(symbol === "DSH"){
    finalsymbol = "DASH"
  }
  if(symbol === "STR"){
    finalsymbol = "XLM"
  }
  if(symbol === "UST"){
    finalsymbol = "USDT"
  }

  let iconname = "crypto crypto-" + finalsymbol.toLowerCase()
  return <i className={iconname} ></i>
}


const getGridClasses = (i) => {

  if(i<3){
    return classes.grid
  }else{
    return classes.grid
  }

}

const getAvatarClasses = (i) => {

  if(i === 0 || !!(i && !(i%2))){
    return classes.avatar
  }else{
    return classes.avatar2
  }

}

  return (

    <div className={classes.root}>
        <div className={classes.actions}>
            <Typography
                className={classes.quoteText}
                variant="h1"
              >
                
                {t('landing.header')}
              </Typography>
        </div>
        <Typography
                className={classes.quoteText}
                variant="h4"
              >
                {t('landing.header2')}
              </Typography>
        
      <div className={classes.actions}>
        <Card
            className={classes.card}
          >
           
            <Link
                  component={RouterLink}
                  to="/sign-up"
                >
              <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    //onClick={handleOnClickSignIn}
                    startIcon={<HttpsIcon />}
                  >
                    {t('menu.trynow')}
                  </Button>
              </CardActions>
            </Link>
          </Card>
          </div>
          
      {/* <Grid
        className={classes.grid}
        container
      >
          {rates.map((rate, i) => ( 
            <Grid
              className={getGridClasses(i)}
              item
              lg={1}
              sm={2}
              xl={2}
              xs={3}
            >
              <Card className={classes.card}>
                <CardHeader 
                  title= {rate.symbol}
                  subheader= {rate.rate + "%"}
                  titleTypographyProps={{variant:'h6' }}
                  subheaderTypographyProps={{variant:'body1' }}
                  avatar={
                    <Avatar
                      className={getAvatarClasses(i)}
                      >
                        {getIcons(rate.symbol)}
                      </Avatar>  
                  }
                />
              </Card>
            </Grid>


          ))}
          
        
       
       </Grid>
     */}
    </div>
    
  );
};

LandingHeader.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LandingHeader;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert, AlertTitle } from '@material-ui/lab';
import { StatusBullet } from 'components';
import '@icon/cryptocurrency-icons/cryptocurrency-icons.css'
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  CardContent,
  Divider,
  Table,
  TableBody,
  Grid,
  Switch,
  Select,
  MenuItem,
  ButtonGroup,
  TextField,
  Slider,
  Tooltip
} from '@material-ui/core';

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles =  makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 200
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 80,
      margin: 5
    }
  },
  avatar: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  avatarPopup: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  formControlMid: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  text: {
    width: 120,
    margin: 5
  },
  griditem: {
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // }
  },
  tableRowCenter: {
    alignItems: "center",
  },
  buttontext: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  button: {
    textAlign: "center" 
  },
  tableRow: {
    padding: theme.spacing(3)
  },
  tableCell: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
  
}));

const statusColors = {
  active: 'success',
  inactive: 'danger'
};

const Setting = forwardRef((props, ref) => {
  const { className, userplan, subsPlan, symbols, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  let { uid: userId } = user || {}

  //console.log(symbols)
  const [openAdd, setOpenAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [symbols, setSymbols] = useState([])
  const [enableAll, setEnableAll] = useState(true);
  //const [userplan, setUserplan] = useState([])
  
  const [state, setState] = useState({
    id: "",
    //exchangename: 'bitfinex',
    symbol: '',
    strategy: 'basic',
    enable: false,
    action: "",
    atLeastLow : 0.00001
  });


  const handleOpen = (exchange) => {
    setState({
      ...state,
      exchangename: exchange
    })
    setOpen(true);
  }
  
  const handleClose = () => {
    setOpen(false);
  }

  const handleCloseAdd = () => {
    setOpenAdd(false);
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  const getSymbolCollection = () => {

    if(state.exchangename === "bitfinex" && !enableAll){
      return bitfinexSymbols
    }
    if(state.exchangename === "poloniex" && !enableAll){
      return poloniexSymbols
    }

    const mysymbols = []
    if(symbols === undefined) return mysymbols

    symbols.forEach(s => {
      if(s.exchange === state.exchangename){
        mysymbols.push({
          symbol: s.code
        })
      }
    });
  
    //console.log(mysymbols)
    return mysymbols

  }

  const isOverMaxBot = () => {

    if(userplan !== undefined)
    {
      let totalsym = symbols.filter(x=>x.enable === true)
      let selectedPlan = subsPlan.find(x=>x.plan_id === userplan.plan_id)
      // console.log(totalsym.length)
      // console.log(selectedPlan.maxbot)
      if(totalsym.length > selectedPlan.maxbot){
        return true
      }
    }
    return false
  }

  const poloniexSymbols = [
    { symbol: 'ATOM'},
    { symbol: 'USDC'},
    { symbol: 'EOS'},
    { symbol: 'ETC'},
    { symbol: 'ETH'},
    { symbol: 'XRP'},
    { symbol: 'XMR'},
    { symbol: 'STR'},
    { symbol: 'LTC'},
    { symbol: 'DASH'},
    { symbol: 'DOGE'},
    { symbol: 'BCHSV'},
    { symbol: 'BCHABC'},
    { symbol: 'BTC'},
    { symbol: 'USDT'},
  ];

  const bitfinexSymbols = [
    { symbol: 'BAB'},
    { symbol: 'BSV'},
    { symbol: 'BTC'},
    { symbol: 'BTG'},
    { symbol: 'DASH'},
    { symbol: 'DAI'},
    { symbol: 'EDO'},
    { symbol: 'EOS'},
    { symbol: 'ETC'},
    { symbol: 'ETH'},
    { symbol: 'ETP'},
    { symbol: 'EUR'},
    { symbol: 'GBP'},
    { symbol: 'IOTA'},
    { symbol: 'JPY'},
    { symbol: 'LEO'},
    { symbol: 'LTC'},
    { symbol: 'NEO'},
    { symbol: 'OMG'},
    { symbol: 'SAN'},
    { symbol: 'USD'},
    { symbol: 'UST'},
    { symbol: 'XLM'},
    { symbol: 'XMR'},
    { symbol: 'XRP'},
    { symbol: 'XTZ'},
    { symbol: 'ZEC'},
    { symbol: 'ZRX'},
    { symbol: 'XAUT'},
  ];

  const getIcons = (symbol) => {
    
    let iconname = "crypto crypto-" + symbol.toLowerCase()
    return <i class={iconname} ></i>
  }

  const getIsActive = (symbol) => {
    
    if(symbols === undefined) return

    let foundSymbol = symbols.find(x=>x.code === symbol && x.exchange === state.exchangename)
    //console.log(foundSymbol)
    if(foundSymbol !== undefined){
      //console.log(foundSymbol.enable)
      if(foundSymbol.enable === true){
        return "active"
      }
    }
    return "inactive"
  }

  const getStrategy = (symbol) => {
    
    if(symbols === undefined) return
    let foundSymbol = symbols.find(x=>x.code === symbol && x.exchange === state.exchangename)
    if(foundSymbol !== undefined){
      return foundSymbol.strategy
    }
    return ""
  }

  const getMinInt = (symbol) => {
    
    if(symbols === undefined) return
    let foundSymbol = symbols.find(x=>x.code === symbol && x.exchange === state.exchangename)
    if(foundSymbol !== undefined){
      return foundSymbol.offer.atLeastLow
    }
    return "0.0000"
  }

  const getSymbolID = (symbol, i) => {
    
    if(symbols === undefined) return
    let foundSymbol = symbols.find(x=>x.code === symbol && x.exchange === state.exchangename)
    if(foundSymbol !== undefined){
      return foundSymbol.id
    }
    return symbol + i
  }
  
  const handleSwitchActive = name => event => {
    //setEnableAll(event.target.checked)
    setState({ ...state, [name]: event.target.checked });
  };

  const handleSwitchChange = name => event => {
    setEnableAll(event.target.checked)
  };

  const handleClickEditUpdate = () => {
    // To update age and favorite color:
    firebase.firestore().collection("symbols_config").doc(state.id).update({
      "enable": state.enable,
      "strategy": state.strategy,
      "offer.atLeastLow": state.atLeastLow
    }).then(function () {
      setOpenAdd(false)
    })
  }



  const handleClickAdd = event => {
    event.preventDefault();

    // To update age and favorite color:
    if(isOverMaxBot()) {
      alert("Exceed number of max bots allowed")
      return
    }

    if(state.action === "add"){
      
      
      firebase.firestore().collection("symbols_config").doc(state.symbol + "_" + state.exchangename + "_" + userId).set({
        code: state.symbol,
        exchange: state.exchangename,
        user: userId,
        enable: state.enable,
        strategy: state.strategy,
        sleep: 5,
        offer: {
          amount: 9999999999,
          frrMultipleLow: 1.0,
          frrMultipleHigh: 1.0,
          atLeastLow: state.atLeastLow,
          atLeastHigh: 0.1,
          minOrderSize: 0.0,
          orderCount: 2,
          randomAmountsPercent: 0,
          easing: "linear"
        },
        alert: {
          rate: 0.01,
          maxFrequency: 5,
          alertMessage: "alert message here."
        }
      }).then(function () {
        setOpenAdd(false)
      })
    }else{
      handleClickEditUpdate()
    }
    
  };

  
  const handleAddNewOpen = (iD, symbol) => event => {
    
    let action = "add"
    let foundSymbol = symbols.find(x=>x.code === symbol && x.exchange === state.exchangename)
    if(foundSymbol !== undefined){
      setState({
        id: foundSymbol.id,
        exchangename: foundSymbol.exchange,
        strategy: foundSymbol.strategy,
        enable: foundSymbol.enable,
        symbol: foundSymbol.code,
        atLeastLow : foundSymbol.offer.atLeastLow,
        action: "edit"
      })
    }else{
      setState({
        ...state,
        symbol: symbol,
        action: "add"
      })
    }
    setOpenAdd(true)
  };

  const handleChange = name => event => {
    
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleClickDelete = (removeid) => event => {
    
    if(removeid !== ""){
      firebase.firestore().collection('symbols_config').doc(removeid).delete().then(async () => {
        setState({
          id: "",
          //exchangename: 'bitfinex',
          symbol: '',
          strategy: 'basic',
          enable: false,
          action: "",
          atLeastLow : 0.00001
        })
        setOpenAdd(false)
        alert("Bot Deleted")
        //handleClose()
      })
    }
  }

  const handleSliderChange = (event, newValue) => {
    //console.log(newValue)
    setState({
      ...state,
      atLeastLow : newValue
    })
  };

  const getDescStrategy = (stra) => {
    
    let msg = ""
    if (stra === "basic") {
      msg = 'The strategy will split the quantity to be lending out at different times and rate'
    }
    if (stra === "premium") {
      msg = 'The goal of the premium strategy is to retain part of your available funds to spread it over time, so that your loans are spread over small chunks and you can benefit from an average rate over time.'
    }
    if (stra === "advance") {
      msg = 'The advance strategy will lend your funds relative to the interest rates at the other platforms. It will take the maximum rate of all platforms and modify it by the offset if configured.'
    }
    return msg
  }


  const getConvertedDailyRate = (dailyRate) => {
    //console.log(dailyRate)
    let annFrr = dailyRate
    return annFrr + "%"
  }


  const handleChangeAnnualRate = event => {
    
    let pece = event.target.value / 100

    console.log(pece)
     setState({
      ...state,
      atLeastLow : pece
    })

  };
  
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog 
          open={openAdd} 
          onClose={handleCloseAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth = {'xs'}
          >
          <DialogContent>
            <DialogContentText>
              <Typography variant="h3">
                {t('botsetup.addnewcointitle') + "(" + state.symbol +")"}
              </Typography> 
            </DialogContentText>
            <Table>

            <TableRow className={classes.tableRowCenter}>
              <TableCell>  
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{t('botsetup.inactive')}</Grid>
                    <Grid item>
                      <AntSwitch
                        checked={state.enable}
                        onChange={handleSwitchActive('enable')}
                        color="primary"
                        inputProps={{
                          name: 'add_enable',
                          id: 'add_enable',
                        }}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>{t('botsetup.active')}</Grid>
                  </Grid>
                </TableCell>
              </TableRow>

              {/* <TableRow className={classes.tableRowCenter}>
                <TableCell>
                    <div>
                      <Select
                        fullWidth
                        value={state.strategy}
                        disabled={getdisable()}
                        onChange={handleChange('strategy')}
                        inputProps={{
                          name: 'strategy',
                          id: 'strategy',
                        }}
                      >
                      <MenuItem value="basic">{t('strategy.basic')}</MenuItem>
                      <MenuItem value="premium">{t('strategy.premium')}</MenuItem>
                      <MenuItem value="advance">{t('strategy.advance')}</MenuItem>
                      </Select>
                    </div>
                    <div>
                      <Alert severity={"info"}>
                        <AlertTitle><Typography variant="h5">{"Description"}</Typography></AlertTitle>
                          {getDescStrategy(state.strategy)}
                      </Alert>
                    </div>
                  </TableCell>
              </TableRow> */}
              <TableRow className={classes.tableRowCenter}>
                <TableCell>
                <Typography gutterBottom>{t('botsetup.mininterest')} (APY %)</Typography>
                <Slider 
                  min={0.00001}
                  step={0.00001}
                  max={0.150}
                  //valueLabelDisplay="on" 
                  value={state.atLeastLow}
                  onChange={handleSliderChange} 
                  aria-labelledby="continuous-slider" />
                    <TextField
                      label={t('botsetup.annualrate')}
                      fullWidth
                      style={{ marginTop: '1rem' }}
                      margin="dense"
                      name="atLeastLow"
                      value={xlendingUtils.getAnnualRate(state.atLeastLow/100)+"%"}
                      variant="outlined"
                      onChange={handleChangeAnnualRate}
                    />
                    <TextField
                      label={t('botsetup.dailyrate')}
                      fullWidth
                      style={{ marginTop: '1rem' }}
                      margin="dense"
                      name="atLeastLowdaily"
                      value={getConvertedDailyRate(state.atLeastLow)}
                      variant="outlined"
                    />
                 </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
          <DialogActions>
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                onClick={handleCloseAdd} 
                color="primary"
                startIcon={<CancelIcon />}>
              {t('common.cancel.button')}
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={handleClickDelete(state.id)}
              >
                {t('common.delete.button')}
              </Button>
              <Button 
                onClick={handleClickAdd} 
                color="primary"
                startIcon={<AddCircleIcon />}>
              {t('common.save.button')}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'sm'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <AntSwitch
                    checked={enableAll}
                    onChange={handleSwitchChange('enable')}
                    value="enable"
                    color="primary"
                  />
                </Grid>
                <Grid item className={classes.griditem}>{t('botsetup.showall')}</Grid>
              </Grid>
            </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity={"info"}>
              <AlertTitle><Typography variant="h5">{state.exchangename}</Typography></AlertTitle>
                {t('botsetup.whichcoindesc') + state.exchangename +"?"}
            </Alert>
          </DialogContentText>
          <DialogContentText>
            <Divider />
            <CardContent className={classes.content}>
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell>{""}</TableCell>
                      <TableCell>{t('botsetup.symbol')}</TableCell>
                      <TableCell>{t('botsetup.inactive')}</TableCell>
                      <TableCell className={classes.nameContainer}>{t('botsetup.strategy')}</TableCell>
                      <TableCell className={classes.nameContainer}>{t('botsetup.mininterest')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSymbolCollection().map((s, i) => (
                        <TableRow
                        hover
                        key={getSymbolID(s.symbol, i)}
                        onClick={handleAddNewOpen(getSymbolID(s.symbol, i), s.symbol)}
                        >
                        <Tooltip title= {s.symbol} arrow='true'>
                          <TableCell className={classes.cell} align="right">
                            <Typography gutterBottom variant="h1" component="h1">
                              {getIcons(s.symbol)}
                            </Typography> 
                          </TableCell>
                        </Tooltip>
                        <TableCell align="left">
                            {s.symbol}
                        </TableCell>
                        <TableCell>
                          <div className={classes.statusContainer}>
                            <StatusBullet
                              className={classes.status}
                              color={statusColors[getIsActive(s.symbol)]}
                              size="md"
                            />
                            </div>
                        </TableCell>
                        <TableCell className={classes.nameContainer}>{getStrategy(s.symbol).toUpperCase()}</TableCell>
                        <TableCell className={classes.nameContainer}>{getMinInt(s.symbol)}%</TableCell>
                      </TableRow>
                     
                    ))}
                  </TableBody>
                </Table>
            </CardContent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary"
            startIcon={<CancelIcon />}>
            {t('common.close.button')}
          </Button>
        </DialogActions>
      </Dialog>
      
    </Card>
  );
});

Setting.propTypes = {
  className: PropTypes.string
};

export default Setting;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowNotificationTelegram = props => {
  const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
      <Typography>
            Check on Telegram in xLending Setup tab
          <br/>
            Click on "Connect Telegram"
          <br/>
            It popup and ask to open it in Telegram in desktop or mobile version
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/telegram-1.png"} width="75%" height="200"/>
          </div>
            Click "START" in Telegram.
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/telegram-2.png"} width="75%" height="200"/>
          </div>
            Back to xLending page, click TEST Telegram button to ensure it works properly
          <br/>
            Click Update to save the preference
          <br/>
          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowNotificationTelegram.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowNotificationTelegram;

//import React, { useRef } from 'react';
import React, { useState, useEffect, } from "react";
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import '@icon/cryptocurrency-icons/cryptocurrency-icons.css'
import palette from 'theme/palette';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import format from 'format-number';
import { Link as RouterLink } from 'react-router-dom';
import appGlobalConfig from 'appGlobalConfig';
import xlendingUtils from 'xlendingUtils'


import {
  Card,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  Grid,
  CardHeader,
  Avatar,
  TextField,
  ButtonGroup,
  Button,
  CardActions,
  Tooltip,
  IconButton,
  Divider,
  FormControl,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableBody
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,2,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,0,0),
    }
  },
  content: {
    marginTop: theme.spacing(1),
    maxHeight: 400,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 250,
    }
  },
  contentgraph: {
    marginTop: theme.spacing(1),
    height: 300,
    //minHeight: 200,
    maxHeight: 300,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 200,
    }
  },
  table: {
    height: 3,
    border: 1,
  },
  chartContainer: {
    height: 400,
    //maxHeight: 500,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    }
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  grid: {
    padding: theme.spacing(0,1,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,1,0),
    }
  },
  tooltip: {
    //background: grey[700],
    borderRadius: 2,
    //color: common.fullWhite,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    minHeight: theme.spacing.unit * 4,
    lineHeight: '32px',
    opacity: 0,
    padding: `0 ${theme.spacing.unit}px`,
    transform: 'scale(0)',
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.up('sm')]: {
      minHeight: 22,
      lineHeight: '22px',
      padding: `0 ${theme.spacing.unit}px`,
      fontSize: theme.typography.pxToRem(10),
    },
  },
  text: {
    textAlign: "right" ,
    width: "100%",
    textAlign: 'right'
  },
  inputText: {
    //color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '28px',
    textAlign: 'right',
  },
  cellhidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
}));


const supportedSymbols = [
  { symbol: 'BAB'},
  { symbol: 'BSV'},
  { symbol: 'BTC'},
  { symbol: 'BTG'},
  { symbol: 'DASH'},
  { symbol: 'EDO'},
  { symbol: 'EOS'},
  { symbol: 'ETC'},
  { symbol: 'ETH'},
  { symbol: 'IOTA'},
  { symbol: 'LEO'},
  { symbol: 'LTC'},
  { symbol: 'NEO'},
  { symbol: 'OMG'},
  { symbol: 'SAN'},
  { symbol: 'USD'},
  { symbol: 'UST'},
  { symbol: 'XLM'},
  { symbol: 'XMR'},
  { symbol: 'XRP'},
  { symbol: 'XTZ'},
  { symbol: 'ZEC'},
  { symbol: 'ZRX'},
];


const ExpectedProfit  = props => {

  const { exRates, subsPlans, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  // const ref = useRef(null);

  const tickerPrices = exRates
  console.log(exRates)
  const { user } = useAuth()
  const [positions, setPositions] = useState([])
  
  const [state, setState] = useState({
    symbol: '',
    quantity: 0,
  });

  const [rates, setRates] = useState([])
  const [radiovalue, setRadioValue] = React.useState('bydays')
  const [rateTypeValue, setRateTypeValue] = React.useState('1davg')
  
  const theme = useTheme();
  
  useEffect(() => {
    getAvgData("BTC", "bitfinex")

  }, [])



  const getPlanDesc = (plan) =>{
  
    const selectedPlan = subsPlans.find(x=>x.plan_id === plan)
    //console.log(selectedPlan)
    if(selectedPlan === undefined) return 
    
    return selectedPlan.plan_name + " (" + format({prefix: appGlobalConfig.BaseCurrency + " ", suffix: ''})(selectedPlan.plan_price) + ")"
  
  }

  const getPlanFeeAndLimit = (plan) =>{
  
    const selectedPlan = subsPlans.find(x=>x.plan_id === plan)
    if(selectedPlan === undefined) return 
    
    let planFee = selectedPlan.plan_price
    let planLimit = selectedPlan.loanlimit
     
    return {planFee, planLimit}
  
  }

  const getPlanLoanLimit = (plan) =>{
  
    const selectedPlan = subsPlans.find(x=>x.plan_id === plan)
    //console.log(selectedPlan)
    if(selectedPlan === undefined) return 
    
    return format({prefix: selectedPlan.plan_ccy, suffix: ''})(selectedPlan.loanlimit) + ")"
  
  }

  // const getDateRange = () => {

  //   let date = new Date();
  //   //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //   date.setHours(0, 0, 0, 0);
  
  //   var today = new Date();
  //   today.setHours(24, 0, 0, 0);
  //   var firstDay = new Date(today)
  //   firstDay.setDate(today.getDate() - 30);
  //   let fromDate = firstDay
  
  //   return {fromDate, today}
  // }

  const getAvgData = (mySymbol, myEx) => {
    
    if(mySymbol === "USDt") {
      mySymbol = "UST"
    }
    

    let foundItem = rates.find(x=>x.symbol === mySymbol)
    if(foundItem !== undefined){
      console.log("use cache data")
      return
    }

    console.log("read from firestore")
    let dateRange = xlendingUtils.getDate30DRange()
    
    const unsubscribe = firebase
    .firestore()
    .collection('funding_rate_avg')
    .where('symbol', '==', mySymbol)
    .where('exchange', '==', myEx)
    .where("rate_time", '>=', dateRange.fromDate)
    .where("rate_time", '<=', dateRange.today)
    .orderBy("rate_time", "desc")
    .limit(30)
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        key: doc.data().rate_time.seconds * 1000,
        ...doc.data()
      }))

      //console.log(newValues)
      let myPayData = []

      newValues.forEach(r => { 
        //let annAsk = getAnnualRate(r.ask)
        myPayData.push({
          key: r.key,
          symbol: r.symbol,
          avg_frr_30d: r.avg_frr_30d,
          avg_rate_1d: r.avg_rate_1d,
          avg_rate_30d: r.avg_rate_30d,
          last: r.last,
          rate_time: r.rate_time
        })
      })
        rates.forEach(r => {
          myPayData.push({
            key: r.key,
            symbol: r.symbol,
            avg_frr_30d: r.avg_frr_30d,
            avg_rate_1d: r.avg_rate_1d,
            avg_rate_30d: r.avg_rate_30d,
            last: r.last,
            rate_time: r.rate_time
          })
        });

      
      setRates(myPayData)
    })
    return () => { unsubscribe() }
  }

  const getIcons = (symbol) => {

    let finalsymbol = symbol
    if(symbol === "BCHSV"){
      finalsymbol = "BSV"
    }
    if(symbol === "BCHABC"){
      finalsymbol = "BCH"
    }
    if(symbol === "DSH"){
      finalsymbol = "DASH"
    }
    if(symbol === "STR"){
      finalsymbol = "XLM"
    }
    if(symbol === "UST"){
      finalsymbol = "USDT"
    }

    let iconname = "crypto crypto-" + finalsymbol.toLowerCase()
    return <i className={iconname} ></i>
  }

  const handleClickDelete = (symbol) => event => {
    event.preventDefault();
    //console.log(positions)
    const filteredItems = positions.filter(t => t.symbol !== symbol)
    //console.log(filteredItems)
    setPositions(filteredItems)

    // if(symbol !== "BTC"){
    //   const filteredRates = rates.filter(t => t.symbol !== symbol)
    //   console.log(filteredRates)
    //   setRates(filteredRates)
    // }
  }

  const getTotalProfit = () => {

    const dt = getDatasetsByDays()

    let total = 0
    let subtotal = 0
    dt.forEach(e => {
      subtotal = subtotal + e.subtotal
    });
    //console.log (subtotal)
    total = total + subtotal

    let totalProfit = xlendingUtils.roundTo(total, 2)
    let totalPort = xlendingUtils.roundTo(getPortfolioTotal(),2)
    let monthlyReturns = totalProfit / totalPort
    let annualReturns = xlendingUtils.getAnnualRateByMonth(monthlyReturns) 

    // console.log(totalProfit)
    // console.log(totalPort)
    // console.log(monthlyReturns)

    return {totalProfit, monthlyReturns, annualReturns, totalPort}
  }

  const getPortfolioTotal = () => {

    const tickerJPY = "BTCUSD"
    let totalValue = 0
    positions.forEach(p => {
      
      if(p.symbol !== ""){
        
        let BTCRate = 1
        let ticker
        if(p.symbol === "USD" || p.symbol === "USDt" || p.symbol === "UST")
        {
          ticker = "BTCUSD" 
        }else{
          ticker = p.symbol + "BTC"
        }
        
        let exRateBTC = tickerPrices.find(x=>x.symbol === ticker)
        let exRateJPY = tickerPrices.find(x=>x.symbol === tickerJPY)
        
        if(p.symbol !== "BTC" && exRateBTC !== undefined){
          BTCRate = exRateBTC.last
        }

        if(p.symbol === "UST" || p.symbol === "USDt" || p.symbol === "USD"){
          BTCRate = 1 / exRateBTC.last
        }
        //console.log(BTCRate)

        let basevalue = p.quantity * BTCRate * exRateJPY.last
        totalValue = totalValue + basevalue
      }
    });
    return totalValue
  }

  const getDatasetsByDays = () => {

    let arr = []
    let i = 0
    //let localProfitarr = []
    const tickerJPY = "BTCUSD"

    positions.forEach(p => {
      
      if(p.symbol !== ""){

        let BTCRate = 1
        let ticker
        if(p.symbol === "USD" || p.symbol === "USDt" || p.symbol === "UST")
        {
          ticker = "BTCUSD" 
        }else{
          ticker = p.symbol + "BTC"
        }

        let exRateBTC = tickerPrices.find(x=>x.symbol === ticker)

        if(p.symbol !== "BTC" && exRateBTC !== undefined){
          BTCRate = exRateBTC.last
        }

        if(p.symbol === "UST" || p.symbol === "USDt" || p.symbol === "USD"){
          BTCRate = 1 / exRateBTC.last
        }

        let exRateJPY = tickerPrices.find(x=>x.symbol === tickerJPY)

        // console.log(exRateBTC)
        // console.log(exRateJPY)
        let firstData = rates.filter(x=>x.symbol === p.symbol)
        
        let dataset1 = []
        let subTotal = 0

        firstData.sort(xlendingUtils.compareValues('key', 'asc'));
        //calc each day profit in JPY
        firstData.forEach(r => {
          let rateSelected = r.avg_rate_1d
          //let localprofit = r.ask * p.quantity
          if (rateTypeValue === "1davg"){
            rateSelected = r.avg_rate_1d
          }else if (rateTypeValue === "frravg"){
            rateSelected = r.avg_frr_30d
          }else if (rateTypeValue === "last"){
            rateSelected = r.last === undefined ? 0 : r.last
          }

          let localprofit = rateSelected * p.quantity
          let baseprofit = localprofit * BTCRate * exRateJPY.last
          dataset1.push(baseprofit)
          subTotal = subTotal + baseprofit
        });

        arr.push({
          label: p.symbol,
          backgroundColor: backgroundColors[i],
          data: dataset1,
          subtotal: subTotal
        })
        i++
        // localProfitarr.push({
        //   symbol:p.symbol,
        //   subtotal: subTotal
        // })
      }
    });
    return arr
  }

  const getDatasetsTotalBySymbol = () => {

    let arr = []
    let i = 0
    //let localProfitarr = []
    let dataset1 = []
    const tickerJPY = "BTCUSD"

    positions.forEach(p => {
      
      if(p.symbol !== ""){
        let BTCRate = 1
        let ticker
        if(p.symbol === "USD" || p.symbol === "USDt" || p.symbol === "UST")
        {
          ticker = "BTCUSD" 
        }else{
          ticker = p.symbol + "BTC"
        }
        let exRateBTC = tickerPrices.find(x=>x.symbol === ticker)
        
        if(p.symbol !== "BTC" && exRateBTC !== undefined){
          BTCRate = exRateBTC.last
        }

        if(p.symbol === "UST" || p.symbol === "USDt" || p.symbol === "USD"){
          BTCRate = 1 / exRateBTC.last
        }

        let exRateJPY = tickerPrices.find(x=>x.symbol === tickerJPY)
        
        let firstData = rates.filter(x=>x.symbol === p.symbol)
        let subTotal = 0
        firstData.forEach(r => {
          let rateSelected = r.avg_rate_1d
          //let localprofit = r.ask * p.quantity
          if (rateTypeValue === "1davg"){
            rateSelected = r.avg_rate_1d
          }else if (rateTypeValue === "frravg"){
            rateSelected = r.avg_frr_30d
          }else if (rateTypeValue === "last"){
            rateSelected = r.last === undefined ? 0 : r.last
          }

          let localprofit = rateSelected * p.quantity
          
          let baseprofit = localprofit * BTCRate * exRateJPY.last
          subTotal = subTotal + baseprofit
        });
        dataset1.push(subTotal)
        i++
      }
    });

    //arrange by symbol
    arr.push({
      label: "",
      backgroundColor: backgroundColors[0],
      data : dataset1
      //data: [12,23,33,34,45]
    })


    return arr
  }

  const getLabelsTotalBySumbol = () => {
    
    let arr = []
    if(positions === undefined) {
      return arr
    }

    for(let i=0; i< positions.length; i++){
      arr.push(positions[i].symbol)
    }
    return arr
  }

  const getLabelsByDays = () => {
    
    let arr = []
    
    let dummRate = rates.filter(x=>x.symbol  === "BTC")
    // console.log(rates)
    // console.log(dummRate)
    if(dummRate !== undefined){
      for(let i=1; i< dummRate.length + 1; i++){
        arr.push(i)
      }
    }else{
      if(positions[0] != undefined){
        dummRate = rates.filter(x=>x.symbol  === positions[0].symbol)
        for(let i=1; i< dummRate.length + 1; i++){
          arr.push(i)
        }
      }
    }
    // for(let i=1; i< 31; i++){
    //   arr.push(i)
    // }
    return arr
  }

  const handleClickSave = event => {

    // positions.forEach(p => {
    //   firebase.firestore().collection("simulate_ports").doc(userId+"-"+p.symbol).set({
    //     "symbol": p.symbol,
    //     "quantity": parseFloat(p.quantity),
    //     "user": userId
    //   })
    // });

  }


  const handleClickAdd = event => {
    event.preventDefault();
    let result = positions.filter(t => t.symbol === state.symbol)
    //console.log(result)
    if(result.length > 0){
      //handleClickMsgOpen(t('common.msg.duplicate'))
      return
    }

    //getData(state.symbol, "bitfinex")
    getAvgData(state.symbol, "bitfinex")


    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        quantity: element.quantity
      })
    });

    p.push({
      symbol: state.symbol,
      quantity: state.quantity
    })

    setPositions(p)
    
  };

  const handleChange = name => event => {
    
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const getRecommendedPlan = () => {

    let totalV = getTotalProfit()
    //console.log(totalprofit)
    if(totalV.totalProfit <= 0){
      return <div></div>
    }

    

    let msgRecom = ""
    let msgRecom2 = ""
    let plandesc = ""
    let planLimitdesc = ""
    let compProfitValue = totalV.totalProfit * 0.8

    let basicPlan = getPlanFeeAndLimit("B-001")
    let premiumPlan = getPlanFeeAndLimit("P-001")
    //let platinumPlan = getPlanFeeAndLimit("T-001")
    
    if(compProfitValue < basicPlan.planFee){
      plandesc = t('simulation.toosmallnorec') 
    
    }else if(totalV.totalPort <= basicPlan.planLimit && compProfitValue >= basicPlan.planFee) {
      plandesc = getPlanDesc("B-001")
    
    }else if(totalV.totalPort > basicPlan.planLimit && totalV.totalPort <= premiumPlan.planLimit && compProfitValue >= premiumPlan.planFee) {
      
      plandesc = getPlanDesc("P-001")
    
    }else if(totalV.totalPort > basicPlan.planLimit && totalV.totalPort <= premiumPlan.planLimit && compProfitValue >= basicPlan.planFee) {
      plandesc = getPlanDesc("P-001")
    
    }else if(totalV.totalPort > premiumPlan.planLimit && compProfitValue >= premiumPlan.planFee) {
      
      plandesc = getPlanDesc("T-001")
      //planLimitdesc = t('simulation.limitamountmsg') + ": " + getPlanLoanLimit("P-001") + " -> " + t('simulation.contactus') 
    
    }
    else if(totalV.totalPort > basicPlan.planLimit && compProfitValue >= premiumPlan.planFee) {
      msgRecom =  t('simulation.contactus') 
    }else{
      plandesc = getPlanDesc("F-001")
    }

    msgRecom = t('simulation.recommendation') + ": " + plandesc + planLimitdesc

    return <div>
            
              <Link
                component={RouterLink}
                to="/subscription"
              >
                <Tooltip title="Subscribe Now" arrow={true}>
                <Alert severity={"success"}>
                  <AlertTitle><Typography variant="h5">{msgRecom}</Typography></AlertTitle>
                  <Typography variant="body1">{msgRecom2}</Typography>
                </Alert>
                </Tooltip>
              </Link>
            
          </div> 
  }

  const getLoadingContent = () => {

    let totalV = getTotalProfit()
    if(totalV.totalProfit <= 0){
      return <div></div>
    }

    return <div>
          <Tooltip title={t('common.msg.simulationdisclaimer')} arrow={true} placement={'start'}>
          <Alert severity={"info"}>
            <AlertTitle><Typography variant="h5">{t('simulation.estprofit') + ": "}{format({prefix: appGlobalConfig.BaseCurrency + " ", suffix: '/'+ t('simulation.month')})(totalV.totalProfit)}</Typography></AlertTitle>
            <Typography variant="body1">{t('simulation.portvalue') + ": "}{format({prefix: appGlobalConfig.BaseCurrency + " ", suffix: ''})(totalV.totalPort)}</Typography>
            <Typography variant="body1">{t('simulation.annreturn') + ": "} {format({prefix: '', suffix: '%'})(totalV.annualReturns)}</Typography>
          </Alert>
          </Tooltip>
        </div> 

  }

  const backgroundColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.text.primary,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.text.disabled,
    theme.palette.primary.main,
    theme.palette.secondary.main
  ]
  
  const getLabels = () => {

    if(radiovalue === "bydays"){
      return getLabelsByDays()
    }else{
      return getLabelsTotalBySumbol()
    }
  }
  
  const getBarThickness = () => {
    
    // console.log(theme.breakpoints.down('sm'))
    // if(theme.breakpoints.up('sm')){
    //   return 20
    // }
    if(radiovalue === "bydays"){
      return 20
    }else{
      return 50
    }
  }

  const getLegend = () => {
    if(radiovalue === "bydays"){
      return true
    }else{
      return false
    }
  }

  const getStacked = () => {
    if(radiovalue === "bydays"){
      return true
    }else{
      return false
    }
  }
  const getDatasets = () => {

    if(radiovalue === "bydays"){
      return getDatasetsByDays()
    }else{
      return getDatasetsTotalBySymbol()
    }
  }

  const data = {
    labels: getLabels(),
    datasets: getDatasets()
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: getLegend() },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function(tooltipItem, data) { 
          return data.datasets[tooltipItem.datasetIndex].label + ": " + xlendingUtils.numberWithCommas(tooltipItem.value);
        }
      }
     },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          stacked: getStacked(), 
          barThickness: getBarThickness(),
          maxBarThickness: getBarThickness(),
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          stacked: false, 
          ticks: {
            callback: function(value) { return xlendingUtils.numberWithCommas(value); },
            beginAtZero:true,
            // min: 0,
            // max: 100  
           }, 
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    }
  };

  const handleRadioChange = event => {
    setRadioValue(event.target.value);

  };

  const handleRateTypeChange = event => {
    setRateTypeValue(event.target.value);

  };

  return (
    <Grid
      className={classes.root}
      container
      >
        <Grid
          className={classes.grid}
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
          <Card>
            
            <CardHeader
              action={
                <Tooltip title="" arrow={true}>
                  <IconButton 
                    onClick={handleClickSave}
                    aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              }
              avatar={
                <Avatar
                  className={classes.avatar}
                  src={'/images/avatars/basic.png'}
                >
                </Avatar>  
              }
              title={t('simulation.profitsimulation')}
              //subheader={"Based on last 30 days price"}
              titleTypographyProps={{variant:'h4' }}
              subheaderTypographyProps={{variant:'body1' }}
            ></CardHeader>
           
              <Divider />
              <Table 
                className={classes.table}
              >
              <TableBody>
              {positions.map((p, i) => ( 
                <TableRow
                    hover
                  >
                    <TableCell className={classes.cellhidden}>
                    <Typography gutterBottom variant="h3" component="h3">
                        {getIcons(p.symbol)}
                      </Typography> 
                    </TableCell>
                    <TableCell>
                        {p.symbol}
                    </TableCell>
                    <TableCell align="right">
                        {p.quantity} 
                    </TableCell>
                    <TableCell>
                    <ButtonGroup size="small" color="secondary" aria-label="large outlined primary button group">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={handleClickDelete(p.symbol)}
                      >
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                  </TableRow>
                  
                ))}
              
              <TableRow className={classes.tableRow}>
              <TableCell className={classes.cellhidden}>
                  <Typography gutterBottom variant="h3" component="h3">
                    {getIcons(state.symbol)}
                  </Typography> 
              </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="symbol"
                      style={{ width: 80 }}
                      name="symbol"
                      options={supportedSymbols}
                      getOptionLabel={option => option.symbol}
                      onChange={(event, newValue) => {
                        //console.log(newValue)
                        setState({
                          ...state,
                          symbol: newValue == null? "" : newValue.symbol
                        });
                      }}
                      renderInput={params => <TextField {...params} label="Token" margin="dense" variant="outlined" />}

                      //className={classes.text}
                      // renderInput={params => (
                      //   <TextField 
                      //     {...params} 
                      //     margin="dense"  
                      //   />
                      // )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number" 
                      className={classes.inputText}
                      //inputProps={{ style: {textAlign: 'right', width:"100%" }}
                      label={t('simulation.quantity')}
                      margin="dense"
                      name="quantity"
                      onChange={handleChange("quantity")}
                      required
                      variant="outlined"
                    />
                  </TableCell>
                  
                  <TableCell>
                    <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<AddCircleIcon />}
                        onClick={handleClickAdd}
                      >
                       {""}
                        
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
                </TableBody>

              </Table>
              {/* </CardContent> */}
           
            <CardActions className={classes.actions}>
              <FormControl component="fieldset">
                  <RadioGroup aria-label="ratetype" name="ratetype" value={rateTypeValue} onChange={handleRateTypeChange} row>
                    <FormControlLabel
                      value="1davg"
                      control={<Radio color="primary" />}
                      label={t('simulation.1dayavg')}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="last"
                      control={<Radio color="primary" />}
                      label={t('simulation.lastprice')}
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </CardActions>
          </Card>
        </Grid>
        <Grid
          className={classes.grid}
          lg={8}
          md={8}
          xl={8}
          xs={12}
        >
          <Card>
            <CardContent>
                {getLoadingContent()}
                {getRecommendedPlan()}
              </CardContent> 
            <CardContent className={classes.contentgraph}>
              {/* <div className={classes.chartContainer}>  */}
                  <Bar 
                  data= {data}
                  options={options}
                />
              {/* </div> */}
              </CardContent>
              <CardActions className={classes.actions}>
              <FormControl component="fieldset">
                  {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
                  <RadioGroup aria-label="position" name="position" value={radiovalue} onChange={handleRadioChange} row>
                    <FormControlLabel
                      value="bydays"
                      control={<Radio color="primary" />}
                      label={t('simulation.bydays')}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="bysymbols"
                      control={<Radio color="primary" />}
                      label={t('simulation.bySymbol')}
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </CardActions>

              

         

          </Card>

          
         

        </Grid>
        
      </Grid>
    
  );
};

export default ExpectedProfit;

import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';


import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {}
}));

const Uniswap = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  
  const [openSwap, setOpenSwap] = React.useState(false);
  const [tokenAddress, setTokenAddress] = React.useState("");

  const getSwapAddress = () => {
    let uri = "https://uniswap.exchange/swap?theme=light?outputCurrency="+tokenAddress //+"?inputCurrency="+swapAction.fromAddress
    return uri
  }

  const handleCloseSwap = () => {
    setOpenSwap(false);
  };
  
  const handleOpenSwap = (tAddress) => {
    setOpenSwap(true);
    setTokenAddress(tAddress)
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpenSwap: handleOpenSwap
    };
  });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={openSwap}
        fullWidth={true}
        maxWidth = {'sm'}
        keepMounted
        onClose={handleCloseSwap}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"UniSwap"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <iframe
              src={getSwapAddress()}
              height="660px"
              width="100%"
              id="myId"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseSwap} 
            color="primary"
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      

    </Card>
  );
});

Uniswap.propTypes = {
  className: PropTypes.string
};

export default Uniswap;

import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@material-ui/icons/Refresh';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import cSaiContract from './cTokens/cSaiContract'
import cEtherContract from './cTokens/cEtherContract'
import cRepContract from './cTokens/cRepContract'
import cWBTCContract from './cTokens/cWBTCContract'
import cUSDCContract from './cTokens/cUSDCContract'
import cDaiContract from './cTokens/cDaiContract'
import cZrxContract from './cTokens/cZrxContract'
import cBatContract from './cTokens/cBatContract'
import daiTokenContract from './Token/daiTokenContract'
import saiTokenContract from './Token/saiTokenContract'
import batTokenContract from './Token/batTokenContract'
import repTokenContract from './Token/repTokenContract'
import wbtcTokenContract from './Token/wbtcTokenContract'
import usdcTokenContract from './Token/usdcTokenContract'
import zrxTokenContract from './Token/zrxTokenContract'

import { StatusBullet } from 'components';
import Uniswap from './Uniswap/Uniswap'
import CancelIcon from '@material-ui/icons/Cancel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import appGlobalConfig from "appGlobalConfig"
import xlendingUtils from 'xlendingUtils'


import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  IconButton,
  Tooltip,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  CircularProgress,
  Typography
} from '@material-ui/core';
//import { useAuth } from "use-auth.js";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    //backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tablecell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    // [theme.breakpoints.up('sm')]: {
    //   display: 'flex'
    // }
  },
  iconbutton: {
    height: 66,
    width: 66,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    // [theme.breakpoints.up('sm')]: {
    //   display: 'flex'
    // }
  },
  iconbuttonbig: {
    height: 30,
    width: 30,
  },
}));

const statusColors = {
  enable: 'success',
  disable: 'danger'
};

function getAvatarSource(coinname) {

  let avatarUrl = '/images/products/' + coinname.toLowerCase() + '.png'
  return avatarUrl
}


const LatestMarkets = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const [marketLoading, setMarketLoading] = React.useState(true);
  const { web3 } = window;
  
  const [values, setValues] = useState([])
  const [tokens, setTokens] = useState([])
  const [transactions, setTransactions] = useState([])
  const [ethAccounts, setEthAccounts] = useState([])
  const [tokenBalance, setTokenBalance] = useState([])

  const estherscanAPI = "U86Q3ARQWQCHWMUR78T9Q8AUBWF3AH5IEY"
  const [buttonAction, setButtonAction] = useState({
    action: "",
    cToken: "",
    underlyingaddress: "",
    symbol: "",
    message: "",
    quantity: 0,
    buttonDisable: true,
    isenable: ""
  })

  const [open, setOpen] = React.useState(false);
  const [metamaskConnected, setMetamaskConnected] = React.useState(false);
  
  try{
    window.ethereum.autoRefreshOnNetworkChange = false;
  }catch(e){

  }

  const popover = (market) => (
    <Popover id="popover-basic" className={classes.overlay}>
        <Popover.Content>
          <Alert severity="success">
          <div>
            <div>{t('compound.lendbalance')}: {getBalanceCell(market.token_address)}</div>
            <div>{t('compound.underbal')}: {getUnderlyingBalCell(market.underlying_address, market.underlying_symbol, 4)}</div>
            <div>{t('compound.lendaccrual')}: {getAccrualCell(market.token_address)}</div>
          </div>
          </Alert>
        </Popover.Content>
      </Popover>
  );

  //fetch market data
  useEffect(() => {
    const controller = new AbortController();
    fetchMarketData();
    return () => {
      controller.abort();
    }
  }, []);

  //fetch cToken data
  useEffect(() => {
    const controller = new AbortController();
    fetchData();
    return () => {
      controller.abort();
    }
  }, []);

  //load tokens balance 
  useEffect(() => {
    const controller = new AbortController();
    fetchTokenBalance()
    return () => {
      controller.abort();
    }
  }, []);

//fetch transaction data based on wallet address
  useEffect(() => {
    const controller = new AbortController();
    
    fetchTxnData();
    return () => {
      controller.abort();
    }
  }, []);

  const netWorkName = () => {

    try{
      let netID = window.ethereum.networkVersion 
      switch(netID) {
        case "1":
          return "mainnet"
        break
        case "4":
          return "rinkeby"
        break
        default:
          return "No Network Connected"
        break
      }
    }catch(e){
      console.log(e)
      return ""
    }

    return ""

  }

  //fetch market data from compound
  const fetchMarketData = async () => {
    
    
    let cancel = false;
    fetch(appGlobalConfig.compoundURL)
        .then((response) => {
          if (response.ok) {
            return response.json().then(data => {
              //console.log(data.cToken)
              if (cancel) {
                return;
              }
              
              setValues(data.cToken)

              if(values !== undefined){
                setMarketLoading(false)
              }else{
                setMarketLoading(true)
              }
              //fetchTokenBalanceTest(data.cToken)
      
            });
          }
          return Promise.reject(response);
        })
        .catch(/* similar stuff here */);
  };

  const fetchTokenBalance = async () => {
    
    

    let accounts
    try {
      accounts = await window.ethereum.enable()
      if(accounts === undefined){
        setMetamaskConnected(false)
        return
      }else{
        setMetamaskConnected(true)
      }
    } catch (error) {
      console.error(error)
      return
    }

    if(!isMetaReady()){
      return
    }
    


    const TokenAddress = []
    TokenAddress.push({symbol: "sai", address: "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359"})
    TokenAddress.push({symbol: "dai", address: "0x6B175474E89094C44Da98b954EedeAC495271d0F"})
    TokenAddress.push({symbol: "bat", address: "0x0d8775f648430679a709e98d2b0cb6250d2887ef"})
    TokenAddress.push({symbol: "rep", address: "0x1985365e9f78359a9B6AD760e32412f4a445E862"})
    TokenAddress.push({symbol: "wbtc", address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"})
    TokenAddress.push({symbol: "usdc", address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"})
    TokenAddress.push({symbol: "zrx", address: "0xe41d2489571d322189246dafa5ebde1f4699f498"})

    let minABI = [
      // balanceOf
      {
        "constant":true,
        "inputs":[{"name":"_owner","type":"address"}],
        "name":"balanceOf",
        "outputs":[{"name":"balance","type":"uint256"}],
        "type":"function"
      },
      // decimals
      {
        "constant":true,
        "inputs":[],
        "name":"decimals",
        "outputs":[{"name":"","type":"uint8"}],
        "type":"function"
      }
    ];

    let newBal = []
    let walletAddress = accounts[0] 
    
    if(walletAddress === undefined) return

    TokenAddress.forEach(t => {
      
        // Get ERC20 Token contract instance
        let contract = web3.eth.contract(minABI).at(t.address);
        // Call balanceOf function
        contract.balanceOf(walletAddress, (error, balance) => {
          // Get decimals
            contract.decimals((error, decimals) => {
            // calculate a balance
            balance = balance.div(10**decimals);
            newBal.push({
              symbol: t.symbol,
              address: t.address,
              underlying_bal: balance
            })
          });
        });
    });
      web3.eth.getBalance(walletAddress, function(err, ethbalance) {
        ethbalance = web3.fromWei(ethbalance, "ether")  
        newBal.push({
          symbol: "ETH",
          address: walletAddress,
          underlying_bal: ethbalance
        })
      });

      setTokenBalance(newBal)
    //   let transaction = await web3.eth.getTransaction('0xb64e5482b24eb2f796d4d34c7c9b686fcc7cfa1d305733aa4c9a9fc471b27ce9')
    // let input = web3.toAscii(transaction.input)
    // console.log(input)

    //web3.toAscii("0xbd834a300000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000004d54ba7ee2467d0000000000000000000000000000000000000000000000000000000000000084")
    // var str = web3.hexToString("0xbd834a30");
    // //var str =web3.utils.hexToNumberString("0xbd834a300000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000004d54ba7ee2467d0000000000000000000000000000000000000000000000000000000000000084")
    // console.log(str)

  };

  //fetch ctoken  
  const fetchData = async () => {
    
    let accounts
    try {
      accounts = await window.ethereum.enable()
      if(accounts === undefined) {
        setMetamaskConnected(false)
        return
      }else{
        setMetamaskConnected(true)
      }
    } catch (error) {
      console.error(error)
      return
    }

    if(!isMetaReady()){
      setTokens([])
      return 
    }

    let cancel = false;
    let collacc = []

    const newAcc = accounts
    newAcc.forEach(a => {
      collacc.push({
        address: a
      })
    });
    setEthAccounts(collacc)

    if(accounts.length > 0){
      fetch('https://api.compound.finance/api/v2/account?addresses[]='+accounts[0])
      .then((response) => {
        if (response.ok) {
          return response.json().then(data => {
            console.log(data)
            if (cancel) {
              return;
            }

            const newValues = data.accounts[0].tokens
            let colldatas = [{
              address: "",
              balance: 0,
              accrual: 0
            }]

            newValues.forEach(d => {
              colldatas.push({
                address: d.address,
                balance: d.supply_balance_underlying.value,
                accrual: d.lifetime_supply_interest_accrued.value
              })
            });

            setTokens(colldatas)
          });
        }

        return Promise.reject(response);
      })
      .catch(/* similar stuff here */);
    }
  };

  //fetch token txn for enable or disable check
  const fetchTxnData = async () => {
    
    let accounts
    try {
      accounts = await window.ethereum.enable()
      if(accounts === undefined){
        setMetamaskConnected(false)
        return
      }else{
        setMetamaskConnected(true)
      }
    } catch (error) {
      console.error(error)
      return
    }

    // throws if no account selected
    let walletAddress = accounts[0] //web3.eth.accounts[0]
    if(walletAddress === undefined) return


    let netWname = netWorkName()
    const api = require('etherscan-api').init(estherscanAPI, netWname, '10000');
    

    var txlist = api.account.txlist(walletAddress, 1, "latest", 1, 100, "asc")
    txlist.then(function(txlistData){
      console.log(txlistData);
      const newValues = txlistData.result
      let colldatas = [{
        from: "",
        to: 0,
        value: 0,
        isError: ""
      }]

      newValues.forEach(d => {
        colldatas.push({
          from: d.from,
          to: d.to,
          value: d.value,
          isError: d.isError
        })
      });

      setTransactions(colldatas)
    })

  }


  try{
    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      handleClick()
    })
  
    window.ethereum.on('networkChanged', function (netID) {
      // Time to reload your interface with accounts[0]!
      console.log(netID)
      if(netID !== "1"){
      }
    })
  }catch(e){
    console.log(e)
  }
  

  const getTitleLoading = () => {

    let labelLoading = <div>{t('compound.titleloading')} <LinearProgress color="secondary" /> </div>
    if (marketLoading) {
      return labelLoading
    }else{
      return t('compound.title')　
    }
  }

  const getWalletStatus = () => {

    if (ethAccounts.length < 1) {
      return t('compound.walletnotconnected')
    }else{
      return t('compound.walletconnected')
    }
  }


  const getTokenAccrual = (address, decplace) => {

    let foundtoken = tokens.find(x=>x.address === address)
    if(foundtoken !== undefined){
      return xlendingUtils.roundTo(foundtoken.accrual,decplace)
    }else{
      return xlendingUtils.roundTo(0,decplace)
    }
  }

  const getTokenUnderlyingBalance = (address, symbol, decplace) => {

    let foundtoken
    if(symbol === "ETH"){
      foundtoken = tokenBalance.find(x=>x.symbol === symbol)
    }else{
      foundtoken = tokenBalance.find(x=>x.address === address)
    }
    if(foundtoken !== undefined){
      return xlendingUtils.roundTo(foundtoken.underlying_bal,decplace)
    }else{
      return xlendingUtils.roundTo(0,decplace)
    }
  }

  const handleClick = () => {
    fetchData()
    fetchTokenBalance()
    fetchTxnData()
  }

  const getTokenBalance = (address, decplace) => {

    let foundtoken = tokens.find(x=>x.address === address)
    if(foundtoken !== undefined){
      return xlendingUtils.roundTo(foundtoken.balance, decplace)
    }else{
      return xlendingUtils.roundTo(0, decplace)
    }
  }

  const getUnderlyingBalCell = (address, symbol, decplace, stringonly) => {

    let bal = getTokenUnderlyingBalance(address, symbol, 8)
    let balprec3 = getTokenUnderlyingBalance(address, symbol, decplace)
    if(bal > 0){
      
      if(stringonly){
        return balprec3
      }else{
        return <Box bgcolor="warning.main">{balprec3}</Box>
      }
    }else{
      if(stringonly){
        return balprec3
      }else{
        return <Box color="primary.main">{balprec3}</Box>
      }
    }
  }

  const getBalanceCell = (address) => {

    let bal = getTokenBalance(address,4)
    if(bal > 0){
      return <Box bgcolor="warning.main">{bal}</Box>
    }else{
      return <Box color="primary.main">{bal}</Box>
    }
  }

  const getAccrualCell = (address) => {

    let bal = getTokenAccrual(address,8)
    let balpre4 = getTokenAccrual(address,4)
    //console.log(bal)
    if(bal > 0){
      return <Box bgcolor="warning.main">{balpre4}</Box>
    }else{
      return <Box color="primary.main">{balpre4}</Box>
    }
  }

  const getEstimateGasLimit = (gasLimit) => {

    return xlendingUtils.roundTo(gasLimit * 1.5, 0)
  }

  async function MetaEnable(symbol){

    var Web3 = require('web3');
    const web3 = new Web3(window.ethereum);
    var accounts= await web3.eth.getAccounts();

    const addressFrom = accounts[0]
    const [tokenInstance, tokenAddress, cContractAddress] = getTokenInstance(web3, symbol)
    
    // declare const variables to pass to the approve function of the sai token contract
    const TOKENS = web3.utils.toHex(-1);
    const ADDRESS_SPENDER = cContractAddress; //this is cContract, not underlying token
    const approveEncodedABI = tokenInstance.methods.approve(ADDRESS_SPENDER, TOKENS).encodeABI()
    
    // using the promise
    tokenInstance.methods.approve(ADDRESS_SPENDER, TOKENS).estimateGas({from: addressFrom})
    .then(function(gasAmount){
        
        console.log(gasAmount)
        const estGasLimit = getEstimateGasLimit(gasAmount)
        web3.eth.getGasPrice()
        .then(function(gasPrice){
          console.log(gasPrice)
          
          const transactionParameters = {
            nonce: '0x00', // ignored by MetaMask
            gasPrice: web3.utils.toHex(gasPrice), //'0x09184e72a000', // customizable by user during MetaMask confirmation.
            gas: web3.utils.toHex(estGasLimit), //'0x2710',  // customizable by user during MetaMask confirmation.
            to: tokenAddress, // to underlying token address
            from: addressFrom, // must match user's active address.
            value: '0x00', // Only required to send ether to the recipient from the initiating external account.
            data: approveEncodedABI, // Optional, but used for defining smart contract creation and interaction.
            chainId: 1 // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
          }

          window.ethereum.sendAsync({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
            from: addressFrom,
          }, function (err, result) {
            
            if (err) {
              console.error(err);
              return;
            }
            // var Web3 = require('web3');
            // const web3 = new Web3(window.ethereum);
      
            // var txhash = result;
            // var receipt = web3.eth.getTransactionReceipt(txhash);
            // if (receipt && receipt.transactionHash == txhash) {
            //   console.log(receipt);
            //   console.log("TransactionStatus ", receipt.status);
            // }
      
          })
      
        })
        .catch(function(error){
          console.log(error)
        });
    
      })
    .catch(function(error){
      console.log(error)
    });

  }

  async function MetaLending(){

    var Web3 = require('web3');
    const web3 = new Web3(window.ethereum);
    var accounts= await web3.eth.getAccounts();

    const addressFrom = accounts[0]
    
    const [cControlInstance] = getcContractTokenInstance(web3)

    // declare a const variable to pass to the mint function of the cSai contract
    const MINT_AMOUNT = web3.utils.toHex(buttonAction.quantity * 10 ** 18) //quantity to be changed

    let mintEncodedABI
    //let estGas = 210000
    // create the encoded abi of the mint function
    if(buttonAction.symbol.toLowerCase() === "eth"){
      
      // using the promise
      cControlInstance.methods.mint().estimateGas({from: addressFrom})
      .then(function(gasAmount){
          console.log(gasAmount)
          const estGasLimit = getEstimateGasLimit(gasAmount)

          web3.eth.getGasPrice()
          .then(function(gasPrice){
            console.log(gasPrice)
            mintEncodedABI = cControlInstance.methods.mint().send({
              from: addressFrom, 
              value: MINT_AMOUNT,
              gasPrice: web3.utils.toHex(gasPrice), //'0x09184e72a000', // customizable by user during MetaMask confirmation.
              gas: web3.utils.toHex(estGasLimit), //'0x2710',  // customizable by user during MetaMask confirmation.
              chainId: 1 // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
            });
          })
          .catch(function(error){
            console.log(error)
          });
      })
      .catch(function(error){
        console.log(error)
      });
      
      return
    }else{
      mintEncodedABI = cControlInstance.methods.mint(MINT_AMOUNT).encodeABI();
      // using the promise
      cControlInstance.methods.mint(MINT_AMOUNT).estimateGas({from: addressFrom})
      .then(function(gasAmount){
          console.log(gasAmount)
          const estGasLimit = getEstimateGasLimit(gasAmount)

          web3.eth.getGasPrice()
          .then(function(gasPrice){
            console.log(gasPrice)

            // Function	Typical Gas Cost
            // Mint	< 150K, cDAI < 300k
            const transactionParameters = {
              nonce: '0x00', // ignored by MetaMask
              gasPrice: web3.utils.toHex(gasPrice), //'0x09184e72a000', // customizable by user during MetaMask confirmation.
              gas: web3.utils.toHex(estGasLimit), //'0x2710',  // customizable by user during MetaMask confirmation.
              to: buttonAction.cToken, // Required except during contract publications.
              from: addressFrom, // must match user's active address.
              value: '0x00', // Only required to send ether to the recipient from the initiating external account.
              data: mintEncodedABI, // Optional, but used for defining smart contract creation and interaction.
              chainId: 1 // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
            }
            
            window.ethereum.sendAsync({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
              from: addressFrom,
            }, function (err, result) {
              
              if (err) {
                console.error(err);
                return;
              }
              // var Web3 = require('web3');
              // const web3 = new Web3(window.ethereum);
              // var txhash = result;
              // var filter = web3.eth.filter('latest');
              // filter.watch(function(error, result) {
              //   var receipt = web3.eth.getTransactionReceipt(txhash);
              //   if (receipt && receipt.transactionHash == txhash) {
              //       console.log("TransactionStatus ", receipt.status);
              //   }
              // });

            })
          })
          .catch(function(error){
            console.log(error)
          });

      })
      .catch(function(error){
        console.log(error)
      });
    }

    
  }

  function getcContractTokenInstance(web3){
    
    let tokenInstance

    //console.log(buttonAction.symbol.toLowerCase())
    switch(buttonAction.symbol.toLowerCase()) {
      case "zrx":
        tokenInstance = new web3.eth.Contract(JSON.parse(cZrxContract.cZrxContractAbi),
          cZrxContract.cZrxContractAddress
        );
        //contractAddress = cZrxContract.cZrxContractAddress
        break;

      case "eth":
        tokenInstance = new web3.eth.Contract(JSON.parse(cEtherContract.cEtherContractAbi),
          cEtherContract.cEtherContractAddress
        );
        //contractAddress = cEtherContract.cEtherContractAddress
        break;

      case "dai":
        tokenInstance = new web3.eth.Contract(JSON.parse(cDaiContract.cDaiContractAbi),
          cDaiContract.cDaiContractAddress
        );
        //contractAddress = cDaiContract.cDaiContractAddress
        break;
      case "rep":
        tokenInstance = new web3.eth.Contract(JSON.parse(cRepContract.cRepContractAbi),
          cRepContract.cRepContractAddress
        );
        //contractAddress = cRepContract.cRepContractAddress
        break;
      case "bat":
        tokenInstance = new web3.eth.Contract(JSON.parse(cBatContract.cBatContractAbi),
          cBatContract.cBatContractAddress
        );
        //contractAddress = cBatContract.cBatContractAddress
        break;
      case "sai":
        tokenInstance = new web3.eth.Contract(JSON.parse(cSaiContract.cSaiContractAbi),
          cSaiContract.cSaiContractAddress
        );
        //contractAddress = cSaiContract.cSaiContractAddress
        break;
      
        case "usdc":
          tokenInstance = new web3.eth.Contract(JSON.parse(cUSDCContract.cUSDCContractAbi),
          cUSDCContract.cUSDCContractAddress
        );
        //contractAddress = cUSDCContract.cUSDCContractAddress
        break;

        case "wbtc":
          tokenInstance = new web3.eth.Contract(JSON.parse(cWBTCContract.cWBTCContractAbi),
          cWBTCContract.cWBTCContractAddress
        );
        //contractAddress = cWBTCContract.cWBTCContractAddress
          break;

        default:
        
        break
        // code block
    }

    return [tokenInstance]

  }

  function getTokenInstance(web3, symbol){
    
    let tokenInstance
    let tokenAddress
    //let c_ContractInstance
    let c_ContractAddress

    switch(symbol.toLowerCase()) {
      case "zrx":

        tokenInstance = new web3.eth.Contract(JSON.parse(zrxTokenContract.zrxTokenContractAbi),
        zrxTokenContract.zrxTokenContractAddress
        );
        tokenAddress = zrxTokenContract.zrxTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cZrxContract.cZrxContractAbi),
        // cZrxContract.cZrxContractAddress
        // );
        c_ContractAddress = cZrxContract.cZrxContractAddress
        break;

      case "eth":
        // tokenInstance = new web3.eth.Contract(JSON.parse(cEtherContract.cEtherContractAbi),
        //   cEtherContract.cEtherContractAddress
        // );
        // tokenAddress = cEtherContract.cEtherContractAddress
        break;

      case "dai":
        tokenInstance = new web3.eth.Contract(JSON.parse(daiTokenContract.daiTokenContractAbi),
          daiTokenContract.daiTokenContractAddress
        );
        tokenAddress = daiTokenContract.daiTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cDaiContract.cDaiContractAbi),
        // cDaiContract.cDaiContractAddress
        // );
        c_ContractAddress = cDaiContract.cDaiContractAddress

        break;
      case "rep":
        tokenInstance = new web3.eth.Contract(JSON.parse(repTokenContract.repTokenContractAbi),
        repTokenContract.repTokenContractAddress
        );
        tokenAddress = repTokenContract.repTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cRepContract.cRepContractAbi),
        // cRepContract.cRepContractAddress
        // );
        c_ContractAddress = cRepContract.cRepContractAddress
        break;
      case "bat":
        tokenInstance = new web3.eth.Contract(JSON.parse(batTokenContract.batTokenContractAbi),
        batTokenContract.batTokenContractAddress
        );
        tokenAddress = batTokenContract.batTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cBatContract.cBatContractAbi),
        // cBatContract.cBatContractAddress
        // );
        c_ContractAddress = cBatContract.cBatContractAddress

        break;
      case "sai":
        tokenInstance = new web3.eth.Contract(JSON.parse(saiTokenContract.saiTokenContractAbi),
        saiTokenContract.saiTokenContractAddress
        );
        tokenAddress = saiTokenContract.saiTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cSaiContract.cSaiContractAbi),
        // cSaiContract.cSaiContractAddress
        // );
        c_ContractAddress = cSaiContract.cSaiContractAddress

        break;
      
        case "usdc":
          tokenInstance = new web3.eth.Contract(JSON.parse(usdcTokenContract.usdcTokenContractAbi),
          usdcTokenContract.usdcTokenContractAddress
        );
        tokenAddress = usdcTokenContract.usdcTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cUSDCContract.cUSDCContractAbi),
        // cUSDCContract.cUSDCContractAddress
        // );
        c_ContractAddress = cUSDCContract.cUSDCContractAddress

        break;

        case "wbtc":
          tokenInstance = new web3.eth.Contract(JSON.parse(wbtcTokenContract.wbtcTokenContractAbi),
          wbtcTokenContract.wbtcTokenContractAddress
        );
        tokenAddress = wbtcTokenContract.wbtcTokenContractAddress

        // //initialize cContract 
        // c_ContractInstance = new web3.eth.Contract(JSON.parse(cWBTCContract.cWBTCContractAbi),
        // cWBTCContract.cWBTCContractAddress
        // );
        c_ContractAddress = cWBTCContract.cWBTCContractAddress

          break;

        default:
        
        break
        // code block
    }

    return [tokenInstance, tokenAddress, c_ContractAddress]

  }

  async function MetaRecall(){
  
    //console.log(buttonAction.cToken)
    //console.log(name)
    var Web3 = require('web3');
    const web3 = new Web3(window.ethereum);
    var accounts= await web3.eth.getAccounts();
    //console.log(accounts)

    const addressFrom = accounts[0]
    
    // instantiate the cSai contract
    const [cControlInstance] = getcContractTokenInstance(web3)

    // declare a const variable to pass to the mint function of the cSai contract
    const REDEEM_AMOUNT = web3.utils.toHex(buttonAction.quantity * 10 ** 18) //quantity to be changed
    
    // create the encoded abi of the redeem function
    const redeemUnderlyingEncodedABI = cControlInstance.methods.redeemUnderlying(REDEEM_AMOUNT).encodeABI();
    

    //let estGas = 0
    // using the promise
    cControlInstance.methods.redeemUnderlying(REDEEM_AMOUNT).estimateGas({from: addressFrom})
    .then(function(gasAmount){

        const estGasLimit = getEstimateGasLimit(gasAmount)

        web3.eth.getGasPrice()
        .then(function(gasPrice){
          console.log(gasPrice)

          const transactionParameters = {
            nonce: '0x00', // ignored by MetaMask
            gasPrice: web3.utils.toHex(gasPrice), //'0x09184e72a000', // customizable by user during MetaMask confirmation.
            gas: web3.utils.toHex(estGasLimit), //'0x2710',  // customizable by user during MetaMask confirmation.
            to: buttonAction.cToken, // Required except during contract publications.
            from: addressFrom, // must match user's active address.
            value: '0x00', // Only required to send ether to the recipient from the initiating external account.
            data: redeemUnderlyingEncodedABI, // Optional, but used for defining smart contract creation and interaction.
            chainId: 1 // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
          }

          window.ethereum.sendAsync({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
            from: addressFrom,
          }, function (err, result) {
            
            console.log(result)
            // A typical node-style, error-first callback.
            // The result varies by method, per the JSON RPC API.
            // For example, this method will return a transaction hash on success.
          })
        })
    })
    .catch(function(error){
      console.log(error)
    });
  }
  
  const handleClickLending = (underlyingaddress, tokenaddress, name) => async event => {

    if(!isMetaReady()){
      return
    }

    if(!metamaskConnected) return

    let isenable = isContractEnable(underlyingaddress, name)

    let message
    let action
    let buttonDisable
    if(isenable === "enable"){
      message = t('compound.abouttolend') + name
      action = "supply"
      buttonDisable = true
    }

    setButtonAction({
      action: action,
      cToken: tokenaddress,
      underlyingaddress: underlyingaddress,
      symbol: name,
      message: message,
      quantity: 0,
      buttonDisable: buttonDisable,
      isenable: isenable
    })

    //console.log(isenable)
    if(isenable === "enable"){
      handleOpen()
    }else{
      MetaEnable(name)
    }
  }

  const handleClickRecall = (underlyingaddress, tokenaddress, name) => async event => {

    if(!metamaskConnected) return

    if(!isMetaReady()){
      return
    }

    let isenable = isContractEnable(tokenaddress, name)

    setButtonAction({
      action: "recall",
      cToken: tokenaddress,
      underlyingaddress: underlyingaddress,
      symbol: name,
      message:  t('compound.abouttorecall') + name,
      quantity: 0,
      buttonDisable: true,
      isenable : isenable
    })

    handleOpen()

  }

  const handleOpen = () => {
    setOpen(true);
  };



  const handleClose = () => {
    //setQuantity("0");
    setOpen(false);
    setButtonAction({
      action: "",
      cToken: "",
      underlyingaddress: "",
      symbol: "",
      message: "",
      quantity: 0,
      buttonDisable: true,
      isenable : ""
    })
  };

  const handleClickCallMeta = e => {

    switch(buttonAction.action.toLowerCase()) {
      case "recall":
        MetaRecall()
        break;

      case "supply":
        MetaLending()
        break;
        
      case "enable":
        MetaEnable()
        break;
        
        default:

        break
    }
   handleClose()
 }

 const handleClickMax = e => {
  let q = getTokenOrUnderlyingBal()
  setButtonAction({
    ...buttonAction,
    quantity: q,
    buttonDisable: q>0? false: true
  })
}

const getEnableTooltip = (tokenAddress, symbol) => {

  let isenable = isContractEnable(tokenAddress, symbol)
  if(isenable === "enable"){
    return t('compound.contract.enabled')
  }else{
    return t('compound.contract.notenabled')
  }
}

const isContractEnable = (tokenAddress, symbol) => {
  
  // if(!isMetaReady()){
  //   return "disable"
  // }

  //console.log(metamaskConnected)
  if(!metamaskConnected){
    return "disable"
  }
  
  if(symbol.toLowerCase() === "eth"){
    return "enable"
  }

  let foundtxn = transactions.find(x=>x.to === tokenAddress && x.isError==="0")
  //console.log(foundtxn)
  if(foundtxn !== undefined){
    return "enable"
  }else{
    return "disable"
  }

}


const getTokenOrUnderlyingBal = () => {
  
  //console.log(buttonAction.action)
  if(buttonAction.action === "recall"){
    return getTokenBalance(buttonAction.cToken, 12)
  }else{
    return getTokenUnderlyingBalance(buttonAction.underlyingaddress, buttonAction.symbol, 8)
  }

};

const ref = useRef(null);

const handleClickSwap = (underlyingaddress, tokenaddress, name) => event => {
  ref.current.handleOpenSwap(underlyingaddress);
};

const handleChange = () => event => {
  
  let btnDisable = false
  let q = event.target.value
  let bal = getTokenOrUnderlyingBal()
  if(q <= 0){
    btnDisable = true
  }
  if(q > bal){
    btnDisable = true
  }
  setButtonAction({
    ...buttonAction,
    quantity: q,
    buttonDisable: btnDisable,
    hasError: btnDisable
  })
};

const isMetaReady = () => {
  
  try{
    if(!window.ethereum.isMetaMask){
      return false
    }
    
    // if(!metamaskConnected){
    //   return false
    // }
    
    if(window.ethereum.networkVersion !== "1" && window.ethereum.networkVersion !== "4"){
      console.log(window.ethereum.networkVersion)
      return false
    }
  }catch(e){
    console.log(e)
    return false
  }
  return true
}


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Uniswap ref={ref} />
    <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth = {'sm'}
        >
        <DialogTitle id="form-dialog-title">{buttonAction.message}</DialogTitle>
        <DialogContent>
           
          <DialogContentText>
          {t('compound.available')}: {getTokenOrUnderlyingBal()}
            <Button 
              onClick={handleClickMax} 
              color="primary"
              disabled={buttonAction.isenable==="enable"? false: true }
              >
              Max
            </Button>
          </DialogContentText>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Avatar
                        src={getAvatarSource(buttonAction.symbol)}
                      >  
                      </Avatar> 
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        className={classes.textField}
                        fullWidth
                        name="quantity"
                        onChange={handleChange()}
                        type="text"
                        value={buttonAction.quantity || 0}
                        variant="outlined"
                        error={buttonAction.hasError}
                        helperText={
                          buttonAction.hasError ? t('compound.amountcheck') : null
                        }
                        disabled={buttonAction.isenable==="enable"? false: true }
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              
                
                
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              color="primary"
              startIcon={<CancelIcon />}>
            {t('common.cancel.button')}
            </Button>
            <Button 
              onClick={handleClickCallMeta} 
              disabled={buttonAction.buttonDisable} 
              color="primary"
              startIcon={<ThumbUpIcon />}>
            {t('common.exe.button')}
            </Button>
          </DialogActions>
      </Dialog>

      <CardHeader
        action={
          <Tooltip title="Connect Wallet" arrow='true'>
            <IconButton 
              onClick={handleClick}
              aria-label="settings">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        }
        avatar={
          <Avatar
            className={classes.avatar}
            src={'/images/logos/compoundlog.png'}
          >
          </Avatar>  
        }
        title={getTitleLoading()}
        subheader={getWalletStatus() + " "　+ netWorkName()}
      >
        <CircularProgress />
    </CardHeader> 
      <Divider />
      <CardContent className={classes.content}>
      {values.map((market, i) => ( 
        <Card>
        <OverlayTrigger key={market.underlying_name} trigger="hover" placement="left" overlay={popover(market)}>
          <CardHeader 
            title= {market.underlying_name}
            subheader={xlendingUtils.toPercent(market.supply_rate.value, 2)}
            action={
              <div>
              <Tooltip title={t('compound.underbal') + " : " + getUnderlyingBalCell(market.underlying_address, market.underlying_symbol, 8, true)} arrow='true'>
                <IconButton className={classes.iconbutton}>
                    <Typography variant="h5" className={classes.div}>  
                      {getUnderlyingBalCell(market.underlying_address, market.underlying_symbol, 4, false)}
                    </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('compound.lendbalance') + " : " + getTokenBalance(market.token_address, 8)} arrow='true'>
                <IconButton className={classes.iconbutton} >
                  <Typography variant="h5" className={classes.div}>  
                    {getBalanceCell(market.token_address)}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('compound.lendaccrual') + " : " + getTokenAccrual(market.token_address, 8)} arrow='true'>
                <IconButton className={classes.iconbutton}>
                  <Typography variant="h5" className={classes.div}>  
                    {getAccrualCell(market.token_address)}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip title={getEnableTooltip(market.underlying_address, market.underlying_symbol)} arrow='true'>     
                <IconButton 
                    onClick={handleClickLending(market.underlying_address, market.token_address, market.underlying_symbol)}
                    >
                  <StatusBullet
                    className={classes.status}
                    color={statusColors[isContractEnable(market.underlying_address, market.underlying_symbol)]}
                    size="md"
                    onClick={handleClickLending(market.underlying_address, market.token_address, market.underlying_symbol)}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('compound.abouttorecall')} arrow='true'>
                <IconButton 
                  
                  onClick={handleClickRecall(market.underlying_address, market.token_address, market.underlying_symbol)} 
                  >
                  <UnarchiveIcon  color="primary" fontSize="small" className={classes.iconbuttonbig}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('compound.abouttolend')} arrow='true'>
                <IconButton 
                  onClick={handleClickLending(market.underlying_address, market.token_address, market.underlying_symbol)}
                  >
                  <AccountBalanceIcon  color="primary" fontSize="small" className={classes.iconbuttonbig}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('compound.swapuniswap')} arrow='true'>
                <IconButton 
                  onClick={handleClickSwap(market.underlying_address, market.token_address, market.underlying_symbol)}
                  >
                  <SwapHorizIcon  color="primary" fontSize="small" className={classes.iconbuttonbig}/>
                </IconButton>
              </Tooltip>
              </div>
            }
            titleTypographyProps={{variant:'h6' }}
            subheaderTypographyProps={{variant:'body1' }}
            avatar={
              <Avatar
                  className={classes.avatar}
                  src={getAvatarSource(market.underlying_symbol)}
                >
                </Avatar>  
            }
          />
        </OverlayTrigger>
        </Card>
      ))}
      {/* <PerfectScrollbar>
        <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{}</TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell>{t('compound.enable')}</TableCell>
                  <TableCell align="right">{t('trades.rate')}</TableCell>
                  <TableCell align="right" className={classes.tablecell} >{t('compound.underbal')}</TableCell>
                  <TableCell align="right" className={classes.tablecell} >{t('compound.lendbalance')}</TableCell>
                  <TableCell align="right" className={classes.tablecell} >{t('compound.lendaccrual')}</TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {values.map((market, i) => ( 
                  <TableRow
                      hover
                      key={market.symbol}
                    >
                    <TableCell>
                      <Avatar
                        //className={classes.avatar}
                        src={getAvatarSource(market.underlying_symbol)}
                      >
                        
                      </Avatar>  
                      {}
                      
                    </TableCell>
                    <TableCell>
                      <Box>    {market.underlying_name}</Box>
                    </TableCell>
                    <Tooltip title={getEnableTooltip(market.underlying_address, market.underlying_symbol)} arrow='true'>
                      <TableCell>
                        <div className={classes.statusContainer}>
                          <StatusBullet
                            className={classes.status}
                            color={statusColors[isContractEnable(market.underlying_address, market.underlying_symbol)]}
                            size="md"
                            onClick={handleClickLending(market.underlying_address, market.token_address, market.underlying_symbol)}
                          />
                        </div>
                      </TableCell>
                    </Tooltip>
                    <TableCell align="right">
                      <Box>{toPercent(market.supply_rate.value, 2)}</Box>
                    </TableCell>
                      <TableCell align="right"  className={classes.tablecell} >
                        {getUnderlyingBalCell(market.underlying_address, market.underlying_symbol, 4)}
                      </TableCell>
                    <Tooltip title={getTokenBalance(market.token_address, 8)} arrow='true'>
                    <TableCell align="right"  className={classes.tablecell} >
                          {getBalanceCell(market.token_address)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={getTokenAccrual(market.token_address, 8)} arrow='true'>
                      <TableCell
                        align="right"  className={classes.tablecell} >
                          {getAccrualCell(market.token_address)}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="right">
                      <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                        <Button 
                          onClick={handleClickLending(market.underlying_address, market.token_address, market.underlying_symbol)} 
                          color="primary"
                          className={classes.signInButton}
                          size="small"
                          variant={isContractEnable(market.underlying_address, market.underlying_symbol)==="enable"? "contained": "outlined" }
                          startIcon={isContractEnable(market.underlying_address, market.underlying_symbol)==="enable"? <AccountBalanceIcon/>: <CheckCircleOutlineIcon/>}
                          
                          >
                          {isContractEnable(market.underlying_address, market.underlying_symbol)==="enable"? t('compound.abouttolend'): t('compound.enable') }
                        </Button>
                        <Button 
                          onClick={handleClickRecall(market.underlying_address, market.token_address, market.underlying_symbol)} 
                          color="default"
                          className={classes.signInButton}
                          size="small"
                          startIcon={<UnarchiveIcon/>}
                          variant={isContractEnable(market.underlying_address, market.underlying_symbol)==="enable"? "contained": "outlined" }
                        >
                         {t('compound.abouttorecall')}
                        </Button>
                        <Tooltip title={t('compound.swapuniswap')} arrow='true'>
                          <Button 
                            onClick={handleClickSwap(market.underlying_address, market.token_address, market.underlying_symbol)} 
                            color="secondary"
                            className={classes.signInButton}
                            size="small"
                            variant="contained"
                            startIcon={<SwapHorizIcon />}
                          >
                            {t('compound.swap')}
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          </PerfectScrollbar> */}
      </CardContent>
    </Card>
  );
};

LatestMarkets.propTypes = {
  className: PropTypes.string
};

export default LatestMarkets;

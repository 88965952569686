import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';



const WhatPurposecn = props => {
  

  return (

    <div>
     
        
        <Alert severity={"info"}>
                  <Typography>
                  
                  你可以以非常有利可图的利率借出比特币以及其他加密货币，
                  而在Poloniex或Bitfinex这样的交易所风险很小。
                  自己来执行操作是有点困难的。因为：
                  <br/>
                 
                    <ui><li>利率波动很大</li>
                    <li>借款人可以随时偿还贷款。你就得再每分每秒看着你的账户再借出去</li>
                    </ui>
                    <br/>
                    为了更有效率得获得最大的收益，
                    你必须常常定期看管以管理你的账户。
                    在xLending得平台上，具有自动链接的借出功能，
                    如果贷款收回来之后，还可以自动续借。
                    续借的贷款的利率与上一贷款相同或更好。
                    这就是为什么它们现在可能高于或低于当前最佳利率的原因。
                    因此，贷款的利率太低的话，就会错失了更好的机会，
                    或者贷款的价格太高了，借款人将不予要借。
                    放款人在未领取贷款期间也错过了赚取利息的机会。
          
          
                    <br/>
                  <Divider />    
                  这就是xLending bot发挥作用的地方。
                  全自动一天24小时不间断地借出您所有的资金，
                  以实现最佳利率。</Typography>
                </Alert>
        
    </div>

    
  );
};

WhatPurposecn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPurposecn;

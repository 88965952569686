import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { PricingTable, PricingFAQ } from './components';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import xlendingUtils from 'xlendingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));



const Subscription = props => {

  const { className, subsPlans, ...rest } = props;

  console.log(subsPlans)

  const classes = useStyles();
  // const [plans, setPlans] = useState([])
  const [userplan, setUserplan] = useState([])
  
  const { user } = useAuth()
  let { uid: userId } = user || {}

  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
  });
  
  useEffect(() => {
      firebase.auth().onAuthStateChanged(function(myuser) {
        if (myuser)
        {
          userId = myuser.uid
          const unsubscribe = firebase
          .firestore()
          .collection('subscriptions')
          .doc(userId).onSnapshot(doc => {
            setUserplan(doc.data())
          })
          return () => { unsubscribe() }
        }
      })
    }, [])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <PricingFAQ 
          plans={subsPlans}
          userplan={userplan}/>
      </div>
      <div className={classes.content}>
        <PricingTable 
          plans={subsPlans}
          userplan={userplan}/>
      </div>
    </div>
  );
};

export default Subscription;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppsIcon from '@material-ui/icons/Apps';
import { useTranslation } from 'react-i18next'
import { 
  WhatIsXLending, 
  WhatIsXLendingja, 
  WhatIsXLendingcn, 
} from '../WhatIsXLending';

import { 
  WhatPurpose, 
  WhatPurposeja, 
  WhatPurposecn, 
} from '../WhatPurpose';

import { 
  WhatMinThreshold, 
  WhatMinThresholdja, 
  WhatMinThresholdcn, 
} from '../WhatMinThreshold';

import { 
  WhatPlatform, 
  WhatPlatformja, 
  WhatPlatformcn, 
} from '../WhatPlatform';

import { 
  WhatRisk, 
  WhatRiskja, 
  WhatRiskcn, 
} from '../WhatRisk';

import {
  Card,
  CardContent,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  CardHeader,
  Avatar,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteText: {
    color: theme.palette.white,
    //fontWeight: 300
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
}));



const LendingFAQ = props => {
  const { className } = props;
  

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(null);
  const { t, i18n } = useTranslation()

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getWhatIsXLending = () => {

    if(i18n.language === "cn"){
      return <WhatIsXLendingcn />
    }else if(i18n.language === "ja"){
      return <WhatIsXLendingja />
    }else{
      return <WhatIsXLending />
    }
  }

  const getWhatPurpose = () => {

    if(i18n.language === "cn"){
      return <WhatPurposecn />
    }else if(i18n.language === "ja"){
      return <WhatPurposeja />
    }else{
      return <WhatPurpose />
    }
  }

  const getWhatRisk = () => {

    if(i18n.language === "cn"){
      return <WhatRiskcn />
    }else if(i18n.language === "ja"){
      return <WhatRiskja />
    }else{
      return <WhatRisk />
    }
  }

  const getWhatPlatform = () => {

    if(i18n.language === "cn"){
      return <WhatPlatformcn />
    }else if(i18n.language === "ja"){
      return <WhatPlatformja />
    }else{
      return <WhatPlatform />
    }
  }

  const getWhatMinThreshold = () => {

    if(i18n.language === "cn"){
      return <WhatMinThresholdcn />
    }else if(i18n.language === "ja"){
      return <WhatMinThresholdja />
    }else{
      return <WhatMinThreshold />
    }
  }



  return (

    <div>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={4}
        >
          <div className={classes.quote}>
            <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('faq.general')}
              </Typography>
          </div>
        </Grid>
        <Grid
          className={classes.grid}
          item
          lg={8}
          xs={12}
        >
          <Card
          >
            <CardHeader 
              title={t('faq.general')}
              titleTypographyProps={{variant:'h2' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar className={classes.avatar}
                    //src='/images/avatars/xLending.png'
                  >
                    <AppsIcon />
                  </Avatar>  
              }
            />
            <CardContent>
              
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>{t('faq.whatisxlending')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getWhatIsXLending()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>{t('faq.purpose')}</Typography>
                {/* <Typography className={classes.secondaryHeading}>
                  Let's do a simulation
                </Typography> */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getWhatPurpose()}
              
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>{t('faq.risk')}</Typography>
                {/* <Typography className={classes.secondaryHeading}>
                  Let's do a simulation
                </Typography> */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getWhatRisk()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            
            <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5h-header"
              >
                <Typography className={classes.heading}>{t('faq.platformsupport')}</Typography>
                {/* <Typography className={classes.secondaryHeading}>
                  Let's do a simulation
                </Typography> */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              {getWhatPlatform()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6h-header"
              >
                <Typography className={classes.heading}>{t('faq.minthreshold')}</Typography>
                {/* <Typography className={classes.secondaryHeading}>
                  Let's do a simulation
                </Typography> */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              {getWhatMinThreshold()}
              
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
            
            </CardContent>
          </Card>
         
        </Grid>
      </Grid>
    </div>

    
  );
};

LendingFAQ.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LendingFAQ;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';




const WhatRisk = props => {
 
  return (

    <div>
      
        <Alert severity={"info"}>
                  <Typography>
                      
                  Storing your API credentials at xLending does not contain any risk. 
                  No withdrawal of funds or trading is possible with these credentials. 
                  On the unlikely chance that xLending gets hacked and your credentials are stolen, 
                  the hacker could not do anything harmful with them.

                  Also, one of the main risk is the Couterparty risk. That is to say a default of the exchange.

                  The reasons can be :<br/>

                  <br/>Hack of the exchange : fraud or seizure,
                  <br/>A very large price movement where loans can not be covered, nor insured.
                  <br/>Please also check twice that the API keys communicated on our site do not allow withdrawal nor trading.

                  <br/>And don't forget that your capital will not be available for the time of the loans.


                  </Typography>
                </Alert>
       
    </div>

    
  );
};

WhatRisk.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatRisk;

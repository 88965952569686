import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const WhyNoLendingcn = props => {
  
  return (

    <div>
     
        
        <Alert severity={"info"}>
        <Typography>
              有几个可能性，例如：
                  <br/>
                  <br/>1. 报价价格低于您的最低价格设置。
                  <br/>2. 您要借出的资产低于交易所的最低金额（Bitfinex 50 USD / Poloniex 0.01 BTC）。
                  </Typography>
          </Alert>
              
     
    </div>

    
  );
};

WhyNoLendingcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhyNoLendingcn;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowPayPalja = props => {
  const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          登録するプランの[購読]をクリックします
          <br/>
          PayPal支払い方法を選択してください
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/payment-1.png"} width="100%" height="250"/>
          </div>
          PayPalボタンをクリックして、指示に従います
          <br/>
          
          ログインのためにPayPalウェブサイトにリダイレクトされます
          <br/>
          
          <Divider/>
          PayPalで支払いが完了したら、システムが更新されるまでしばらくお待ちください
          <br/>
          30〜60分経ってもタブに「Payment Not Paid」と表示される場合。 support@xlending.ccでサポートにご連絡ください
          </Typography>
        </Alert>
    </div>

    
  );
};

HowPayPalja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPayPalja;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const WhatRiskja = props => {
  

  return (

    <div>
     
        <Alert severity={"info"}>
                  <Typography>
                      
                  APIキーをxLendingに保存してもリスクはありません。
                  ハッカーは、xLendingから資金の引き出しや取引はできません。
                  xLendingがハッキングされても、ハッカーはユーザーに対して不利益なことは何もできません。
                  ですが、以下、3つのリスクには注意してください。
                  <br/>
                  <br/>
                  <ui>
                    <li>
                    1つ目はPoloniex、Bitfinexなどの取引所に起因するリスクです。例えば、価格変動リスク、流動性リスク、システムリスク、法令・税制変更リスク、取引所の倒産リスクなどです。これらは、すべて取引所に起因したリスクです。詳細は、Poloniex、Bitfinexなどのサイトを参照ください。
                    </li>
                    <li>
                    2つ目は、Poloniex、Bitfinex などで、APIキー発行をする際に、出金の許可ボタンを押してしまったことに起因するリスクです。当サイトで登録したAPIキー発行の際に、出金の許可していないことをもう一度確認してください。
                    </li>
                  </ui>
                    参照：
                    <br/>
                    出金の許可ボタンを押してしまうとハッカーが資金の引き出しや取引を出来てしまう可能性があります。


                  </Typography>
                </Alert>
      
    </div>

    
  );
};

WhatRiskja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatRiskja;

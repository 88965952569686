import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6,6),
    justifyContent: 'center',
    display: 'flex',

  },
  content: {
    paddingTop: 250,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  quoteContainer: {
    justifyContent: 'center',
  },
  quote: {
    paddingTop: 250,
    backgroundColor: theme.palette.neutral,
    //height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/thankyou.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  // quoteInner: {
  //   textAlign: 'center',
  //   flexBasis: '600px'
  // },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 300,
    textAlign: 'center',
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.secondary,
    textAlign: 'center',
  },
}));

const ThankYou = () => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
         <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <Link
            component={RouterLink}
            to="/sign-in"
            variant="h3"
          >
          <div className={classes.quote}>
            
          </div>
          </Link>
          <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('signup.thankyousignup')}
                
              </Typography>
              <div className={classes.quoteText}>
                <Typography
                  className={classes.quoteText}
                  variant="h3"
                >
                   {t('signup.verify.msg')}
                </Typography>
                
              </div>
        </Grid>
    </div>
  );
};

export default ThankYou;

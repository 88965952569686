import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import RateReviewIcon from '@material-ui/icons/RateReview';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import { Alert, AlertTitle } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
  Divider,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  Switch,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  ButtonGroup
} from '@material-ui/core';


const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 200
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 80,
      margin: 5
    }
  },
  avatar: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  avatarPopup: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  formControlMid: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  text: {
    width: 120,
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      margin: 5
    }
  },
  select: {
    width: 150,
    margin: 5,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      margin: 5
    }
  },
  griditem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttontext: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  button: {
    textAlign: "center" 
  },
  tableRow: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  

}));


function getStrategySource(stra) {
  let avatarUrl = ""
  if (stra === "basic") {
    avatarUrl = '/images/avatars/basic.png'
  }
  if (stra === "premium") {
    avatarUrl = '/images/avatars/premium.png'
  }
  if (stra === "advance") {
    avatarUrl = '/images/avatars/advance.png'
  }
  return avatarUrl
}


const BotsTable = props => {
  const { className, symbols, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()

  const { uid: userId } = user || {}
  const [state, setState] = useState({
    exchangename: 'bitfinex',
    symbol: '',
    strategy: 'basic',
    enable: false,
  });

  const [open, setOpen] = React.useState(false);
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [openEst, setOpenEst] = React.useState(false);
  
  const [popupmessageconfirm, setPopupmessageconfirm] = React.useState("");
  const [removeid, setRemoveId] = React.useState("");
  const [estimateProfit, setEstimateProfit] = React.useState([]);
  
  const handleClickMsgOpen = (msg) => {
    ref.current.handleOpen(msg, "error");
  };

  const handleClickMsgOpenWithImage = (msg, status) => {
    ref.current.handleShowImage(true, "/images/products/startstop.png", msg, status);
    //ref.current.handleOpen(msg, "success");
  };

  const handleClickConfirmOpen = () => {
    setOpenconfirm(true)
  };

  const handleNo = () => {
    setOpenconfirm(false)
    setRemoveId("")
  };

  const handleYes = () => {
    
    if(removeid !== ""){
      firebase.firestore().collection('symbols_config').doc(removeid).delete().then(async () => {
        //handleClickMsgOpen(t('common.msg.successdelete'))
        handleClickMsgOpenWithImage(t('botsetup.msg.successdelete'), "success")
      })
      setOpenconfirm(false)
      setRemoveId("")  
    }
  };

  const [editdatas, setEditdatas] = useState({
    edit_id: '',
    edit_exchangename: '',
    edit_symbol: '',
    edit_strategy: '',
    edit_enable: false,
  });

  const handleClickOpen = name => event => {
    
    let result = symbols.filter(t => t.id === name)
    setEditdatas({
      edit_id: result[0].id,
      edit_exchangename: result[0].exchange,
      edit_strategy: result[0].strategy,
      edit_enable: result[0].enable,
      edit_symbol: result[0].code
    })
    setOpen(true);

  };

  const handleCloseEst = () => {
    setOpenEst(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEst = () => {
    setOpenEst(true);
  };


  const validateInput = () => {
    
    let msg = t('common.msg.blank') + ": \n"
    if(state.length === 0)
    {
      return t('common.msg.blank') + ": \n"
    }   
    if(state.exchangename === ""){
      return msg + t('botsetup.exchange') 
    }
    if(state.symbol === ""){
      return msg + t('botsetup.symbol')
    }
    if(state.strategy === ""){
      return msg + t('botsetup.strategy')
    }
    return ""
    //return true
  }


  const getActiveString = (name) => {
    let actiFlag = t('botsetup.inactive')
    if (name) {
      actiFlag = t('botsetup.active')
    }
    return actiFlag
  }

  const ref = useRef(null);

  const handleClickDelete = name => event => {
    
    //alert(name)
    let msg = t('common.msg.confirmdelete')
    setPopupmessageconfirm(msg)
    setRemoveId(name)
    handleClickConfirmOpen()

  };

  const handleClickEditUpdate = event => {
    // To update age and favorite color:
    firebase.firestore().collection("symbols_config").doc(editdatas.edit_symbol + "_" + editdatas.edit_exchangename + "_" + userId).update({
      "enable": editdatas.edit_enable,
      "strategy": editdatas.edit_strategy
    })
      .then(function () {
        setOpen(false);
        //handleClickMsgOpen(t('common.msg.successupdate'))
        handleClickMsgOpenWithImage(t('botsetup.msg.successupdate'), "success")
        setEditdatas({
          edit_id: "",
          edit_exchangename: "",
          edit_strategy: "",
          edit_enable: false,
          edit_symbol: "",
        })
      });
  };

  const handleClickAdd = event => {
    
    
    event.preventDefault();

    const msg = validateInput()
    if(msg !== ""){
      handleClickMsgOpen(msg)
      return
    } 
    
    let result = symbols.filter(t => t.code === state.symbol && t.exchange === state.exchangename)
    if(result.length > 0){
      handleClickMsgOpen(t('common.msg.duplicate'))
      return
    }

    //console.log(state.symbol)
    
    // To update age and favorite color:
    firebase.firestore().collection("symbols_config").doc(state.symbol + "_" + state.exchangename + "_" + userId).set({
        code: state.symbol,
        exchange: state.exchangename,
        user: userId,
        enable: state.enable,
        strategy: state.strategy,
        sleep: 5,
        offer: {
          amount: 9999999999,
          frrMultipleLow: 1.0,
          frrMultipleHigh: 1.0,
          atLeastLow: 0.00000,
          atLeastHigh: 0.1,
          minOrderSize: 0.8,
          orderCount: 2,
          randomAmountsPercent: 0,
          easing: "linear"
        },
        alert: {
          rate: 0.01,
          maxFrequency: 5,
          alertMessage: "alert message here."
        }
    }).then(function () {

      handleClickMsgOpenWithImage(t('botsetup.msg.successadd'), "success")
      // setState({
      //   exchangename: "bitfinex",
      //   strategy: "basic",
      //   enable: false,
      //   symbol: ""
      // })
    })
  };

  const handleChangeEdit = name => event => {
    setEditdatas({
      ...editdatas,
      [name]: event.target.value,
    });
  };

  const handleSwitchChangeEdit = name => event => {

    setEditdatas({ ...editdatas, [name]: event.target.checked });
  };

  const handleChange = name => event => {
    
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  
  const handleClickCalc = () => event => {

    let db = firebase.firestore()

    fetch('/api/getTickers', {
        method: 'GET'
      }).then(response => response.json())
      
      .then(data => {
        console.log(data)
        
        if(data.status === "1000"){
         
          let finalamount = 0
          let profitarr = []

          symbols.forEach(s => {

            let bal 
            let docRef = db.collection("wallets")
            docRef
            .where('user', '==', userId)
            .where('symbol', '==', s.code)
            .orderBy("updatedAt", "desc")
            .limit(1)
            .onSnapshot((snapshot) => {
              const newValues = snapshot.docs.map((doc) => ({
                ...doc.data()
              }))   
              //console.log(newValues)
      
              bal = newValues[0].balance
              //console.log(bal)

              let frr
              let frrRef = db.collection("ticker_frr_avg")
              frrRef
              .where('symbol', '==', s.code)
              .limit(1)
              .onSnapshot((snapshot) => {
                const newFrr = snapshot.docs.map((doc) => ({
                  ...doc.data()
                }))   
                console.log(newFrr)
        
                frr = newFrr[0].frr
                console.log(frr)
                
                let annFrr = Math.pow(frr+1, 365) -1 
                //((frr+1)^365-1)
                console.log(annFrr)
                let sym = "t"+ s.code + "USD"
                let f = data.message.find(t=>t.symbol === sym)
                console.log(f)
      
                let value = f.price * bal
                console.log(value)

                finalamount = (1+annFrr) * value
                console.log(finalamount)
                profitarr.push({
                  symbol: s.code,
                  amount :finalamount
                })
                
              })
            })
            console.log(profitarr)
            setEstimateProfit(profitarr)
          });

          //console.log(estimateProfit)
        }
    });

    

    // if no found, let user to input
    // get from ticker_frr_avg
    // annualize the rate  

  }

  const getTotalEstProfit = () => {

    let total = 0
    console.log(estimateProfit.length)
    for(let i=0; i< estimateProfit.length; i++){
      total = total + estimateProfit[i].amount
    }
    // estimateProfit.forEach(p => {
    //   console.log(p.amount)
    //   total = total + p.amount
    // });
    console.log(total)
    return total
  } 


  const handleSwitchChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const supportedSymbols = [
  { symbol: 'BCH'},
  { symbol: 'BSV'},
  { symbol: 'BTC'},
  { symbol: 'BTG'},
  { symbol: 'DASH'},
  { symbol: 'EDO'},
  { symbol: 'EOS'},
  { symbol: 'ETC'},
  { symbol: 'ETH'},
  { symbol: 'ETP'},
  { symbol: 'EUR'},
  { symbol: 'GBP'},
  { symbol: 'IOTA'},
  { symbol: 'JPY'},
  { symbol: 'LEO'},
  { symbol: 'LTC'},
  { symbol: 'NEO'},
  { symbol: 'OMG'},
  { symbol: 'SAN'},
  { symbol: 'USD'},
  { symbol: 'USDt'},
  { symbol: 'XLM'},
  { symbol: 'XMR'},
  { symbol: 'XRP'},
  { symbol: 'XTZ'},
  { symbol: 'ZEC'},
  { symbol: 'ZRX'},
];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={openconfirm}
        fullWidth={true}
        maxWidth = {'sm'}
        keepMounted
        onClose={handleNo}
        aria-labelledby="confirm-dialog-slide-title"
        aria-describedby="confirm-dialog-slide-description"
      >
        {/* <DialogTitle id="confirm-dialog-slide-title">{"Confirm"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="confirm-dialog-slide-description">
            
            <Alert severity={"warning"}>
              <AlertTitle><Typography variant="h5">{"Confirm"}</Typography></AlertTitle>
              {popupmessageconfirm}
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleNo} 
            color="primary"
            startIcon={<CancelIcon />}>
            No
          </Button>
          <Button 
            onClick={handleYes} 
            color="primary"
            startIcon={<ThumbUpIcon />}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <PopupMessage ref={ref} />
      
      <Dialog 
          open={open} 
          onClose={handleClose} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth = {'sm'}
          >
          <DialogTitle id="form-dialog-title">{t('common.update.button')} {t('botsetup.exchange')}: {editdatas.edit_exchangename.toUpperCase()} </DialogTitle>
          <DialogContent>
            <DialogContentText>

            {t('common.update.button')} {t('botsetup.symbol')}: {editdatas.edit_symbol}

            </DialogContentText>
            <Table>
              <TableRow className={classes.tableRow}>
                <TableCell>

                  <div className={classes.nameContainer}>
                    <Avatar
                      className={classes.avatarPopup}
                      src={getStrategySource(editdatas.edit_strategy)}
                    >
                      
                    </Avatar>
                    <Typography variant="body1">
                      <FormControl className={classes.formControl}>
                        <Select
                          className={classes.select}
                          value={editdatas.edit_strategy}
                          onChange={handleChangeEdit('edit_strategy')}
                          inputProps={{
                            name: 'edit_strategy',
                            id: 'edit_strategy',
                          }}
                        >
                          <MenuItem value="basic">{t('strategy.basic')}</MenuItem>
                          <MenuItem value="premium">{t('strategy.premium')}</MenuItem>
                          <MenuItem value="advance">{t('strategy.advance')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow className={classes.tableRow}>
                <TableCell>
                  <div className={classes.nameContainer}>
                    <Avatar
                      className={classes.avatar}
                    >
                      A/I
                    </Avatar>
                    <FormControl className={classes.formControl}>
                      <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>{t('botsetup.inactive')}</Grid>
                          <Grid item>
                            <AntSwitch
                              checked={editdatas.edit_enable}
                              onChange={handleSwitchChangeEdit('edit_enable')}
                              inputProps={{
                                name: 'edit_enable',
                                id: 'edit_enable',
                              }}
                              color="primary"
                            />
                          </Grid>
                          <Grid item>{t('botsetup.active')}</Grid>
                        </Grid>
                      </Typography>
                    </FormControl>
                  </div>
                </TableCell>
              </TableRow>

            </Table>


          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              color="primary"
              startIcon={<CancelIcon />}>
            {t('common.cancel.button')}
            </Button>
            <Button 
              onClick={handleClickEditUpdate} 
              color="primary"
              startIcon={<EditIcon />}>
            {t('common.update.button')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openEst} 
          onClose={handleCloseEst} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth = {'sm'}
          >
          <DialogTitle id="form-dialog-title">Estimating Profit Returns</DialogTitle>
          <DialogContent>
            <DialogContentText>

              Estimated based on the 30-d average FRR and your position in Exchange = {getTotalEstProfit()} USD

            </DialogContentText>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> {t('botsetup.exchange')} </TableCell>
                      <TableCell>{t('botsetup.symbol')}</TableCell>
                      <TableCell>{t('botsetup.balance')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {symbols.slice(0, 10).map(symbol => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={symbol.id}
                      >

                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Avatar
                              className={classes.avatar}
                              src={xlendingUtils.getAvatarSource(symbol.exchange)}
                            >
                              {symbol.exchange}

                            </Avatar>
                            <Typography variant="body1">{symbol.exchange}</Typography>
                          </div>
                        </TableCell>
                        <TableCell>{symbol.code}</TableCell>
                        <TableCell>
                        <TextField
                          className={classes.text}
                          //error={hasError('firstName')}
                          fullWidth
                          // helperText={
                          //   hasError('firstName') ? formState.errors.firstName[0] : null
                          // }
                          //label="First name"
                          name="balance"
                          //onChange={handleChange}
                          type="text"
                          //value={formState.values.firstName || ''}
                          variant="outlined"
                        />

                        </TableCell>
                        
                      </TableRow>
                    ))}

                  </TableBody>
                  
                </Table>
              
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleCloseEst} 
              color="primary"
              startIcon={<CancelIcon />}>
            {t('common.cancel.button')}
            </Button>
            <Button 
              onClick={handleClickCalc()} 
              color="primary"
              startIcon={<ThumbUpIcon />}>
            {t('common.calc.button')}
            </Button>
          </DialogActions>
        </Dialog>

      <CardHeader
          action={
            <Tooltip title={t('botsetup.estimate')} arrow='true'>    
              <IconButton 
                onClick={handleOpenEst}
                aria-label="settings">
                <RateReviewIcon />
              </IconButton>
            </Tooltip>
          }
        >
      </CardHeader> 
      <Divider />
      <CardContent className={classes.content}>
        
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('botsetup.exchange')} </TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell className={classes.griditem}>{t('botsetup.strategy')}</TableCell>
                  <TableCell>{t('botsetup.active')}</TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {symbols.slice(0, 10).map(symbol => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={symbol.id}
                  >

                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={xlendingUtils.getAvatarSource(symbol.exchange)}
                        >
                          {symbol.exchange}
                        </Avatar>
                        <Typography variant="body1">{symbol.exchange.toUpperCase()}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{symbol.code}</TableCell>
                    <TableCell className={classes.griditem}>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={getStrategySource(symbol.strategy)}
                        >
                          {symbol.strategy}
                        </Avatar>
                        <Typography variant="body1">{symbol.strategy}</Typography>
                      </div>

                    </TableCell>
                    <TableCell>{getActiveString(symbol.enable)}</TableCell>
                    <TableCell>
                    <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={handleClickOpen(symbol.id)}
                        >
                          <span className={classes.buttontext}>{t('common.update.button')}</span>
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.button}
                          startIcon={<DeleteIcon />}
                          onClick={handleClickDelete(symbol.id)}
                        >
                          <span className={classes.buttontext}>{t('common.delete.button')}</span>
                          
                        </Button>
                    </ButtonGroup>
                    </TableCell>
                    
                  </TableRow>
                ))}

                <TableRow className={classes.tableRow}>
                  <TableCell>
                    <div className={classes.nameContainer}>
                      <Avatar
                        className={classes.avatar}
                        src={xlendingUtils.getAvatarSource(state.exchangename)}
                      >
                        {t('common.add.button')}
                      </Avatar>
                      <Typography variant="body1">
                        <FormControl className={classes.formControlMid}>
                          <Select
                            className={classes.select}
                            value={state.exchangename}
                            onChange={handleChange('exchangename')}
                            inputProps={{
                              name: 'exchangename',
                              id: 'exchangename',
                            }}
                          >
                            <MenuItem value="bitfinex">Bitfinex</MenuItem>
                            <MenuItem value="poloniex">Poloniex</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>


                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="symbol"
                      name="symbol"
                      options={supportedSymbols}
                      getOptionLabel={option => option.symbol}
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setState({
                          ...state,
                          symbol: newValue == null? "" : newValue.symbol
                        });
                      }}
                      className={classes.text}
                      renderInput={params => (
                        <TextField 
                          {...params} 
                          margin="normal" 
                          fullWidth 
                        />
                      )}
                    />
                    {/* <TextField
                      className={classes.text}
                      value={state.symbol}
                      onChange={handleChange('symbol')}
                      inputProps={{
                        name: 'symbol',
                        id: 'symbol',
                      }}
                      //variant="outlined"
                      label="Symbol"
                    /> */}
                  </TableCell>
                  <TableCell className={classes.griditem}>
                    <div className={classes.nameContainer}>
                      <Avatar
                        className={classes.avatar}
                        src={getStrategySource(state.strategy)}
                      >
                        {t('common.add.button')}
                      </Avatar>
                      <Typography variant="body1">
                        <FormControl className={classes.formControlMid}>
                          <Select
                            className={classes.select}
                            value={state.strategy}
                            onChange={handleChange('strategy')}
                            inputProps={{
                              name: 'strategy',
                              id: 'strategy',
                            }}
                          >
                          <MenuItem value="basic">{t('strategy.basic')}</MenuItem>
                          <MenuItem value="premium">{t('strategy.premium')}</MenuItem>
                          <MenuItem value="advance">{t('strategy.advance')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>

                  </TableCell>
                  <TableCell>

                    <Typography component="div">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item className={classes.griditem}>{t('botsetup.inactive')}</Grid>
                        <Grid item>
                          <AntSwitch
                            checked={state.checkedC}
                            onChange={handleSwitchChange('enable')}
                            value="enable"
                            color="primary"
                          />
                        </Grid>
                        <Grid item className={classes.griditem}>{t('botsetup.active')}</Grid>
                      </Grid>
                    </Typography>


                  </TableCell>
                  <TableCell>
                    <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<AddCircleIcon />}
                        onClick={handleClickAdd}
                      >
                        <span className={classes.buttontext}>{t('common.add.button')}</span>
                        
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              </TableBody>
              
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

BotsTable.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default BotsTable;

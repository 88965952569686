import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardContent,
  Typography,
  Link,
  CardHeader,
  Avatar,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    
  },
  cardPricing: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(5, 5),
    display: 'flex',
    justifyContent: 'center'
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(20),
    //flexBasis: '33.33%',
    //flexShrink: 0,
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: theme.typography.pxToRem(10),
    // },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  quoteContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  quote: {
   
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundImage: 'url(/images/automation.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    //height: 200,
    position: 'relative',
    zindex: 0, 

  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.primary.main,
    fontWeight: 300
  },
  grid: {
     padding: theme.spacing(1, 3, 1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
}));



const LearnSimulation = props => {
  
  const classes = useStyles();
  const { t } = useTranslation()
  return (
        <Card
            className={classes.cardPricing}
          >
            <CardHeader 
              title={t('learnsimulation.title')}
              subheader={t('learnsimulation.subheader')}
              titleTypographyProps={{variant:'h3' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                    src='/images/avatars/advance.png'
                  > 
                  </Avatar>  
              }
            />
           
            <CardContent>
              <Typography variant="h3">
              {t('learnsimulation.learnmore')}              
              </Typography>
              <Typography variant="h6">
                <Link
                  component={RouterLink}
                  to="/simulations"
                >
                  {t('learnsimulation.trytool')} 
                  </Link>
              </Typography>

            </CardContent>
            
        </Card>
  );
};

LearnSimulation.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LearnSimulation;

import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Select, MenuItem, FormControl,Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import firebase from "firebase/app";
import Guides from '../Guides/Guides'
import HelpIcon from '@material-ui/icons/Help';
import Circular from 'views/Circular/Circular'
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  whiteColor: {
    color: "white"
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const { t, i18n } = useTranslation()
  const [values, setValues] = useState([])
  const refCircular = useRef(null);
  
  const auth = useAuth()
  const { user } = useAuth()
  const { uid: userId } = user || {}
  const ref = useRef(null);
  const handleClick = event => {
    
    refCircular.current.handleOpen(true)
    auth.signout();
    //props.history.push('/sign-in')
    
  }

  const handleClickHome = event => {
    props.history.replace('/home')
  }

  const handleClickfaq = event => {
    props.history.replace('/faq')
  }

  useEffect(() => {
    if (userId)
      firebase
        .firestore()
        .collection('users')
        .doc(userId).onSnapshot(doc => {
        setValues(doc.data())
        //console.log(values)
        i18n.changeLanguage(values.lang)
      })

  }, [])


  const handleClickOpenGuide = event => {
    
    ref.current.handleOpen("msg")
  }

  const handleChangeLang = () => event => {
    //setLang(event.target.value)
    setValues({
      ...values,
      lang: event.target.value
    })
    i18n.changeLanguage(event.target.value)
  }

  //console.log(values.lang)
  //i18n.changeLanguage(values.lang)

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Circular ref={refCircular} />
      <Toolbar>
        <RouterLink to="/">
          {/* <Avatar 
            className={classes.avatar}
            onClick={handleClick}
            >
            X
          </Avatar> */}
          <img
            alt="Logo"
            src="/images/avatars/xWinLogo-2.jpg"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Guides ref={ref} />
        <Hidden mdDown>
          {/* <Link
              component={RouterLink}
              to="/home"
              //color="white"
            > */}
          <Tooltip title="Home" arrow='true'>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={handleClickHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
          {/* </Link> */}
          <Tooltip title="Logout" arrow='true'>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={handleClick}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
          >
            <FormControl className={classes.formControl}>
              <Select
                //value={values.lang || "en"}
                onChange={handleChangeLang()}
                classes={{
                  root: classes.whiteColor,
                  icon: classes.whiteColor
                }}
                inputProps={{
                  name: 'lang',
                  id: 'lang',
                }}
              >
                <MenuItem value="en">{t('common.language-english')}</MenuItem>
                <MenuItem value="ja">{t('common.language-japanese')}</MenuItem>
                <MenuItem value="cn">{t('common.language-chinese')}</MenuItem>
              </Select>
            </FormControl>
            </IconButton>
            
          
          
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

//export default Topbar;
export default withRouter(Topbar);

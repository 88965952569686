import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToSetupBotcn = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            我将解释如何自动交易Bitfinex。您可以使用各种虚拟货币，但是这次，我将解释如何使用比特币现金进行设置。
            <br/>
            <br/>
            
            1。接下来，转到xLending。
            <br/>
            2。如上所述，单击xLending的Bot设置。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-1.png"} width="100%"/>
            </div>
            3。单击Bitfinex的“设置”，如下所示。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-2.png"} width="100%"/>
            </div>
            <br/>
            4.单击[BAB]，如下所示。 Bitfinix将BCH称为BAB。
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-3.png"} width="100%"/>
            </div>
            5.设置每日最低利率如下：然后，按[保存]按钮。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-4.png"} width="100%"/>
            </div>
            <br/>
            6.这样就完成了设置。之后，该漫游器将自动运行24小时/ 365天。当借入的货币恢复时，机器人将计算并设置最佳利率，并自动设置下一个贷款。为用户节省时间和精力。
            <br/>
            
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToSetupBotcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToSetupBotcn;

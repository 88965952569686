import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToStart0cn = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            1．	登录Bitfinex主页ジムペジジ　https://www.bitfinex.com/
            <br/>
            2．	单击下面的 ［Wallet］→［Deposit］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-1.png"} width="100%"/>
            </div>
            3．	选择您要汇出的虚拟货币，然后单击。 [重要提示：请确认你要的代币]
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-2.png"} width="100%"/>
            </div>
            4．[示例]我们以BCH作为示例，继续进行说明。
            <br/>
            5．如下所示，请确认那个是您选择的虚拟货币。
            <br/>
            6．单击[Funding Wallet]中的[Click to generate address]。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            7．	然后从您自己的虚拟货币钱包发送到这个交易所的地址。
            <br/>
            8．	请确认虚拟货币是否已成功汇出。单击[Wallet]→[Wallets]，然后移至以下屏幕。并检查。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-4.png"} width="100%"/>
            </div>
           
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToStart0cn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToStart0cn;

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";

import {
  TotalBots,
  Subscription,
  BotStatus,
  TotalProfit,
  LatestOrders,
  PaymentOffers,
  PortfolioWallet,
  ProfitPayments
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1)
    }
  }
}));

function getFirstLastDate(myval){

  let date = new Date();
  //console.log(myval)
  if(myval === "prev"){
    let lastfirstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
    date = new Date(lastfirstDay.getFullYear(), lastfirstDay.getMonth(), 1);
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);

  var firstDayNextMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
  firstDayNextMonth.setHours(0, 0, 0, 0);

  //console.log({firstDay, firstDayNextMonth})
  return {firstDay, firstDayNextMonth}
}

const Dashboard = props => {
  const { exRates, ...rest } = props;
  const classes = useStyles();
  const [userPayments, setUserPayments] = useState([])

  const { user } = useAuth()
  let { uid: userId } = user || {}

  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
  });

  let mydate = getFirstLastDate()

  useEffect(() => {
    
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        //setIsLoading(true)
        userId = myuser.uid
        const unsubscribe = firebase
        .firestore()
        .collection('user_payments')
        .where('user', '==', myuser.uid)
        .where('pay_time', '>=', mydate.firstDay)
        .where('pay_time', '<=', mydate.firstDayNextMonth)
        .orderBy("pay_time", "desc")
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            key: doc.data().pay_time.seconds * 1000,
            ...doc.data()
          }))
          setUserPayments(newValues);
        })
        return () => { unsubscribe() }
      }
    })
    
    }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Subscription />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalBots />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <BotStatus />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit 
            userPayments={userPayments}
            exRates={exRates}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xl={9}
          xs={12}
        >
          <ProfitPayments />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xl={9}
          xs={12}
        >
          <PortfolioWallet
            exRates={exRates}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xl={9}
          xs={12}
        >
          <PaymentOffers 
            userPayments={userPayments}
           />
        </Grid>
        <Grid
            item
            lg={6}
            md={12}
            xl={9}
            xs={12}
        >
          <LatestOrders />
        </Grid>
        
      </Grid>
    </div>
  );

};

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';



const WhatPurposeja = props => {
  //const { className } = props;
  

  return (

    <div>
    
        
        <Alert severity={"info"}>
                  <Typography>
                  
                  このサービスの目的は、あなたの仮想通貨を自動で運用し、財産を増やすことです。
                  Poloniex、Bitfinexなどの取引所では、ローリスクで、ビットコインやその他の暗号通貨を高い金利で貸すことができます。
                  ただし、多くの場合は、手動で行う必要があり、手間と時間を浪費します。金利は、高くなったり、低くなったりします。
                  Poloniex、Bitfinexなどの取引所で最大限の収益を得るには、定期的にログインして金利の動きを注視し、手動で注文する必要があります。
                  <br/>
                  <br/>
                  しかし、例えば、Poloniex、Bitfinexなどの取引所では、ローンが返済された場合にローンを自動的に更新する自動更新機能などがあります。更新されたローンは、前のローンと同じ金利で作成されます。
                  そのため、その金利が現在の最適金利を上回ったり下回ったりする可能性があります。
                  したがって、ローンの金利が低すぎて潜在的な利益が捨てられるか、ローンの金利が高すぎて借り手に引き継がれない場合があります。
                  また、貸し手はローンが組まれてない時間の間の利子を見逃します。このようなロスを防ぐため、xLendingは有効なツールです。
                  完全に自動化されたxLendingのシステムは、可能な限りの最高の金利を探し、すべての資産を有効に貸し出すために24時間／365日、
                  働いてくれます。
                  </Typography>
                </Alert>
    </div>

    
  );
};

WhatPurposeja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPurposeja;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HowToSetupBot, HowToSetupBotcn, HowToSetupBotja } from '../HowToSetupBot';
import { HowToStartPoloniex, HowToStartPoloniexcn, HowToStartPoloniexja } from '../HowToStartPoloniex';
import { HowToStart0, HowToStart0cn, HowToStart0ja } from '../HowToStart0';
import { HowBitfinex, HowBitfinexcn, HowBitfinexja } from '../HowBitfinex';
import { HowPoloniex, HowPoloniexcn, HowPoloniexja } from '../HowPoloniex';
import { WhyNoLending, WhyNoLendingcn, WhyNoLendingja } from '../WhyNoLending';
import { 
  HowNotification, 
  HowNotificationja, 
  HowNotificationcn, 
  HowNotificationTelegram, 
  HowNotificationTelegramcn,
  HowNotificationTelegramja
} from '../HowNotification';
import { HowCompound } from '../HowCompound';
import BuildIcon from '@material-ui/icons/Build';
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardContent,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  CardHeader,
  Avatar,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
}));



const LendingFAQTechnical = props => {
  const { className, ...rest } = props;
  const { t, i18n } = useTranslation()

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = panel => (event, isExpanded) => {
    console.log(panel)
    console.log(isExpanded)
    setExpanded(isExpanded ? panel : false);
  };

  const getHowToSetupBot = () => {

    if(i18n.language === "cn"){
      return <HowToSetupBotcn />
    }else if(i18n.language === "ja"){
      return <HowToSetupBotja />
    }else{
      return <HowToSetupBot />
    }
  }

  const getHowToStartPolo = () => {

    if(i18n.language === "cn"){
      return <HowToStartPoloniexcn />
    }else if(i18n.language === "ja"){
      return <HowToStartPoloniexja />
    }else{
      return <HowToStartPoloniex />
    }
  }

  const getHowToStart = () => {

    if(i18n.language === "cn"){
      return <HowToStart0cn />
    }else if(i18n.language === "ja"){
      return <HowToStart0ja />
    }else{
      return <HowToStart0 />
    }
  }

  const getBitfinex = () => {

    if(i18n.language === "cn"){
      return <HowBitfinexcn />
    }else if(i18n.language === "ja"){
      return <HowBitfinexja />
    }else{
      return <HowBitfinex />
    }
  }

  const getPoloniex = () => {

    if(i18n.language === "cn"){
      return <HowPoloniexcn />
    }else if(i18n.language === "ja"){
      return <HowPoloniexja />
    }else{
      return <HowPoloniex />
    }
  }

  const getNotificationLine = () => {

    if(i18n.language === "cn"){
      return <HowNotificationcn />
    }else if(i18n.language === "ja"){
      return <HowNotificationja />
    }else{
      return <HowNotification />
    }
  }

  const getNotificationTelegram = () => {

    if(i18n.language === "cn"){
      return <HowNotificationTelegramcn />
    }else if(i18n.language === "ja"){
      return <HowNotificationTelegramja />
    }else{
      return <HowNotificationTelegram />
    }
  }

  const getWhyNoLending = () => {

    if(i18n.language === "cn"){
      return <WhyNoLendingcn />
    }else if(i18n.language === "ja"){
      return <WhyNoLendingja />
    }else{
      return <WhyNoLending />
    }
  }


  return (

    <div>
      <Grid
        className={classes.root}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={4}
        >
          <div className={classes.quote}>
            <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('faq.technical')}
              </Typography>
          </div>
        </Grid>
        <Grid
          className={classes.grid}
          item
          lg={8}
          xs={12}
        >
          <Card
          >
            <CardHeader 
              title= {t('faq.technical')}
              titleTypographyProps={{variant:'h2' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar className={classes.avatar}
                  >
                    <BuildIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            
            <ExpansionPanel expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11bh-content"
                id="panel11h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostart0')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getHowToStart()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel19bh-content"
                id="panel19h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostartpoloniex')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getHowToStartPolo()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostart1')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getBitfinex()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
            <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostart1-b')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getPoloniex()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/* <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostart2')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getNotificationLine()}
              </ExpansionPanelDetails>
            </ExpansionPanel> */}

            <ExpansionPanel expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel13bh-content"
                id="panel13h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtostart3')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getHowToSetupBot()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            

            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtocompound')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <HowCompound />
              </ExpansionPanelDetails>
            </ExpansionPanel>

            

            {/* <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtotelegramnotify')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getNotificationTelegram()}
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
            
            <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9h-header"
              >
                <Typography className={classes.heading}>{t('faq.whynolending')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              {getWhyNoLending()}
                {/* <Alert severity={"info"}>
                  <Typography>
                  There could be several reason such as:
                  <br/>
                  <br/>1. The Last Offer Rate is lower than your setting for Minimum rate.
                  <br/>2. Your assets to lend are lower than the xchange's minimum amount (Bitfinex 50 USD / Poloniex 0.01 BTC).
                  </Typography>
                </Alert> */}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
            </CardContent>
          </Card>
         
        </Grid>
      </Grid>
    </div>

    
  );
};

LendingFAQTechnical.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LendingFAQTechnical;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';



const WhatCost = props => {
  

  return (

    <div>
     
        <Alert severity={"info"}>
        <Typography>
                      
                      We offer 3 subscription plan as follow. 
                      <br/>
                      <ui>
                        <li>Basic (USD 5 / month). It perfect for beginner with limit to USD 10,000 </li>
                        <li>Premium (USD 35 / month). It is suitable for larger portfolio with limit up to USD 300,000 </li>
                        <li>Platinum (USD 95 / month). Institutional and hedge fund with unlimited bots and limit</li>
                      </ui> <br/>
                      
                      
                    </Typography>
          </Alert>
     
    </div>

    
  );
};

WhatCost.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatCost;

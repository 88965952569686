import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import moment from 'moment';
import format from 'format-number';
// import copy from "copy-to-clipboard";
import { useTranslation } from 'react-i18next'
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import {
  Card,
  CardHeader,
  // Slide,
  // Snackbar,
  Avatar,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '50.00%',
    flexShrink: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50.00%',
      //flexShrink: 10,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 46,
    width: 46
  },
  avatarbasic: {
    backgroundColor: theme.palette.secondary.main,
    height: 46,
    width: 46
  },
  avatarpremium: {
    backgroundColor: theme.palette.primary.main,
    height: 46,
    width: 46
  },
  avatarfree: {
    backgroundColor: theme.palette.error.main,
    height: 46,
    width: 46
  },
  avatarplatinum: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
}));

// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }

const PricingFAQ = props => {
  const { className, plans, userplan, ...rest } = props;
  
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const { t } = useTranslation()
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (myuser && myuser.emailVerified) {
      userId = myuser.uid
    }
  });

  const classes = useStyles();
  // const [transition, setTransition] = React.useState(undefined);
  // const [open, setOpen] = React.useState(false);
  
  const selectedPlan = plans.find(x=>x.plan_id === userplan.plan_id)

  
  // const handleCopy = () => (event) => {
    
  //   setTransition(() => TransitionUp);
  //   setOpen(true);
  //   copy(userId);
  //   //alert("Copied!")
  // };

  


  const getPlanTitle = () => {

    let desc = ""
    if(selectedPlan !== undefined){
      desc = selectedPlan.plan_name + " (" + format({prefix: selectedPlan.plan_ccy + " ", suffix: ''})(selectedPlan.plan_price)  + ")"
    }
    if(userplan.payment_status !== undefined && userplan.payment_status === 0 && userplan.plan_id !== "F-001"){
      desc = desc + " & " + t('subs.nopayment') 
    }
    if(userplan.cancel_status !== undefined && userplan.cancel_status === 1 && userplan.plan_id !== "F-001"){
      desc = desc + " & "　+ t('subs.paymentcancel')  
    }

    if(userplan.expiry_date === undefined) return 
    let expiry = moment(new Date(userplan.expiry_date.seconds * 1000))
    let today = new Date()

    let expired = today > expiry 
    if(expired){
      desc = desc + " & " + t('subs.subsexpire')  
    }
    
    return desc
  }

  const getPlanDesc = () => {

    let desc = ""
    let limitamount = ""
    if(selectedPlan !== undefined){
      
      if(selectedPlan.plan_id === "T-001"){
        limitamount = "Unlimited"
      }else{
        limitamount = format({prefix: selectedPlan.plan_ccy + " ", suffix: ''})(selectedPlan.loanlimit) 
      }

      desc = t('subs.lendinglimit') +": " + limitamount
    }
    return desc
  }

  // const getPlanPaymentMethod = () => {

  //   //console.log(selectedPlan)
  //   let desc = ""
  //   let paymentImage
  //   //let img = <img src={paymentImage} width="100%" height="100"/>
  //   if(userplan !== undefined){
  //     let pmethod = userplan.payment_method
  //     if(pmethod === undefined){
  //       pmethod = "N/A"
  //     }else if(pmethod === ""){
  //       pmethod = "N/A"
  //     }else if(pmethod.toLowerCase() === "paypal")
  //     {
  //       paymentImage = '/images/logos/paypal-payment.jpg'
  //     }else{
  //       paymentImage = '/images/logos/accept-monarchpal.png'
  //     }
  //     //desc =  <img src={paymentImage} width="15%" height="50"/>
  //   }
  //   return paymentImage
  // }

  // const getPlanPaymentImage = () => {

  //   //console.log(selectedPlan)
  //   let desc = ""
  //   let paymentImage
  //   //let img = <img src={paymentImage} width="100%" height="100"/>
  //   if(userplan !== undefined){
  //     let pmethod = userplan.payment_method
  //     if(pmethod === undefined){
  //       pmethod = "N/A"
  //     }
  //     if(pmethod.toLowerCase() === "paypal")
  //     {
  //       paymentImage = '/images/logos/paypal-payment.jpg'
  //     }else{
  //       paymentImage = '/images/logos/accept-monarchpal.png'
  //     }
  //     desc =  <img src={paymentImage} width="15%" height="50"/>
  //   }
  //   return desc
  // }

  const getPlanPaymentType = () => {

    let pmethod = "N/A"
    if(userplan !== undefined){
      pmethod = userplan.payment_method
      //console.log(pmethod)
      if(pmethod === undefined){
        pmethod = "N/A"
      }else if(pmethod === ""){
        pmethod = "N/A"
      }else if(pmethod.toLowerCase() === "paypal")
      {
        pmethod = "PayPal"
      }
    }

    console.log(pmethod)
    return pmethod
  }

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const getColor = () => {

    if(userplan.plan_id === "B-001"){
      return classes.avatarbasic
    }else if (userplan.plan_id === "P-001"){
      return classes.avatarpremium
    }else if (userplan.plan_id === "T-001"){
      return classes.avatarplatinum
    }else{
      return classes.avatarfree
    }
  
  }
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/* <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Copied!"
      /> */}
     
        <CardHeader 
          title={getPlanTitle()}
          subheader={getPlanDesc()}
          titleTypographyProps={{variant:'h5' }}
          subheaderTypographyProps={{variant:'body1' }}
          //onClick={handleCopy()}
          avatar={
            <Avatar
                className={getColor()}
              > 
              <CardMembershipIcon />
              </Avatar>  
          }
        />
       <Divider/>
        {/* <CardHeader 
          title={t('subs.userid')}
          subheader={userId}
          titleTypographyProps={{variant:'h5' }}
          subheaderTypographyProps={{variant:'body1' }}
          
          action={
            <Tooltip title={t('copy')} arrow='true'>
            <IconButton 
              onClick={handleCopy()}
              >
              <FileCopyIcon  color="primary" fontSize="small"/>
            </IconButton>
            </Tooltip>
          }
          avatar={
            <Avatar
              className={getColor()}
            > 
              <AccountCircleIcon />
              </Avatar>  
          }
        />
        <Divider/> */}
        <CardHeader 
          title={t('subs.paymentmethod')}
          subheader={getPlanPaymentType}
          titleTypographyProps={{variant:'h5' }}
          subheaderTypographyProps={{variant:'body1' }}
          avatar={
            <Avatar
                className={getColor()}
                //src={getPlanPaymentMethod()}
              > 
              <AccountBalanceIcon/>
              </Avatar>  
          }
        />
        
       <Divider/>

    </Card>
  );
};

PricingFAQ.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default PricingFAQ;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import TelegramIcon from '@material-ui/icons/Telegram';
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import appGlobalConfig from "appGlobalConfig"


import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Fab,
  LinearProgress,
  ButtonGroup
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));


const Line = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  const ref = useRef(null);

  
  //const [user, setUser] = useState(null);
  let { uid: userId } = user || {}
  const [values, setValues] = useState({
    line: "",
    telegram: ""
  });

  const [Lineloading, setLineLoading] = React.useState(false);
  const [Teleloading, setTeleLoading] = React.useState(false);
  const [randomS, setRandomS] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState({
    radiovalue: '',
    telegramEnable: true,
    lineEnable: false
  });

  const handleClickOpen = (msg, status) => {
    ref.current.handleOpen(msg, status);
  };

  

  useEffect(() => {

    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        if(myuser.emailVerified){
          userId = myuser.uid
          const unsubscribe = firebase
            .firestore()
            .collection('user_config')
            .doc(userId).onSnapshot(doc => {
            const newnotify = doc.get("notify")
            const { notify, ...othersConfig } = newnotify || {}
            // console.log(othersConfig.line)
            // console.log(othersConfig.telegram)
            setValues({
              ...values,
              line: othersConfig.line.token,
              telegram: othersConfig.telegram.token
            })
    
            if(othersConfig.telegram.enable){
              setSelectedValue({
                radiovalue: "t",
                telegramEnable: true,
                lineEnable: false
              })
            }else{
              setSelectedValue({
                radiovalue: "l",
                telegramEnable: false,
                lineEnable: true
              })
            }
          })
          return () => { unsubscribe() }
        }
        
        
      } 
    });
      
          
     
         
        
       
  }, [])

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateInput = () => {
    
    
    if(selectedValue.radiovalue === "l" && values.line === ""){
      return t('common.msg.blank') + " LINE Token"
    }
    if(selectedValue.radiovalue === "t" && values.telegram === ""){
      return t('common.msg.blank') + " Telegram Token"
    }
    return ""
  }

  const handleClick = event => {
    
    const msg = validateInput()
    if(msg !== ""){
      handleClickOpen(msg, "error")
      return
    } 

    firebase.firestore().collection("user_config").doc(userId).update({
      "notify.line.token": values.line,
      "notify.telegram.token": values.telegram,
      "notify.line.enable": selectedValue.lineEnable,
      "notify.telegram.enable": selectedValue.telegramEnable
    })
      .then(function () {
        handleClickOpen(t('common.msg.successupdate'), "success")
      })
  }
  
  const handleTelegramURL = e => {

    e.preventDefault();
    const myRand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    setRandomS(myRand)
    setValues({
      ...values,
      telegram: myRand
    })
    const telegramURL = "https://telegram.me/xLending_bot?start=" + myRand
    window.open(telegramURL,'Data','height=550,width=550');
    handleClickOpen(t('common.msg.tele.connect'), "info")
    
  }

  const handleClickTest = async e => {
    e.preventDefault();
    
    const msg = validateInput()
    if(msg !== ""){
      handleClickOpen(msg, "error")
      return
    } 

    setLineLoading(true)
    await fetch(appGlobalConfig.testLineURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: values.line }),
    }).then(response => response.json())
      
      .then(data => {
        setLineLoading(false)
        // console.log(data)
        
        if(data.status === 200){
          handleClickOpen(t('common.msg.line.verify.success'), "success")
        }else{
          handleClickOpen(t('common.msg.line.verify.fail'), "error")
        }
    });
  }
    
    

  const getButtonLineLabel = () => {

    //let labelLoading = <div><i className="fa fa-spinner fa-spin" />{t('common.msg.verifying')}</div>
    
    let labelLoading = <div><LinearProgress color="secondary" /> {t('common.msg.verifying')}</div>
    let labelButton = t('common.test.button') + " LINE  "
    
    if(Lineloading){
      return labelLoading
    }else{
      return labelButton
    }
  }

  const getButtonTelegramLabel = () => {

    //let labelLoading = <div><i className="fa fa-spinner fa-spin" />{t('common.msg.verifying')}</div>
    let labelLoading = <div><LinearProgress color="secondary" /> {t('common.msg.verifying')}</div>
    let labelButton = t('common.test.button') + " Telegram"
    
    if(Teleloading){
      return labelLoading
    }else{
      return labelButton
    }
  }

  const handleClickTestTelegram = e => {
    e.preventDefault();
    
    let urI = appGlobalConfig.FunctionURL
    let methodtocall = "getTelegram"
    let strToSend = randomS

    if(randomS === "" && values.telegram === ""){
      handleClickOpen(t('common.msg.tele.connect'), "error")
      //setPopupmessage(t('common.msg.tele.connect'))
      return
    }else if(randomS === "" && values.telegram !== ""){
      methodtocall = "sendTelegram"
      strToSend = values.telegram
    }

    // console.log(urI + methodtocall)
  
    setTeleLoading(true)
    fetch(urI + methodtocall, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: strToSend }),
    }).then(response => response.json())
      
      .then(data => {
        setTeleLoading(false)
        // console.log(data)
        
        if(data.status === "1000"){
          // console.log(data.chatid)
          
          handleClickOpen(t('common.msg.tele.verify.success'), "success")
    
          setValues({
            ...values,
            telegram: data.chatid
          })
          setSelectedValue({
            radiovalue: "t",
            telegramEnable: true,
            lineEnable: false 
          })
        }

    });

  }  

  const handleRadioChange = event => {
    
    let LEnable = false
    let TEnable = false
    
    // console.log(event.target.value)
    // console.log(event.target.name)
    
    if(event.target.value === "t")
    {
      TEnable = true
      LEnable = false
    }else{
      TEnable = false
      LEnable = true
    }

    setSelectedValue({
      radiovalue: event.target.value,
      lineEnable: LEnable,
      telegramEnable: TEnable
    });

  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
      <form>

      <CardHeader
          title={t('notification.label')}
        />
        <Divider />
        <CardContent>
        <RadioGroup aria-label="radiovalue" name="radiovalue" value={selectedValue.radiovalue} onChange={handleRadioChange} row>
          <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={3}
                xs={8}
              >
                <FormControlLabel
                  value="l"
                  control={<Radio color="primary" />}
                  label="LINE"
                  labelPlacement="right"
                />
              </Grid>
              <Grid
                item
                md={9}
                xs={10}
              >
                  <TextField
                    fullWidth
                    disabled = {!selectedValue.lineEnable}
                    margin="dense"
                    name="line"
                    onChange={handleChange}
                    required
                    value={values == null ? "" : values.line}
                    variant="outlined"
                  />
              </Grid>
            </Grid>
          
        
        <Divider />
        
        <Divider />
        <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={8}
            >
              <FormControlLabel
                value="t"
                control={<Radio color="primary" />}
                label="Telegram"
                labelPlacement="right"
              />
              
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Fab 
                fullWidth
                size="small"
                variant="contained" 
                onClick = {handleTelegramURL}
                disabled = {!selectedValue.telegramEnable}
                >
                <TelegramIcon className={classes.extendedIcon} />
                Connect Telegram
              </Fab>
               
            </Grid>
          </Grid>
        <Divider />
        </RadioGroup>
        </CardContent>
        <CardActions className={classes.actions}>
        <ButtonGroup size="medium" color="primary" aria-label="button group">
            <Button
              fullWidth
              color="primary"
              startIcon={<EditIcon />}
              size="small"
              variant="outlined"
              onClick={handleClick}
            >
              {t('common.update.button')}
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<SettingsEthernetIcon />}
              size="small"
              onClick={handleClickTest}
              disabled = {!selectedValue.lineEnable}
            >
              {getButtonLineLabel()}
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<SettingsEthernetIcon />}
              size="small"
              onClick={handleClickTestTelegram}
              disabled = {!selectedValue.telegramEnable}
            >
              {getButtonTelegramLabel()}
            </Button>
          </ButtonGroup>
        </CardActions>
      </form>

    </Card>
  );
};

Line.propTypes = {
  className: PropTypes.string
};

export default Line;

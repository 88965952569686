import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToSetupBot = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            
            I will explain how to lend your coins in Bitfinex automatically. 
            You can use various crypto currencies, but this time, 
            I will explain how to set up with Bitcoin Cash.
            <br/>
            <br/>
            
            1．	Next, go to the xLending dashboard.
            <br/>
            2．	Click Bot Settings on the xLending dashboard as described。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-1.png"} width="100%"/>
            </div>
            3．	Click "Settings" of Bitfinex as shown below.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-2.png"} width="100%"/>
            </div>
            <br/>
            4. Click [BAB] as shown below. Bitfinix refers to Bitcoin Cash as BAB.
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-3.png"} width="100%"/>
            </div>
            5. Set a daily minimum interest rate as follows: Then, press the [Save] button.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-4.png"} width="100%"/>
            </div>
            <br/>
            6. This completes the settings. After that, the bot will automatically work 24 hours / 365 days. 
            When the borrowed currency comes back, the bot will calculate and set the optimal interest rate and automatically set the next lending. 
            Saves time and effort for users.
             <br/>
            
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToSetupBot.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToSetupBot;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Card,
  CardContent,
} from '@material-ui/core';

import palette from 'theme/palette';
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import xlendingUtils from 'xlendingUtils'


const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 280,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  formControlMid: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(10),
      alignItems: 'center'
    },
  },
}));


const LatestSales = props => {
  const { className, mainData, radiovalue, ...rest } = props;

  //console.log(mainData)
  const classes = useStyles();
  const { t } = useTranslation()
  
  const getTitleLabel = () => {

    if(radiovalue=== "this"){
      return t('accgraph.thismonth')
    }
    if(radiovalue === "prev"){
      return t('accgraph.lastmonth')
    }
    return ""
  }

  const getFinalData = () => {
    
    let prevaccbal = 0
    let colldatas = []
    
    //console.log(mainData)

    mainData.forEach(p => {
      //console.log(p)
      let desc = p.description
      if(desc.includes("Margin Funding")){ //temporary. to be changed to filter by type
        let s = p.symbol
        let d = moment(new Date(p.pay_time.seconds * 1000)).format('L')
        let myD = new Date(d)
        let forD = myD.getFullYear().toString() + myD.getMonth().toString() + myD.getDate().toString()
        let mykey = forD 
        let bal = p.amountBTC

        //console.log(mykey +" ->" +bal)

        let r = colldatas.find(t => t.key === mykey)
        prevaccbal = Number(prevaccbal) + Number(bal)
        if(r !== undefined) //collection exists
        {
          r.balance = Number(r.balance) + Number(bal)
          r.date = d
          r.accbal = prevaccbal
        }else{
          colldatas.push({
            key: mykey,
            symbol: s,
            date: d,
            balance: bal,
            accbal : prevaccbal,
          })
        }
      }
    })

    let myPayLabel = []
    let myPayData = []
    
    //colldatas.sort(compareValues('date', 'desc'));

    //console.log(colldatas)
    colldatas.forEach(d => {
      if(d.key !== ""){
        myPayLabel.push(d.date)
        myPayData.push(d.accbal)
      }
    });

    return {myPayLabel, myPayData}

  }

  const {myPayLabel, myPayData} = getFinalData()

  // var numberWithCommas = function(x) {
  //   return roundTo(Number(x).toPrecision(), 6)
  // };

  const datanew = {
    labels: myPayLabel, //paymentlabel,
    datasets: [
      {
        label: getTitleLabel(),
        backgroundColor: palette.primary.main,
        data: myPayData//paymentdata
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function(tooltipItem, data) { 
          return data.datasets[tooltipItem.datasetIndex].label + ": " + xlendingUtils.roundToFormat(tooltipItem.value, 6);
        }
      }
     },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(value) { return xlendingUtils.roundToFormat(value, 6); },
           }, 
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    }
  };

  const getLoadingContent = () => {

      return <div className={classes.chartContainer}>
            <Line
              data={datanew}
              options={options}
            />
          </div>
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        {getLoadingContent()}
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToSetupBotja = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            Bitfinexの自動取引をする方法の説明をします。いろいろな仮想通貨で出来ますが、今回は、Bitcoin Cashでの設定の仕方をご説明します。
            <br/>
            <br/>
            
            1．	次に、xLendingのダッシュボードにいきます。
            <br/>
            2．	上記のように、 xLendingダッシュボードの［ボットの設定］をクリックします。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-1.png"} width="100%"/>
            </div>
            3．	下記にように、Bitfinexの［設定］をクリックしてください。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-2.png"} width="100%"/>
            </div>
            <br/>
            4. 下記のように、［BAB］をクリックしてください。Bitfinixでは、Bitcoin Cashは、BABと表示されています。
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-3.png"} width="100%"/>
            </div>
            5. 下記のように、日毎の最低金利を設定してください。そして、［保存］ボタンを押してください。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start3-4.png"} width="100%"/>
            </div>
            <br/>
            6. これによって、設定の完了です。あとは、ボットが24時間／365日、自動で活動してくれます。貸した通貨が帰ってきたら、ボットは、最適な金利を計算・設定して、次の貸出設定も自動でしてくれます。ユーザーの手間と時間が省けます。
            <br/>
            
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToSetupBotja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToSetupBotja;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowPayPalcn = props => {
  //const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          1. 单击“Subscribe”以注册您想要的配套
          <br/>
          2. 选择PayPal付款方式
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/payment-1.png"} width="100%" height="250"/>
          </div>
          3. 点击PayPal按钮，然后按照说明进行操作
          <br/>
          4. 它将重定向到PayPal网站进行登录
          <br/>
          
          <Divider/>
          5. 在PayPal完成付款后，请等待一段时间以便等待系统的更新
          <br/>
          6. 如果30到60分钟后，xLending仍显示“尚未收到付款”的话，请别着急。你可以通过support@xlending.cc与我们联系。
          </Typography>
        </Alert>
    </div>

    
  );
};

HowPayPalcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPayPalcn;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    //padding: theme.spacing(3)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowCompound = props => {
  const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div className={classes.root}>
     
        <Alert severity={"info"}>
            <Typography>
            Login to xLending. Go to Compound tab 
            First, you have to sign in to wallet. Either Metamask or Connect Wallet
            <br/>
            If you do not have the wallet, please download it from here
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/compound-1.png"} width="100%" height="400"/>
            </div>
            <br/>
            <Divider/>
            To enable lending in Compound, you have to first enable the contract first. 
            <br/>
            1. Click on "Enable" button and follow the instruction to send the transaction 
            <br/>
            2. Once it is enable, you will see the screen for the token is in "Green"
            <br/>
            3. Then, you can click "Lend" button. You are prompted to enter the quantity you want to lend out. 
            Follow the instruction to approve and send the transaction.
            <div className={classes.image}>
              <img alt="" src={"/images/products/compound-3.png"} width="100%" height="100"/>
            </div>
            <br/>
            4. It takes a while for Ethereum blockchain to mine the transaction. 
            <br/>
            5. Once it is done, you can see the total on lend and total interests received. 

            
            </Typography>
          </Alert>
       
    </div>

    
  );
};

HowCompound.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowCompound;

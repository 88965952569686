import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/StarBorder'
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next'
import appGlobalConfig from "appGlobalConfig"
import { Alert, AlertTitle } from '@material-ui/lab';
import format from 'format-number';
import web3 from 'web3';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import moment from 'moment';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  TextField,
  Avatar
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main[700],
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.white,
  },
  cardHeaderHighlight: {
    //backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  },
  div: {
    padding: theme.spacing(1, 2),
    width: "100%",
    //display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    //padding: theme.spacing(1, 2),
    //width: "100%",
    //display: 'flex',
    color: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarbasic: {
    backgroundColor: theme.palette.secondary.main,
    height: 56,
    width: 56
  },
  avatarpremium: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  avatarfree: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  avatarplatinum: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
}));




const PricingTable = props => {
  const { className, userplan, plans, ...rest } = props;
  
  const classes = useStyles();
  
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [values, setValues] = useState([])
  // const [payment, setPayment] = useState([])
  const [isLoading, setIsLoading] = React.useState(true);
  // const [onTrial, setOnTrial] = React.useState(true);
  // const [isPaymentExpired, setIsPaymentExpired] = React.useState(true);
  const [planID, setPlanID] = React.useState("");
  const [hasBeenCancelBefore, setHasBeenCancelBefore] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState('paypal')
  const [paymentImage, setPaymentImage] = React.useState('/images/logos/paypal-payment.jpg')
  const [ethAddress, setethAddress] = React.useState('')
  const [paymentId, setPaymentId] = React.useState('B-001')
  const { t } = useTranslation()
  const ref = useRef(null);

  // const handleClickOpen = (msg, status) => {
  //   ref.current.handleOpen(msg, status);
  // };

  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (myuser && myuser.emailVerified) {
      userId = myuser.uid
      //console.log(userplan.eth_address)
      
    }
  });

  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser)
      {
        
        userId = myuser.uid
        setIsLoading(true)
        const unsubscribe = firebase
        .firestore()
        .collection('paypal_subscriptions')
        .where('user', '==', userId)
        //.where('status', '==', "active")
        .orderBy('updated_at', "desc")
        .limit(1)
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          // setValues(newValues)
          const user = newValues.find(x=>x.status === "active")
          if(user !== undefined)
          {
            setPlanID(user.plan_id)
          }
          //console.log(newValues)
          const usercancel = newValues.find(x=>x.status === "cancel")
          if(usercancel !== undefined)
          {
            setHasBeenCancelBefore(true)
          }
        })
        setIsLoading(false)
        return () => { unsubscribe() }
      }
    })
  }, [])

  
  const handleClosePayment = () => {
    setOpenPayment(false);
  }

  const handleSaveTrialPayment = event => {
    
    let today = new Date()
    let date_expired = new Date(today.setMonth(today.getMonth() + 1))

    firebase.firestore().collection("subscriptions").doc(userId).update({
      "plan_id": paymentId,
      "payment_status": 1,
      "cancel_status": 0,
      "expiry_date" : date_expired
    })

    let host = appGlobalConfig.freePlanServer
    switch (paymentId) {
      case "B-001":
      host = appGlobalConfig.basicPlanServer
      break
      case "P-001":
      host = appGlobalConfig.premiumPlanServer
      break
      case "T-001":
      host = appGlobalConfig.platinumPlanServer
      break
    }
    firebase.firestore().collection("bot_config").doc(userId).update({
      "host": host
    })

    setOpenPayment(false);
  }

const handleClickOpenPayment = (tagID) => event => {

  

  setOpenPayment(true)
  setPaymentId(tagID)
}

const getPaymentButton = (tagID) => {

  if(tagID === "F-001" && userplan.plan_id === "F-001"){
    return <Button
            fullWidth
            color="primary"
            size="small"
            variant="contained"
            //onClick={handleClickOpenPayment(tagID)}
            >
            {t('subs.underthisplan') }
          </Button>
    return
  }  

  if(userplan.expiry_date === undefined) return 
  let expiry = moment(new Date(userplan.expiry_date.seconds * 1000))//.format('L')
  let today = new Date()

  let expired = today > expiry 
  // console.log(today)
  // console.log(expiry)
  // console.log(expired)

  if(tagID !== "F-001" &&  (userplan.payment_status === undefined || userplan.payment_status === 0 || expired)){
      return <Button
            fullWidth
            color="primary"
            size="small"
            variant="text"
            onClick={handleClickOpenPayment(tagID)}
            >
              <CardMembershipIcon /> Subscribe <ArrowRightIcon />
          </Button>
  }

  if(userplan.plan_id === tagID){
    return <Button
          fullWidth
          color="primary"
          size="small"
          //variant="text"
          variant="contained"
          >
           <CardMembershipIcon />
            {" "}You are under this plan
        </Button>
  }else{
    return <Button
            fullWidth
            color="primary"
            size="small"
            variant="text"
            //onClick={handleClickOpenPayment(tagID)}
            >
              <FreeBreakfastIcon />
            {/* {"It's always good to be free"}  */}
          </Button>
  }

}

const getPayPalLink = (tagID) => {

  //let item_id
  let btnID
  if(tagID === "B-001"){
     btnID = appGlobalConfig.paypalBasicPlanId
  }else if(tagID === "P-001"){
    btnID = appGlobalConfig.paypalPremiumPlanId
  }

  let formsub = <div align="center"><form action={appGlobalConfig.paypalGatewayURL} method='post' target='_blank'>
  <input type='hidden' name='cmd' value='_s-xclick'/>
  <input type='hidden' name='hosted_button_id' value={btnID}/>
  <input type='hidden' name='custom' value={userId}/>
  <input type='hidden' name='item_id' value={paymentId}/>
  {/* <input align="center" width="30%" height="50" type='image' src={appGlobalConfig.paypalGatewayImageURL} border='0' name='submit' alt='PayPal - The safer, easier way to pay online!'/> */}
  </form></div>

  return formsub

}

const handleRadioChange = event => {
  setPaymentType(event.target.value)
  setethAddress(userplan.eth_address)
  if(event.target.value === "paypal"){
    setPaymentImage('/images/logos/paypal-payment.jpg')
  }else{
    setPaymentImage('/images/logos/accept-monarchpal.png')
  }
  
}

const handleChange = event => {
  // console.log(event.target.value)
  setethAddress(event.target.value)
};

const getMonarchLink = () => {

  let monarchID = appGlobalConfig.monarchBasicPlanId 
  if(ethAddress === "" && userplan.eth_address === ""){
    return <img alt="" src={paymentImage} width="100%" height="100"/>
  }else{

    if(paymentId === "P-001"){
      monarchID = appGlobalConfig.monarchPremiumPlanId
    }
    let uri = appGlobalConfig.monarchGatewayURL + monarchID
    return <a href={uri} target="_blank" >
      <img alt="" src={paymentImage} width="100%" height="100"/>
    </a> 
  }
}

const onClickNext = event => {

  if(web3.utils.isAddress(ethAddress)){
      
    //console.log("valid address")
    firebase.firestore().collection("subscriptions").doc(userId).update({
      "eth_address": ethAddress
    })

    let monarchID = appGlobalConfig.monarchBasicPlanId 
      if(paymentId === "P-001"){
        monarchID = appGlobalConfig.monarchPremiumPlanId
      }
    let uri = appGlobalConfig.monarchGatewayURL + monarchID
    window.open(uri, '_blank')
  }else{
    alert(t('subs.ethaddressnotvalid') , "error");
  }
}
const getNextButton = () => {

  if(paymentType === "paypal"){
    return
  }else
  {
      return <Button 
                onClick={onClickNext}
                color="primary"
                startIcon={<ArrowRightIcon />}>
                { t('common.next.button') }
              </Button>
  }
    
}


const getPaymentInput = () => {
  
  if(paymentType === "paypal"){
    return <CardContent>
                {getPayPalLink(paymentId)}
                <Alert severity={"info"}>
                  <AlertTitle>
                    <Typography variant="h3" className={classes.content}>{t('subs.betalaunch')}</Typography></AlertTitle>
                  {/* <Typography variant="body1">{t('subs.paypalstep1') }</Typography>
                  <br/><Typography variant="body1">{t('subs.paypalstep2') }</Typography> */}
                </Alert>
          </CardContent>
  }else{
    return <CardContent>
            {getMonarchLink()}
            <TextField
                label={t('ETH Address')}
                fullWidth
                style={{ marginTop: '1rem' }}
                margin="dense"
                name="ethaddress"
                value={ethAddress}
                variant="outlined"
                onChange={handleChange}
              />
               <Alert severity={"info"}>
                  <AlertTitle><Typography variant="h5">{"Description"}</Typography></AlertTitle>
                  <br/><Typography variant="body1">{t('subs.monarchstep1') }</Typography>
                  <br/><Typography variant="body1">{t('subs.monarchstep2') }</Typography>
                  <br/><Typography variant="body1">{t('subs.monarchstep3') }</Typography>
                  <br/><Typography variant="body1">{t('subs.monarchstep4') }</Typography>
                  <br/><Typography variant="body1">{t('subs.monarchstep5') }</Typography>
                </Alert>
              
        </CardContent>
  }
}

const getPlanDesc = (plan) =>{
  const selectedPlan = plans.find(x=>x.plan_id === plan)
  if(selectedPlan === undefined) return 
  return selectedPlan.plan_name //+ " (" + format({prefix: selectedPlan.plan_ccy + ' ', suffix: ''})(selectedPlan.plan_price) + ")"
}

const getPlanSubHeader = (plan) =>{
  const selectedPlan = plans.find(x=>x.plan_id === plan)
  if(selectedPlan === undefined) return 
  return <h2>{format({prefix: selectedPlan.plan_ccy + ' ', suffix: ''})(selectedPlan.plan_price) + " / " + t('simulation.month')}</h2>
}

const getPlanloanlimit = (plan) =>{
  const selectedPlan = plans.find(x=>x.plan_id === plan)
  if(selectedPlan === undefined) return 

  if(plan === "T-001"){
    return <Typography component="li" variant="h2" align="center" > &#8734;</Typography> 
  }else{
    return format({prefix: selectedPlan.plan_ccy + ' ', suffix: ''})(selectedPlan.loanlimit)
  }
  // <Typography component="li" variant="h1" align="center" >&#8734;</Typography> 
  //             </Typography>

}

const getColor = (plan) => {

  if(plan === "B-001"){
    return classes.avatarbasic
  }else if (plan === "P-001"){
    return classes.avatarpremium
  }else if (plan === "T-001"){
    return classes.avatarplatinum
  }else{
    return classes.avatarfree
  }

}

const getColorFont = (plan) => {

  return {variant:'h4', align: 'center', color:'initial'}

  // if(plan === "B-001"){
  //   return {variant:'h4', align: 'center', color:'initial'}
  // }else if (plan === "P-001"){
  //   return {variant:'h4', align: 'center', color:'primary'}
  // }else{
  //   return {variant:'h4', align: 'center', color:'initial'}
  // }

}

const getPlanDetail = (plan) => {

  if(plan.plan_id === "B-001" || plan.plan_id === "P-001"){
    return  <div>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.uptobot') + ": " }
              <Typography component="li" variant="h2" align="center" >{plan.maxbot}</Typography>
              </Typography>
              {/* <br/><Typography component="li" variant="subtitle1" align="center" >
              {t('subs.emailsupport')}
              </Typography>
              <br/>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.basicsetting')}
              </Typography>
              <br/> */}
              {/* <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.beginnerportfolio')}
              </Typography> */}
            </div>
  }else if (plan.plan_id === "T-001"){
    return  <div>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.uptobot')}
              <Typography component="li" variant="h1" align="center" >&#8734;</Typography> 
              </Typography>
              {/* <br/><Typography component="li" variant="subtitle1" align="center" >
              {t('subs.priorityemail')}
              </Typography>
              <br/>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.advancesetting')}
              </Typography>
              <br/> */}
              {/* <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.largeportfolio')}
              </Typography> */}
            </div>
  }else{
    return  <div>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.uptobot')}
              <Typography component="li" variant="h2" align="center" >{plan.maxbot}</Typography> 
              </Typography>
              <br/>
              {/* <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.emailsupport')}
              </Typography>
              <br/>
              <Typography component="li" variant="subtitle1" align="center" >
              {t('subs.basicsetting')}
              </Typography>
              <br/> */}
              <Typography component="li" variant="subtitle1" align="center" >
                {t('subs.freeportfolio')}
              </Typography>
              </div>
  }

}

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
      <Dialog
        className={classes.dialog}
        open={openPayment}
        keepMounted
        onClose={handleClosePayment}
        fullWidth={true}
        maxWidth = {'sm'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> 
            <Typography variant="h5" color="primary">
              {t('subs.subscribeto') }{" " + getPlanDesc(paymentId)}
            </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
                <FormControl component="fieldset" >
                  <RadioGroup aria-label="position" name="position" value={paymentType} onChange={handleRadioChange} row>
                    <FormControlLabel
                      className={classes.radio}
                      value="paypal"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body2">{t('PayPal')}</Typography>}
                      labelPlacement="start"
                    />
                    {/* <FormControlLabel
                      className={classes.radio}
                      value="monarchpay"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body2">{t('Monarch Pay')}</Typography>}
                      labelPlacement="start"
                    /> */}
                    
                  </RadioGroup>
                </FormControl>
            
            <Card className={classes.card}>
              <div className={classes.div}>
                {getPaymentInput()}
              </div>
            </Card>
            
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClosePayment} 
            color="primary"
            startIcon={<CancelIcon />}>
            {t('common.close.button') }
          </Button>
          <Button 
            onClick={handleSaveTrialPayment} 
            color="primary"
            startIcon={<SaveIcon />}>
            {t('common.savetrial.button') }
          </Button>
          {getNextButton()}
          
        </DialogActions>
      </Dialog>

      <CardContent className={classes.content}>
        
      <Grid container spacing={5} alignItems="flex-end">

        {plans.map(plan => (
            <Grid item key={plan.plan_id} lg={3} xs={12} sm={6} md={4}>
              <Card >
                <CardHeader
                  avatar={
                        <Avatar className={getColor(plan.plan_id)}>
                          <StarIcon />
                        </Avatar>
                  }
                  title={getPlanDesc(plan.plan_id)}
                  subheader={getPlanSubHeader(plan.plan_id)}
                  titleTypographyProps={getColorFont(plan.plan_id)}
                  subheaderTypographyProps={{variant:'h5', align: 'center', color:'primary'}}
                />
                <Divider/>
                <CardContent>
                  <ul>
                    <Typography component="li" variant="h5" align="center" color="primary">
                      {t('subs.lendinglimit')} 
                      <Typography component="li" variant="h3" align="center" color="primary">
                        {getPlanloanlimit(plan.plan_id)}
                        </Typography>
                    </Typography>
                    <br/>
                    {getPlanDetail(plan)}
                  </ul>
                </CardContent>
                <Divider/>
                <CardActions className={classes.actions}>
                  {getPaymentButton(plan.plan_id)}
                </CardActions>
              </Card>
            </Grid>
          ))} 

        </Grid>

      </CardContent>
      
      
      
    </Card>
  );
};

PricingTable.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default PricingTable;
//export default withRouter(PricingTable);
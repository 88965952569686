import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const HowPoloniex = props => {
  
  

  const classes = useStyles();

  return (

    <div>
      
        
        <Alert severity={"info"}>
            <Typography>

           1．	Please login to Poloniex　https://www.poloniex.com/
            <br/>
            2．	Click on［API Keys］as follow:
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-1.png"} width="100%"/>
            </div>
            3．	Click on［Enable API］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-2.png"} width="100%"/>
            </div>
            <br/>
            4. After clicking, go to the following screen after two-step authentication, email authentication, etc. Then click [Create New Key].
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            <br/>
            5．	[Important] Please set as follows. Check [Enable Trading]. By setting as follows, the API cannot be used outside the system.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            6．It shows as follow:【Important】Please make a copy of this API key and save it in the safer place.
            <br/>
            
            7．	Next, back to xLending Dashboard
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
            8．	Go to xLending ［API Setting］ tab. 
            <br/>
            9．	Fill in your Bitfinex API and Secret Key
            <br/>
            
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-6.png"} width="100%"/>
            </div>
            <br/>
            10．After entering the Poloniex API key and secret code, click on [Test Poloniex].
            <br/>

            11．[Important] Finally, click [Update].
            <br/>
            12．API was set in Poloniex, and consolidation with xLending was completed.

            <br/>

            
            </Typography>
          </Alert>
              
      {/* </Grid> */}
    </div>

    
  );
};

HowPoloniex.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPoloniex;

import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar, Tooltip, LinearProgress, IconButton, CardHeader } from '@material-ui/core';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import xlendingUtils from 'xlendingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));


const getMonth = () => {

  let date = new Date()
  return moment(new Date(date.getFullYear(), date.getMonth())).format('MMMYYYY');  

}

function getPreviousDate(mydate){
  var pDay = new Date(mydate.getFullYear(), mydate.getMonth(), mydate.getDay()-2);
  pDay.setHours(0, 0, 0, 0);
  return pDay
}

function getFirstLastDate(myval){

  let date = new Date();
  //console.log(myval)
  if(myval === "prev"){
    let lastfirstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
    date = new Date(lastfirstDay.getFullYear(), lastfirstDay.getMonth(), 1);
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);

  var firstDayNextMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
  firstDayNextMonth.setHours(0, 0, 0, 0);

  //console.log({firstDay, firstDayNextMonth})
  return {firstDay, firstDayNextMonth}
}

const TotalProfit = props => {
  const { className, userPayments, exRates, ...rest } = props;
  const classes = useStyles();

  

  const { user } = useAuth()
  const { t, } = useTranslation()
  

  let { uid: userId } = user || {}
  const [selectedCurrency, setSelectedCurrency] = useState("BTC")
  const [selectedIndex, setSelectedIndex] = useState(0)
  const currencies = ['BTC', 'JPY', 'USD']
  const ref = useRef(null);

 
  const handleClickHelp = e => {
    e.preventDefault();  
    let desc = t('botsetup.totalprofitdesc') //"The estimated profit is calculated based on accumulation of interest received in \n\n 'Local Symbol Amount' x 'BTC spot rate on the day' for the month x 'latest today JPY or USD rate'"
    ref.current.handleOpen(desc, "info");
  };

  const getTotaltemp = (_profits) => {

    let total = 0;
    _profits.forEach(element => {
  
      if(element.exchange === "bitfinex"){
        let desc = element.description
        if(desc.includes("Payment")){ //temporary. to be changed to filter by type
          total = total + element.amountBTC; 
        }
      }else if(element.exchange === "poloniex"){
        total = total + element.amountBTC; 
      }
    });
  
    return parseFloat(total).toFixed(5)
  }
  
  let totalAmt = getTotaltemp(userPayments)

  const getIcons = () => {
  
    let iconname = "crypto crypto-" + selectedCurrency.toLowerCase() + " classes.icon"
    return <i  className={iconname} ></i>
  }

  const handleChangeCurrency = () => event => {
    let x
    if(selectedIndex >= 2){
      x=0
    }else{
      x = selectedIndex + 1
    }
    setSelectedIndex(x)
    setSelectedCurrency(currencies[x])

  }
  

  const getAmount = () => {

    
    if(selectedCurrency === "BTC") {
      return totalAmt
    }
    
    let pair
    let round
    if(selectedCurrency === "JPY"){
      pair = "BTCJPY"
      round = 0

    }else if (selectedCurrency === "USD"){
      pair = "BTCUSD"
      round = 2
    }

    console.log(exRates)
    let exR = exRates.find(x=>x.symbol === pair)
    let convertedAmt = exR.last * totalAmt
    return xlendingUtils.roundTo(convertedAmt, round)

  }

  const getTotalDisplay = () => {

    return  getAmount() + " "+ selectedCurrency + " (" + getMonth() + ")"
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
     <PopupMessage ref={ref} />
    
     <CardHeader
        title=  {t('totalprofit.label')}
        action={
          <IconButton 
            aria-label="settings"
            onClick={handleClickHelp}
            >
            <MoreVertIcon color="secondary" fontSize="small"/>
          </IconButton>
        }
        subheader={getTotalDisplay()}
        avatar={
          <Tooltip title="Tap to see profit in different currency" arrow='true'>
            <Avatar 
              className={classes.avatar}
              onClick={handleChangeCurrency()}>
              <Typography variant="subtitle">
                {getIcons()}
              </Typography> 
            </Avatar>
            </Tooltip>
        }
        titleTypographyProps={{variant:'h4'}}
        subheaderTypographyProps={{variant:'body1' }}
      />
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string
};

export default TotalProfit;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';




const WhatMinThresholdja = props => {
  

  return (

    <div>
     
       <Alert severity={"info"}>
                <Typography>

                はい、Bitfinexは最低50ドル相当のトークンが必要です。 その上で、価格の変動に備えて、バッファーを取ることをお勧めしています。具体的に言うと、Bitfinexにおいては、ウォレットに55ドル以上あることをお勧めいたします。
                <br/>
                Poloniexの場合、最小基準値は次のとおりです。
                <br/>
                <br/>
                Bitcoin: 0.01 BTC
                <br/>Ethereum: 0.1 ETH
                <br/>Dash: 0,1 DASH
                <br/>Clams: 5 CLAM
                <br/>Factom: 5 FCT
                <br/>Stellar: 100 STR
                <br/>Dogecoin: 100 DOGE
                <br/>Bitcoin Cash: 0.1 BAB
                <br/>Bitcoin SV: 0.1 BSV


                  </Typography>
                </Alert>
     
    </div>

    
  );
};

WhatMinThresholdja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatMinThresholdja;

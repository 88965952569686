import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';

import {
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide
} from '@material-ui/core';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  image: {
    width: "80%",
    height: "50%",
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <SettingsIcon />,
    5: <SettingsIcon />,
    6: <SettingsIcon />,
    7: <SettingsIcon />,
    8: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Step-1', 
    'Step-2', 
    'Step-3',
    'Step-4',
    'Step-5',
    'Step-6',
    'Step-7',
    'Step-8',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Download Monarch Pay Wallet in your mobile';
    case 1:
      return 'Create the wallet or import existing. Transfer ETH into the wallet. Click on Pay';
    case 2:
      return 'Click Subcsribe button. Input the invoice ID you see in xLending. Fill in the username/email that you used in xLending。 Click Subscribe once everything is done. You are done for the first payment. Next, it is to ensure your recurring account have the balance for the next payment';
    case 3:
      return 'Click on View Fund. You will see your Monarch Pay Wallet. You have to ensure you have balance available for the next payment';
    case 4:
      return 'To top up, you go back to Pay in wallet. Click View Funds. You will see the balance fund as follow. Select ETH';
    case 5:
      return 'Input the amount you want to transfer to recurring payment wallet';
    case 6:
      return 'Click on Deposit and wait for the confirmation';
    case 7:
      return 'You are done';
  
      default:
    return 'Unknown step';
  }
}

function getStepImage(step) {
  switch (step) {
    case 0:
      return "/images/products/monarchpay-mobile0.jpg";
    case 1:
      return "/images/products/monarchpay-mobile1.jpg";
    case 2:
      return "/images/products/monarchpay-mobile4.jpg";
    case 3:
      return "/images/products/monarchpay-mobile2.jpg";
    case 4:
      return "/images/products/monarchpay-mobile5.jpg";
    case 5:
      return "/images/products/monarchpay-mobile7.jpg";
    case 6:
      return "/images/products/monarchpay-mobile8.jpg";
    case 7:
      return "/images/products/monarchpay-mobile9.jpg";
    
    default:
    return 'Unknown step';
  }
}

const MonarchGuide = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [showImage, setShowImage] = React.useState(false);
  const [imagePath, setImagePath] = React.useState("");
  
  const handleShowImage = (yesno, path, imagemssage) => {
    setShowImage(yesno)
    setImagePath(path)
  }

  const handleOpen = (msg) => {
    
    setMsg(msg)
    setOpen(true);
    
  }
  
  const handleClose = () => {
    setOpen(false);
    setShowImage(false)
    setImagePath("")
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      handleShowImage: handleShowImage
    };
  });

  const getImageTag = () => {

    if(showImage){
      return <DialogContentText id="alert-dialog-slide-description" align="center"><img alt="" className={classes.image} src={imagePath}/></DialogContentText>
    }else{
      return ""
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    
    if(activeStep === steps.length -1){
      setOpen(false)
      handleReset()
      return
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        open={open}
        fullScreen
        fullWidth={true}
        //maxWidth = {'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Guides To Automate xLending"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
            <div className={classes.root}>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              </div>
            </DialogContentText>  
            <DialogContentText id="alert-dialog-slide-description">
              <div align="center">
                <Typography variant="h4" className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              </div>
            </DialogContentText>  
            <DialogContentText id="alert-dialog-slide-description">
              <div align="center">
                <img alt="" className={classes.image} src={getStepImage(activeStep)}/>
              </div>
             
          </DialogContentText>
            
        </DialogContent>
        <DialogActions>
        
                 <div>
                    {/* <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography> */}
                    <div>
                      <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        //disabled={activeStep === steps.length - 1}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </div>
        </DialogActions>
      </Dialog>
      
      

    </Card>
  );
});

MonarchGuide.propTypes = {
  className: PropTypes.string
};

export default MonarchGuide;

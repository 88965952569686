import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { 
  HowPayPal, 
  HowPayPalja, 
  HowPayPalcn, 
} from '../HowPayPal';
import { 
  WhatCost,
  WhatCostja,
  WhatCostcn
} from '../WhatCost';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardContent,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  CardHeader,
  Avatar,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    }
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
}));



const LendingFAQPayment = props => {
  const { className } = props;
  const { t, i18n } = useTranslation()

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const getHowMonarchPay = () => {

  //   if(i18n.language === "cn"){
  //     return <HowMonarchPay_cn />
  //   }else if(i18n.language === "ja"){
  //     return <HowMonarchPay_ja />
  //   }else{
  //     return <HowMonarchPay />
  //   }
  // }

  const getHowPayPal = () => {

    if(i18n.language === "cn"){
      return <HowPayPalcn />
    }else if(i18n.language === "ja"){
      return <HowPayPalja />
    }else{
      return <HowPayPal />
    }
  }

  const getWhatCost = () => {

    if(i18n.language === "cn"){
      return <WhatCostcn />
    }else if(i18n.language === "ja"){
      return <WhatCostja />
    }else{
      return <WhatCost />
    }
  }

  return (

    <div>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={4}
        >
          <div className={classes.quote}>
            <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('faq.payment')}
              </Typography>
          </div>
        </Grid>
        <Grid
          className={classes.grid}
          item
          lg={8}
          xs={12}
        >
          <Card
          >
            <CardHeader 
              title={t('faq.payment')}
              titleTypographyProps={{variant:'h2' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                    //src='/images/avatars/xLending.png'
                  >
                    <AccountBalanceIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            
            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>{t('faq.cost')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getWhatCost()}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            
            {/* <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtomonarchpay')}</Typography>
             </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {getHowMonarchPay()}
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
            
            <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9h-content"
                id="panel9h-header"
              >
                <Typography className={classes.heading}>{t('faq.howtopaypal')}</Typography>
             </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              {getHowPayPal()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
            </CardContent>
          </Card>
         
        </Grid>
      </Grid>
    </div>

    
  );
};

LendingFAQPayment.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LendingFAQPayment;

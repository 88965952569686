import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Alert } from '@material-ui/lab';
import Popover from 'react-bootstrap/Popover'
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useAuth } from "use-auth.js";


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));


const LatestTrades = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date())

  

  let { uid: userId } = user || {}
  const [values, setValues] = useState([])

  
  const handleDateChange = date => event => {
    
    //console.log(event.target.value)
    let mydate = new Date(event.target.value);
    mydate.setHours(0, 0, 0, 0);
    
    const nextDay = getNextDay(mydate)

    setSelectedDate(mydate)
    //setValues([])
    getData(mydate, nextDay)
   
  };

  const getNextDay = (myval) => {

    myval.setHours(0, 0, 0, 0);
    var NextDay = new Date(myval.getFullYear(), myval.getMonth(), myval.getDate() + 1);
    NextDay.setHours(0, 0, 0, 0);
  
    return NextDay
  }


  useEffect(() => {

      let mydate = new Date();
      mydate.setHours(0, 0, 0, 0);
      const nextDay = getNextDay(mydate)
      //let mydate = getFirstLastDate(event.target.value)

      setSelectedDate(mydate)
      getData(mydate, nextDay)
  }, [])

  const getData = (mydate, nextDay) => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
          const unsubscribe = firebase
            .firestore()
            .collection('user_trades')
            .where('user', '==', userId)
            .where('trade_time', '>', mydate)
            .where('trade_time', '<', nextDay)
            .orderBy("trade_time", "desc")
            //.limit(10)
            .onSnapshot((snapshot) => {
              const newValues = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
              }))
              //console.log(newValues)
              setValues(newValues);
            })
            return () => { unsubscribe() }
          }
    })
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = name => event => {
    if (values.length > getTotalShow()) {
      setOpen(true);
    }
  };

  
  const getTotalShow = () => {

    let totalShow = 4
    if (values.length <= totalShow) {
      return values.length
    }
    return totalShow
  }

  const popover = (trade) => (
    <Popover id="popover-basic" className={classes.overlay}>
        <Popover.Content>
          <Alert severity="info">
          <div>
          <div>{trade.exchange.toUpperCase()}</div>
            <div>{t('trades.period')}: {trade.period} {t('trades.days')}</div>
            <div>{t('trades.tradedate')}: {moment(new Date(trade.trade_time.seconds * 1000)).format('lll')}</div>
          </div>
          </Alert>
        </Popover.Content>
      </Popover>
  );

  return (

    <div>
      <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth = {'md'}
        keepMounted
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
         
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cell}>{t('botsetup.exchange')}</TableCell>
                      <TableCell>{t('botsetup.symbol')}</TableCell>
                      <TableCell className={classes.cell}>{t('trades.period')}</TableCell>
                      <TableCell>{t('trades.rate')}</TableCell>
                      <TableCell>{t('trades.amount')}</TableCell>
                      <TableCell className={classes.cell}>{t('trades.tradedate')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.map((trade, i) => (
                      <OverlayTrigger key={trade.id + "p"} trigger="hover" placement="right" overlay={popover(trade)}>
                         <TableRow
                          hover
                          key={trade.id + "p"}
                          >
                          <TableCell className={classes.cell}>
                            <Avatar
                              className={classes.avatar}
                              src={xlendingUtils.getAvatarSource(trade.exchange)}
                            />
                          </TableCell>
                          <TableCell>{trade.symbol}</TableCell>
                          <TableCell className={classes.cell}>{trade.period} {t('trades.days')}</TableCell>
                          <TableCell>{xlendingUtils.toPercent(trade.rate, 4)}</TableCell>
                          <TableCell>{xlendingUtils.roundTo(trade.amount, 2)}</TableCell>
                          <TableCell className={classes.cell}>
                            {moment(new Date(trade.trade_time.seconds * 1000)).format('lll')}
                          </TableCell>
                        </TableRow>
                      </OverlayTrigger>
                    ))}
                  </TableBody>
                </Table>
        
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary"
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
   
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
       <Tooltip title={t("botsetup.latesttradedesc")} arrow={true}>
        <CardHeader
          action={
            <TextField
              type="date"
              defaultShow="true"
              //defaultValue={selectedDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'date',
                id: 'date',
              }}
              onChange={handleDateChange('date')}
            />
          }
          title={t('trades.top10latesttrade') + " " + moment(selectedDate).format('ll')}
        />      
      </Tooltip>
      <CardContent className={classes.content}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>{t('botsetup.exchange')}</TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell className={classes.cell}>{t('trades.period')}</TableCell>
                  <TableCell>{t('trades.rate')}</TableCell>
                  <TableCell>{t('trades.amount')}</TableCell>
                  <TableCell className={classes.cell}>{t('trades.tradedate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {values.slice(0, getTotalShow()).map((trade) => (
                  <OverlayTrigger 
                  key={trade.id}
                  className={classes.cell}
                  trigger="hover"
                  placement="right" 
                  overlay={popover(trade)}>
                  
                    <TableRow
                        key={trade.id}
                        hover
                        //onClick={handleRowClick()}
                      >
                      <TableCell className={classes.cell}>
                        <Avatar
                          className={classes.avatar}
                          src={xlendingUtils.getAvatarSource(trade.exchange)}
                        />
                      </TableCell>
                      <TableCell>
                        {/* <img src={getSymbolSource(trade.symbol)} /> */}
                        {trade.symbol}</TableCell>
                      <TableCell className={classes.cell}>{trade.period} {t('trades.days')}</TableCell>
                      <TableCell>{xlendingUtils.toPercent(trade.rate, 4)}</TableCell>
                      <TableCell>{xlendingUtils.roundTo(trade.amount, 2)}</TableCell>
                      <TableCell className={classes.cell}>
                        {moment(new Date(trade.trade_time.seconds * 1000)).format('lll')}  
                      </TableCell>                      
                    </TableRow>
                  </OverlayTrigger>
                ))}
              </TableBody>
            </Table>
      </CardContent>
      <CardActions className={classes.actions}>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={handleClickOpen()}
      >
      View all <ArrowRightIcon />
      </Button>
      </CardActions>
    </Card>
    </div>
  );
};

LatestTrades.propTypes = {
  className: PropTypes.string
};

export default LatestTrades;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowPayPal = props => {
  const { className, } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          Click Subscribe on the plan you wish to enroll
          <br/>
          Select PayPal payment method
          <br/>
          <div align="center">
            <img className={classes.image} src={"/images/products/payment-1.png"} width="100%" height="250"/>
          </div>
          Follow the instruction by clicking the PayPal button 
          <br/>
          
          It will redirect you to PayPal website for login
          <br/>
          
          <Divider/>
          Once the payment is done in PayPal, please wait for a while for the system get updated
          <br/>
          If you still see "Payment Not Paid" in the tab after 30 to 60 mins. Please contact our support at support@xlending.cc 
          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowPayPal.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPayPal;

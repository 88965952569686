import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowBitfinex = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
        
        <Alert severity={"info"}>
            <Typography>
            1．	登录Bitfinex主页 https://www.bitfinex.com/
            <br/>
            2．	点击下面的［API Keys］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-1.png"} width="100%"/>
            </div>
            3．	点击下面的[Create New Key]
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-2.png"} width="100%"/>
            </div>
            <br/>
            4. [重要]请进行以下设置。将Margin Funding Read/Margin Funding Write/Wallets Read。通过如下设置，该API不能在系统外部使用。

            <br/>
            5．	在上方的[Label Your API Key]中输入xLending。
            <br/>
            6．	按上面的[Generate API Key]
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-3.png"} width="100%"/>
            </div>
            <br/>
            6．[重要]请务必保留此API Key和API secret的记录。您不能在Bitfinex主页上看到它。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-4.png"} width="100%"/>
            </div>
            <br/>
            7．	次に、xLendingのダッシュボードに戻ります。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
            8．	接下来，返回到xLending系统的API设定
            <br/>
            9．	输入您的Bitfinex API key和secret，如下所示。
            <br/>
            ＊ 您可以使用QR码阅读器。特别是对于那些使用智能手机操作的人来说很方便。
            扫描QR码，API key和secret将自动输入
            
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-6.png"} width="100%"/>
            </div>
            <br/>
            10．输入Bitfinex APIAPI key和secret后，单击[测试BITFINEX]。
            <br/>

            11．[重要]最后，单击[更新]。
            <br/>
            

            
            </Typography>
          </Alert>
        
    </div>

    
  );
};

HowBitfinex.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowBitfinex;

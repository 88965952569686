import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import {
  ExpectedProfit
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2,0,0,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,0)
    }
  },
  div: {
    display: 'flex',
    //textAlign: 'center',
    //justifyContent: 'center',
    padding: theme.spacing(0,0,0,0),
  },
  div2: {
    display: 'flex',
   // textAlign: 'center',
   // justifyContent: 'center',
    padding: theme.spacing(0,0,0,0),
  }
}));

const Simulations = props => {
  const { exRates, subsPlans, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
 
  return (
    <div className={classes.root}>
     
          <ExpectedProfit 
            subsPlans={subsPlans}
            exRates={exRates}
          />
          <div className={classes.div}>
            <Typography variant="body1" color="textPrimary" >
                {"*** "}{t('simulation.disclaimer') }
              </Typography>
            </div>
            <div className={classes.div2}>
              <Typography variant="body1" color="textPrimary">
              {"*** "}{t('common.msg.simulationdisclaimer') }
              </Typography>
            </div>
    </div>
  );

};

export default Simulations;

import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import SettingsIcon from '@material-ui/icons/Settings';
import { useAuth } from "use-auth.js";
import Setting from '../Setting/Setting'

import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions,
  Button,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    height: "100%"
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Bitfinex = props => {
  const { className, userplan, subsPlan, symbols,  ...rest } = props;
  const classes = useStyles();
  //const { className, ...rest } = props;
  const { t } = useTranslation()
  const ref = useRef(null);

  const { user } = useAuth()
  const { uid: userId } = user || {}

 
  const handleClick = event => {
    ref.current.handleOpen("bitfinex");
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Setting 
        ref={ref} 
        subsPlan={subsPlan}
        userplan={userplan}
        symbols={symbols}
        />

      <CardActionArea>
        <CardMedia
          component="img"
          alt="Bitfinex Exchange"
          height="140"
          image="/images/logos/bitfinex_header.jpg"
          title="Bitfinex Exchange"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Description
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {t("botsetup.bitfinexdesc")}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {t("botsetup.setupkeyfirst")}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
            Please setup your API Key in setting page 
          </Typography> */}

        </CardContent>
      </CardActionArea>
      <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClick}
            startIcon={<SettingsIcon/>}
          >
            {t('common.setting.button')}
          </Button>
      </CardActions>
    </Card>
  );
};

export default Bitfinex;

import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import P2PBetAbi from './P2PBetABI.json'
import xlendingUtils from 'xlendingUtils'

import {
  BetGames
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    alignItems: 'center',
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
}));

const P2PGames = props => {
  const { subsPlans, ...rest } = props;
  const classes = useStyles();
  const [userplan, setUserplan] = useState([])
  
  const [bets, setBets] = useState([])
  const [betCount, setBetCount] = useState(0)
  const [p2pBet, setP2pBet] = useState([])
  const [account, setAccount] = useState([])
  let Web3 = require('web3');

  try{
    window.ethereum.autoRefreshOnNetworkChange = true;
  }catch(e){

  }
   //fetch cToken data
   useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    //loadBlockchainData()
    return () => {
      controller.abort();
    }
  }, []);

  //fetch cToken data
  useEffect(() => {
    const controller = new AbortController();
    //loadWeb3()
    loadBlockchainData()
    return () => {
      controller.abort();
    }
  }, []);

  //async const loadWeb3() {
  const loadWeb3 = async () => {

    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  const loadBlockchainData = async () => {
  
    //const contractAddr = "0xb5b6698b520e0895ede20d1e5d458a5b7f181ce8"
    const contractAddr = "0x894e54bfb54770b268B95D6163886E007D9bbfd4"
    
    
    let web3 = window.web3
    const accounts = await web3.eth.getAccounts()
    setAccount(accounts[0])
    console.log(accounts[0])
    
    web3 = new Web3(window.ethereum);
    const p2pBet = new web3.eth.Contract(P2PBetAbi,contractAddr);
    setP2pBet(p2pBet)
    
    const betCount = await p2pBet.methods.betCount().call()
    setBetCount(betCount)
    
    let myBets = []
    for (var i = 1; i <= betCount; i++) {
      const bet = await p2pBet.methods.bets(i).call()
      console.log(bet)
      myBets.push({
        id: bet.id,
        betAmount: bet.betAmount,
        betStatus: bet.betStatus,
        betOutcome: bet.betOutcome,
        betOutcomeNumber: bet.betOutcomeNumber,
        originatorAddress: bet.originatorAddress,
        originatorGuess: bet.originatorGuess,
        originatorStatus: bet.originatorStatus,
        takerAddress: bet.takerAddress,
        takerGuess: bet.takerGuess,
        takerStatus: bet.takerStatus
      })
      myBets.sort(xlendingUtils.compareValues('id', 'desc'));
    }
    setBets(myBets)

  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        
          <BetGames 
            bets={bets}
            p2pBet={p2pBet}
            account={account}
          />
            
        
      </Grid>
    </div>
  );

};

export default P2PGames;

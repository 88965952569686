import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,

} from '@material-ui/core';




const WhatPlatformja = props => {
  
  return (

    <div>
        <Alert severity={"info"}>
                  <Typography>
                      
                  現在、PoloniexとBitfinexの取引所をサポートしています。
                  </Typography> 
                </Alert>
    </div>

    
  );
};

WhatPlatformja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPlatformja;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import QrReader from 'react-qr-reader'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import appGlobalConfig from "appGlobalConfig"

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  LinearProgress,
  ButtonGroup,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    fullWidth: true
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    //backgroundColor: "#9ee",
    display: "flex",
    wrap: "nowrap",
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const Api = props => {
  const { db, className, ...rest } = props;
  const classes = useStyles();

  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [values, setValues] = useState({
    keyBF: '',
    keyBFS: '',
    keyPL: '',
    keyPLS: ''
  });

  const ref = useRef(null);
  //const { uid: userId } = firebase.auth().currentUser || {}
  const { t } = useTranslation()
  const [Bfloading, setBfLoading] = React.useState(false);
  const [PLloading, setPLLoading] = React.useState(false);
  const [showSecret, setShowSecret] = React.useState({
    showBfSecret: false,
    showPlSecret: false
  });

  const [scanResult, setScanResult] = React.useState("No result")
  const [scanOpen, setScanOpen] = React.useState(false)

  

  const handleClickShowBfSecret = () => {
    setShowSecret({ 
      ...values, 
      showBfSecret: !showSecret.showBfSecret 
    });
  };

  const handleClickShowPlSecret = () => {
    setShowSecret({ 
      ...values, 
      showPlSecret: !showSecret.showPlSecret 
    });
  };
  
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickOpen = (msg, status) => {
    ref.current.handleOpen(msg, status);
  };


  useEffect(() => {

    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
              userId = myuser.uid
              const unsubscribe = firebase
              .firestore()
              .collection('user_config')
              .doc(userId).onSnapshot(doc => {
              const newexchanges = doc.get("exchanges")
              const { exchanges, ...othersExchanges } = newexchanges || {}

              if (othersExchanges.bitfinex !== null) {
                setValues({
                  keyBF: othersExchanges.bitfinex.key || "",
                  keyBFS: othersExchanges.bitfinex.secret || "",
                  keyPL: othersExchanges.poloniex.key || "",
                  keyPLS: othersExchanges.poloniex.secret || ""
                });
              }
            })
            return () => { unsubscribe() }
          // }
          } 
        });
    
  }, [])

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateInput = () => {
    if(values.keyBF === ""){
      return t('common.msg.blank.bitfinexapi')
    }
    if(values.keyBFS === ""){
      return t('common.msg.blank.bitfinexsecret')
    }
    if(values.keyPL === ""){
      return t('common.msg.blank.poloniexapi')
    }
    if(values.keyPLS === ""){
      return t('common.msg.blank.poloniexsecret')
    }
    return ""
  }

  const handleClick = event => {

    const msg = validateInput()
    if(msg !==""){
      handleClickOpen(msg, "error")
      return
    } 

    // To update age and favorite color:
    firebase.firestore().collection("user_config").doc(userId).update({
      "exchanges.bitfinex.key": values.keyBF,
      "exchanges.bitfinex.secret": values.keyBFS,
      "exchanges.poloniex.key": values.keyPL,
      "exchanges.poloniex.secret": values.keyPLS,
    })
      .then(function () {
        //console.log("API Key successfully updated!");
        //setPopupmessage(t('common.msg.successupdate'))
        handleClickOpen(t('common.msg.successupdate'), "success")
      });
  };

  
  const verifyBitfinex = e => {

    e.preventDefault();
      
    const msg = validateInput()
    if(msg !== ""){
      handleClickOpen(msg, "error")
      return
    } 

    setBfLoading(true)
    //const response = await fetch('/api/exchangeBitfinexKey', {
    //const response = fetch('https://us-central1-cryptolending-7b810.cloudfunctions.net/exchangeKey', {
    const response = fetch(appGlobalConfig.verifyBitfinexURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: values }),
    }).then(response => response.json())
      
      .then(data => {
      setBfLoading(false)
      //console.log(data)
      
      if(data.status === "1000"){
        handleClickOpen(t('common.msg.apikey-success'), "success") 
      }else{
        handleClickOpen(data.message, "error") 
      }

    });
  }

  const verifyPoloniex = e => {

      e.preventDefault();
      const msg = validateInput()
      if(msg !== ""){
        handleClickOpen(msg, "error")
        return
      } 
      
      setPLLoading(true)
      const response = fetch(appGlobalConfig.verifyPoloniexURL, {
      //const response = fetch('/api/exchangePoloniexKey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: values }),
      }).then(response => response.json())
      
      .then(data => {
        setPLLoading(false)
        //console.log(data)
        
        if(data.status === "1000"){
          handleClickOpen(t('common.msg.apikey-success'), "success") 
        }else{
          handleClickOpen(data.message, "error") 
        }
    });
  }

  const getButtonBitfinexLabel = () => {

    let labelLoading = <div><LinearProgress color="secondary" /> {t('common.msg.verifying')}</div>
    let labelButton = t('common.test.button') + " Bitfinex"
    
    if(Bfloading){
      return labelLoading
    }else{
      return labelButton
    }
  }

  const getButtonPoloLabel = () => {

    //let labelLoading = <div><ColorCircularProgress size={30} thickness={3} /></div>
    
    let labelLoading = <div><LinearProgress color="secondary" /> {t('common.msg.verifying')}</div>
    let labelButton = t('common.test.button') + " Poloniex"
    
    if(PLloading){
      return labelLoading
    }else{
      return labelButton
    }
  }
   
  const handleScan = data => {
    if (data) {
      setScanResult(data)
      let dataarr = data.split('-');
      let key = dataarr[1].split(":")
      let secret = dataarr[2].split(":")

      setValues({
        ...values,
        keyBF: key[1],
        keyBFS: secret[1]
      });

      setScanOpen(false)
    }
  }
  const handleError = err => {
    console.error(err)
  }
  
  const handleScanClose = () => {
    setScanOpen(false)
  }
  const handleScanOpen = () => {
    setScanOpen(true)
  }

  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />

      <Dialog
        open={scanOpen}
        //fullScreen
        fullWidth={true}
        maxWidth = {'sm'}
        keepMounted
        onClose={handleScanClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('apisetting.alignqr')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
          <div>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{width: '100%'}}
              facingMode={'environment'}
              showViewFinder={true}
              />
          </div>
      
             
          </DialogContentText>
            
        </DialogContent>
        
      </Dialog>
      
      <form>
      <CardHeader 
        title= {t('apisetting.bitfinexapikey')}
        action={
          <Tooltip title={t('apisetting.scanqr')} arrow='true'>
          <IconButton 
            onClick={handleScanOpen}
            >
            <CenterFocusWeakIcon  color="primary" fontSize="small"/>
          </IconButton>
          </Tooltip>
        }
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
        avatar={
          <Avatar
              className={classes.avatar}
              src='/images/avatars/bitfinex.png'
            >
                Poloniex
            </Avatar>  
        }
      />

        <Divider />
        <CardContent>
          <TextField
            fullWidth
            style={{ marginTop: '1rem' }}
            //helperText="Please specify API Key"
            label={t('apisetting.apikey')}
            margin="dense"
            name="keyBF"
            onChange={handleChange}
            required
            value={values.keyBF}
            variant="outlined"
          />

          <FormControl className={clsx(classes.margin, classes.formControl)}>
            <InputLabel htmlFor="keyBFS">{t('apisetting.secretkey')}</InputLabel>
            <Input
              id="keyBFS"
              name="keyBFS"
              type={showSecret.showBfSecret ? 'text' : 'password'}
              value={values.keyBFS}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowBfSecret}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showSecret.showBfSecret ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

        </CardContent>
        <CardActions className={classes.actions}>
          <ButtonGroup size="medium" color="primary" aria-label="button group">
          <Button
              fullWidth
              startIcon={<EditIcon />}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={handleClick}
            >
              {t('common.update.button')}
            </Button>
            <Button
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              onClick={verifyBitfinex}
            >
              {getButtonBitfinexLabel()}
            </Button>
          </ButtonGroup>
        </CardActions>
      </form>
      <Divider />
      <form>
      <CardHeader 
        title= {t('apisetting.poloniexapikey')}
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
        avatar={
            <Avatar
              className={classes.avatar}
              src='/images/avatars/poloniex.png'
            >
                Poloniex
            </Avatar>  
        }
      />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            style={{ marginTop: '1rem' }}
            margin="dense"
            label={t('apisetting.apikey')}
            name="keyPL"
            type="text"
            value={values.keyPL}
            onChange={handleChange}
            variant="outlined"
          />
          <FormControl className={clsx(classes.margin, classes.formControl)}>
            <InputLabel htmlFor="keyPLS">{t('apisetting.secretkey')}</InputLabel>
            <Input
              fullWidth
              style={{ marginTop: '1rem' }}
              margin="dense"
              id="keyPLS"
              name="keyPLS"
              type={showSecret.showPlSecret ? 'text' : 'password'}
              value={values.keyPLS}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPlSecret}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showSecret.showPlSecret ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <ButtonGroup size="medium" color="primary" aria-label="button group">
            <Button
              fullWidth
              startIcon={<EditIcon />}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={handleClick}
            >
              {t('common.update.button')}
            </Button>
            <Button
              fullWidth
              color="primary"
              size="medium"
              variant="outlined"
              onClick={verifyPoloniex}
            >
              {getButtonPoloLabel()}
            </Button>
          </ButtonGroup>
        </CardActions>
      </form>
    </Card>
  );
};

Api.propTypes = {
  className: PropTypes.string
};

export default Api;

import React, {  useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
//import { useAuth } from "../../use-auth.js";
import appGlobalConfig from "appGlobalConfig"

import {
  LatestMarkets
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1)
    }
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const [values, setValues] = useState([])
  const [marketLoading, setMarketLoading] = React.useState(true);
  
  //fetch market data
  useEffect(() => {
    const controller = new AbortController();
    fetchMarketData();
    return () => {
      controller.abort();
    }
  }, []);

  //fetch market data from compound
  const fetchMarketData = async () => {
    let cancel = false;
    fetch(appGlobalConfig.compoundURL)
        .then((response) => {
          if (response.ok) {
            return response.json().then(data => {
              //console.log(data.cToken)
              if (cancel) {
                return;
              }
              
              setValues(data.cToken)

              if(values !== undefined){
                setMarketLoading(false)
              }else{
                setMarketLoading(true)
              }
            });
          }
          return Promise.reject(response);
        })
        .catch(/* similar stuff here */);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={8}
          md={8}
          xl={10}
          xs={12}
        >
          <LatestMarkets 
            values={values}
          />
        </Grid>
        {/* <Grid
          item
          lg={8}
          md={14}
          xl={10}
          xs={12}
        >
          <CompoundMarkets 
            values={values}
          />
        </Grid> */}
        
      </Grid>
    </div>
  );

};

export default Dashboard;

import React, { useState, useEffect, useRef   } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, IconButton, Tooltip, Avatar, LinearProgress } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withRouter } from 'react-router-dom';
import PopupMessage from '../../../PopupMessage/PopupMessage'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  card: {
    maxWidth: 345,
  },
  SubsCardHeader: {
    backgroundColor: theme.palette.primary.main,
    //color: 'white'
    color: 'red',
    flexgrow: '1',
        flex: '0 1 auto'
  }
}));

const Subscription = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()

  let { uid: userId } = user || {}
  const [payment, setPayment] = useState([])
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPaymentExpired, setIsPaymentExpired] = React.useState(true);
  const [expireDate, setExpireDate] = React.useState("");
  const [userplan, setUserplan] = useState([])
  const ref = useRef(null);
  
  const handleClick = event => {

    props.history.replace('/subscription')
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser)
      {
        userId = myuser.uid
        setIsLoading(true)
        const unsubscribe = firebase
          .firestore()
          .collection('subscriptions')
          .doc(userId).onSnapshot(doc => {
            setUserplan(doc.data())
          })

        return () => { unsubscribe() }
      }
    })
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser)
      {
        userId = myuser.uid
        setIsLoading(true)
        const unsubscribe = firebase
        .firestore()
        .collection('paypal_payments')
        .where('user', '==', userId)
        .limit(1)
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          const payment = newValues.find(x=>x.user === userId)
          //console.log(payment)
          if(payment !== undefined)
          {
            setPayment(payment)
            //console.log(payment)
            let today = new Date()
            let exDt = moment(new Date(payment.nextPaymentDate.seconds * 1000))//.format('L')
            if(exDt < today){
              //console.log("Expire here")
              setIsPaymentExpired(true)
            }else{
              setIsPaymentExpired(false)
            }
            setExpireDate(exDt)
          }
          
        })
        setIsLoading(false)
        return () => { unsubscribe() }
      }
    })
  }, [])

  // var date_diff_indays = function(date1, date2) {
  //   let dt1 = new Date(date1);
  //   let dt2 = new Date(date2);
  //   return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
  //   }

  const handleClickHelp = e => {
    e.preventDefault();  
    let desc = t('botsetup.plandesc') //"The estimated profit is calculated based on accumulation of interest received in \n\n 'Local Symbol Amount' x 'BTC spot rate on the day' for the month x 'latest today JPY or USD rate'"
    ref.current.handleOpen(desc, "info");
  };
  
  const getCategory = () => {

    //no subs from paypal yet. Define as free member
    if(userplan === undefined) {
      return t('subscription.memberfree')
    }

    if(userplan.plan_id === undefined) {
      return t('subscription.memberfree')
    }

    if(userplan.plan_id === "F-001") {
      return t('subscription.memberfree')
    }
    
    let msg = ""

    if(userplan.plan_id === "B-001"){
      return t('subscription.memberbasic') + msg
    }else if(userplan.plan_id === "P-001"){
      return t('subscription.memberpremium') + msg
    }else{
      return t('subscription.memberfree')   
    }
  }

  const getLabelCondition = () => {

    if(isLoading){
      return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    }else{
      return getCategory()
    }
  }

  const getExpireDate = () => {

    //no subs from paypal yet. Define as free member
    //console.log(values.trial_end)
    if(userplan === undefined) {
      return "" 
    }
    if(userplan.expiry_date === undefined) {
      return "" 
    }

    
    //check if subs trial period first
    let exDt = moment(new Date(userplan.expiry_date.seconds * 1000)).format('L')
    return t('subscription.expire') + ": "+  exDt
    // if(payment.nextPaymentDate !== undefined){
    //   let ntPayDate = moment(new Date(payment.nextPaymentDate.seconds * 1000)).format('L')
    //   return t('subscription.expire') + ": "+  ntPayDate
    // }

  }

  //console.log(values.expiry_date)
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
      <CardHeader 
        title= {getLabelCondition()}
        action={
          <IconButton 
            aria-label="settings"
            onClick={handleClickHelp}
            >
            <MoreVertIcon color="secondary" fontSize="small"/>
          </IconButton>
        }
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
        subheader= {getExpireDate()}
        avatar={
          <Tooltip title={t("sidebar.subsc")} arrow='true'>
          <Avatar 
            onClick={handleClick}
            className={classes.avatar}>
            <PermIdentityIcon className={classes.icon}/>
          </Avatar>
          </Tooltip>
        }
      />
     
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};


export default withRouter(Subscription);
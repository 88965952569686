import React, { useState, useEffect, Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { user } = useAuth()

  // if (!user){
  //   props.history.replace('/sign-in')
  // }

  const { uid: userId } = user || {}
  const [{ sex, name } = {}, setUser] = useState([])

  useEffect(() => {
    if (userId)
      {
        const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(userId).onSnapshot(doc => {
        setUser(doc.data())
      })
      return () => { unsubscribe() }
    }
  }, [])


  let avatarlink = '/images/avatars/male.png'
  if (sex === "f") {
    avatarlink = '/images/avatars/female.png'
  }
  // const user = {
  //   name: 'Shen Zhi',
  //   avatar: '/images/avatars/avatar_11.png',
  //   bio: 'Brain Director'
  // };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={avatarlink}
        to="/dashboard"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {name}
      </Typography>
      {/* <Typography variant="body2">{user.bio}</Typography> */}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

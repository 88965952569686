import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Alert } from '@material-ui/lab';
import Popover from 'react-bootstrap/Popover'
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useAuth } from "use-auth.js";


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 250,
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));



const LatestPayment = props => {
  const { className, userPayments, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()


  let { uid: userId } = user || {}
  const [open, setOpen] = React.useState(false);


  const getPayments = () => {
    let newPays = []
    userPayments.forEach(p => {
      let desc = p.description
      if(desc.includes("Margin Funding")){ //temporary. to be changed to filter by type
        
        newPays.push({
          id: p.id,
          symbol: p.symbol,
          exchange: p.exchange,
          amount: p.amount,
          pay_time: moment(new Date(p.pay_time.seconds * 1000)).format('ll')
        })
      }
    });

    if(newPays.length < 3){
      let remaincount = 3-newPays.length
      for(let x=0; x< remaincount; x++){
        newPays.push({
          id: x,
          symbol: "",
          exchange: "",
          amount: 0,
          pay_time: ""
        })
      } 
    }

    return newPays
  }

  let payments = getPayments()
  
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = name => event => {
    if (payments.length > getTotalShow()) {
      setOpen(true);
    }
  };

  const getTotalShow = () => {

    let totalShow = 3
    if (payments.length <= totalShow) {
      return payments.length
    }
    return totalShow
  }

  const popover = (payment) => (
    <Popover id="popover-basic" className={classes.overlay}>
        <Popover.Content>
          <Alert severity="info">
          <div>
            <div>{t('payments.paydate')}: {payment.pay_time}</div>
          </div>
          </Alert>
        </Popover.Content>
      </Popover>
  );
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth = {'md'}
        keepMounted
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <CardHeader
            title={t('payments.top5latestpayment')}
          />
        <Divider />
            <CardContent className={classes.content}>
            {/* <PerfectScrollbar> */}
          
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('botsetup.exchange')}</TableCell>
                      <TableCell>{t('botsetup.symbol')}</TableCell>
                      <TableCell>{t('trades.amount')}</TableCell>
                      <TableCell className={classes.cell}>{t('payments.paydate')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.map((p, i) => (
                      <OverlayTrigger key={p.id} trigger="hover" placement="right" overlay={popover(p)}>
                      <TableRow
                          hover
                          key={p.id}
                        >
                        <TableCell>
                          <Avatar
                            className={classes.avatar}
                            src={xlendingUtils.getAvatarSource(p.exchange)}
                          >
                            {p.exchange===""? "N":p.exchange}
                          </Avatar>  
                          
                          
                        </TableCell>
                        <TableCell>{p.symbol}</TableCell>
                        <TableCell>{xlendingUtils.roundToFormat(p.amount, 4)}</TableCell>
                        <TableCell className={classes.cell}>{p.pay_time}</TableCell>
                        
                      </TableRow>
                      </OverlayTrigger>
                    ))}
                  </TableBody>
                </Table>
            
            {/* </PerfectScrollbar> */}


            </CardContent>

          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary"
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      
      
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('botsetup.exchange')}</TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell>{t('trades.amount')}</TableCell>
                  <TableCell className={classes.cell}>{t('payments.paydate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {payments.slice(0, getTotalShow()).map((p) => (
                 <OverlayTrigger key={p.id} trigger="hover" placement="right" overlay={popover(p)}>
                  <TableRow
                        hover
                        key={p.id}
                      >
                      <TableCell>
                        <Avatar
                          className={classes.avatar}
                          src={xlendingUtils.getAvatarSource(p.exchange)}
                        >
                          {p.exchange===""? "N":p.exchange}
                        </Avatar>  
                        
                        
                      </TableCell>
                      <TableCell>{p.symbol}</TableCell>
                      <TableCell>{xlendingUtils.roundToFormat(p.amount, 4)}</TableCell>
                      <TableCell className={classes.cell}>{p.pay_time}</TableCell>
                      
                    </TableRow>
                  </OverlayTrigger>
                ))}
              </TableBody>
            </Table>
         
      
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleClickOpen()}
          >
          View all <ArrowRightIcon />
          </Button>
          </CardActions>
    </Card>
  );
};

LatestPayment.propTypes = {
  className: PropTypes.string
};

export default LatestPayment;

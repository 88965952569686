import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToStart0 = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            1．	Please login to Bitfinex https://www.bitfinex.com/
            <br/>
            2．	Click on［Wallet］→［Deposit］as shown below:
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-1.png"} width="100%"/>
            </div>
            3．	Choose the coins you want to transfer. 【Important：Please ensure you have selected the intended coins address】
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-2.png"} width="100%"/>
            </div>
            4．【Example】As an example、we are selecting Bitcoin Cash as part of the instruction
            <br/>
            5．Please ensure you have selected BCH as follow: 
            <br/>
            6．Click on［Funding Wallet］->［Click to generate address］. You will see the address
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            7．	Then, please copy the address. Transfer the coin from your own wallet to this address. 
            <br/>
            8．	Please ensure you are transfering to the address as shown below. 
                Click on［Wallet］→［Wallets］and it shows the following page.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-4.png"} width="100%"/>
            </div>
           
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToStart0.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToStart0;

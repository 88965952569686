import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LatestSales, SymbolProfits } from './components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import PopupMessage from '../../../PopupMessage/PopupMessage'
import xlendingUtils from 'xlendingUtils'

import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  CardActions,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  IconButton,
  Tooltip
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  radio: {
    color: theme.palette.text.primary,
    fontWeight: 200,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px'
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getFirstLastDate(myval){

  let date = new Date();
  //console.log(myval)
  if(myval === "prev"){
    let lastfirstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
    date = new Date(lastfirstDay.getFullYear(), lastfirstDay.getMonth(), 1);
  
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);

  var firstDayNextMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
  firstDayNextMonth.setHours(0, 0, 0, 0);

  if(myval === "last7"){
    var today = new Date();
    firstDay = new Date(today)
    firstDay.setDate(today.getDate() - 7);
    firstDayNextMonth = today
  }

  //console.log({firstDay, firstDayNextMonth})
  return {firstDay, firstDayNextMonth}
}


const ProfitPayments = props => {
  const { className, ...rest } = props;
    
  const classes = useStyles();
  const { t } = useTranslation()
  // const refCircular = useRef(null);
  const ref = useRef(null);
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [SaleChart, setSaleChart] = useState({
    month: 'this'
  });
  const [mainData, setMainData] = React.useState([]);
  const [radiovalue, setRadioValue] = React.useState('this')
  const [cacheColl, setCacheColl] = useState([])
  
  useEffect(() => {
    let mydate = getFirstLastDate(SaleChart.month)
    getData(mydate, radiovalue)
  }, [])


  const getData = (mydate, period) => {
    
    //refCircular.current.handleOpen(true)
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        //setIsLoading(true)
        userId = myuser.uid
        const unsubscribe = firebase
        .firestore()
        .collection('user_payments')
        .where('user', '==', userId)
        .where('pay_time', ">=", mydate.firstDay)
        .where('pay_time', "<", mydate.firstDayNextMonth)
        .orderBy("pay_time", "desc")
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            key: doc.data().pay_time.seconds * 1000,
            ...doc.data()
          }))
          
          newValues.sort(xlendingUtils.compareValues('key', 'asc'));
          //console.log(newValues)
          setMainData(newValues)

          let forCacheData = []
          newValues.forEach(r => {
            forCacheData.push({
              period: period,
              ...r
            })
          });

          cacheColl.forEach(r => {
            forCacheData.push({
              period: period,
              ...r
            })
          });

          setCacheColl(forCacheData)
      })
      return () => { unsubscribe() }
    }
  })
  //refCircular.current.handleOpen(false)
}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleRadioChange = event => {
    
    setRadioValue(event.target.value);
    let mydate = getFirstLastDate(event.target.value)
    let existcol = cacheColl.filter(x=>x.period === event.target.value)
    
    //console.log(existcol)

    if(existcol.length === 0){
      //console.log("read from firestore")
      getData(mydate, event.target.value)
    }else{

      //console.log("read from cache")
      setMainData(existcol)
    }
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
      {/* <Circular ref={refCircular} /> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tooltip title={t("botsetup.graphprofitaccdesc")} arrow={true}>
          <Tab label={t('accgraph.accuprofit')}{...a11yProps(0)} />
        </Tooltip>
        <Tooltip title={t("botsetup.graphprofitdesc")} arrow={true}>
          <Tab label={t('accgraph.symbol.profit')} {...a11yProps(1)} />
        </Tooltip>
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <LatestSales 
            mainData={mainData}
            radiovalue={radiovalue}
            />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SymbolProfits 
            mainData={mainData}
            radiovalue={radiovalue}
            />
        </TabPanel>
        
      </SwipeableViews>
      <CardActions>
      <FormControl component="fieldset" >
                  {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
                  <RadioGroup aria-label="position" name="position" value={radiovalue} onChange={handleRadioChange} row>
                    <FormControlLabel
                      className={classes.radio}
                      value="this"
                      control={<Radio color="primary" />}
                      //label={t('accgraph.thismonth')}
                      label={<Typography variant="body2">{t('accgraph.thismonth')}</Typography>}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className={classes.radio}
                      value="prev"
                      control={<Radio color="primary" />}
                      //label={t('accgraph.lastmonth')}
                      label={<Typography variant="body2">{t('accgraph.lastmonth')}</Typography>}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className={classes.radio}
                      value="last7"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body2">{t('accgraph.last7')}</Typography>}
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
      </CardActions>
      </Card>
  );
};


ProfitPayments.propTypes = {
  className: PropTypes.string
};

export default ProfitPayments;


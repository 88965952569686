import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';



const WhatIsXLendingja = props => {
 
  return (

    <div>
      
        <Alert severity={"info"}>
                  <Typography>
                  xLendingは、Win Innovation株式会社によって2020年に開発・提供されました。Win Innovation株式会社は、ブラックロック・ジャパン株式会社のファンドマネジャー出身者が経営している日本に本拠地をおくフィンテック企業です。ビットコインやその他のデジタル資産運用に関して、独自のテクノロジーを開発しています。
                  さて、xLendingを活用することによって、あなたの仮想通貨を自動で運用し、財産を増やすことができます。BitfinexやPoloniexなどでレンディングをするユーザーは、仮想通貨を貸し出すためには、多数のパラメーターを計算して、貸出し期間や利子率などの基準を手動で作成する必要があります。チャンスが失われないために、ユーザーは継続的な監視が必要になり、手間と時間を浪費します。
                  <br/>
                  <br/>
                <Divider/>

                しかし、xLendingを活用することで、ユーザーの手間と時間を省きながら利益を出すことが出来ます。仮想通貨資産市場を、ボットが常時監視しながら、アルゴリズムに基づいて、最適な金利を自動的に計算します。そして適切なタイミングでボットが自動的に資金の貸し付けをします。これにより、貸出口座の監視に費やす時間が短縮でき、収益を増やすことができます。ユーザーにとって必要な作業は、ほとんどありません。xLendingが、あなたの仮想通貨を自動で運用し、財産を増やしてくれます。
                xLendingのユーザーになるのは、非常に簡単です。ユーザーが行う必要があるのは、アカウントを登録し、BitfinexやPoloniexなどの取引所からのAPI情報をxLendingにリンクすることだけです。 また、xLendingは非常に安全です。ハーカーは、秘密鍵にアクセスが出来たとしても、xLendingから資産の引き出しなどはできないためです。
                  <br/>
                  <br/>
                <Divider/>

                「xLending」の特徴:
                <br/>
                <br/>■リスクが少なく利益を上げることができる、但し、ハイリターンは期待できません。
                <br/>■貸し付けは自動で手間がいりません。さらに福利運用が可能です。
                <br/>■仮想通貨２４種類に対応しています。
                <br/>■自分の予算に合わせてプランを選べます。
                <br/>■手間と労力を考えると格安です。ベーシックプランの場合は、100万円相当の仮想通貨（年利10％）を1か月運用すれば、1万円の利益が出るので、5ドルの手数料は大した額ではありません。
                <br/>■日本語、中国語、英語の対応をしています。xLending のWinInnovation株式会社は、本社が東京の上野にある日系企業なので安心です。「xLending」は日本語対応していて、ご利用ガイドもわかり易くまとめられてます。
                <br/>
                <br/>
                貸し付ける仕組みは、「Poloniex」や「Bitfinex」などから提供する自動売買用APIを用いてセットアップするだけです。あとは、xLendingのテクノロジーである統計アルゴリズムで自動的に貸し付けが実施されます。レンディングをする場合には、xLendingをうまく活用してみてください。


                </Typography>
                </Alert>
       
    </div>

    
  );
};

WhatIsXLendingja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatIsXLendingja;

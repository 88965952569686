import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Circular from 'views/Circular/Circular'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@material-ui/icons/Language';
import { 
  AppBar, 
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Select,
  FormControl
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  whiteColor: {
    color: "white"
  },
  quoteText: {
    color: theme.palette.primary,
    //fontWeight: 300
  },
  quoteTextPrimary: {
    color: theme.palette.white,
    //fontWeight: 300
  },
  div: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  divsmall: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  divcircular: {
    padding: theme.spacing(4),
    alignItems: 'center',
  },
  dialog : {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }
}));


const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [anchorLG, setAnchorLG] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openProgress, setOpenProgress] = React.useState(false);
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  const [values, setValues] = useState([])
  
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const refCircular = useRef(null);

  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (myuser && myuser.emailVerified) {
      userId = myuser.uid
    }
  });

  // useEffect(() => {

  //   if(values.lang !== undefined) return 

  //   const controller = new AbortController();
  //   fetch("http://ip-api.com/json", { 
  //     method: 'GET',
  //   })
  //   .then((response) => response.json())
  //   .then((responseData) => {
  //     //console.log(responseData.countryCode);
  //     if(responseData.countryCode === "JP"){
  //       i18n.changeLanguage("ja")
  //       setValues("ja")
  //     }else if(responseData.countryCode === "CN" || responseData.countryCode === "HK" || responseData.countryCode === "TW" ){
  //       i18n.changeLanguage("cn")
  //       setValues("cn")
  //     }else{
  //       i18n.changeLanguage("en")
  //       setValues("en")
  //     }
  //   })
  //   .catch((error) => {
  //     console.warn(error);
  //   })
  //   return () => {
  //     controller.abort();
  //   }
  // }, []);

  const handleChangeLang = () => event => {
    //setLang(event.target.value)
    setValues({
      ...values,
      lang: event.target.value
    })
    i18n.changeLanguage(event.target.value)
  }

  const handleChangeSimpleLang = lang => event => {
    //setLang(event.target.value)
    setValues({
      ...values,
      lang: lang
    })
    i18n.changeLanguage(lang)
  }


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClickLang = event => {
    setAnchorLG(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorLG(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClickLink = (linkname) => event => {
    props.history.replace("/" + linkname)
    handleClose()
  };
  
  const handleClickLogout = event => {
    
    refCircular.current.handleOpen(true)
    auth.signout();
    handleClose()
    //props.history.replace('/home')
    refCircular.current.handleOpen(false)
    
  }

  
  const handleSignIn = event => {
    event.preventDefault();

    refCircular.current.handleOpen(true)

    //setOpenProgress(true)
    auth.signin("demo@wininnovation.net", "demo123")
      .then(response => {
        if(response === undefined){
          refCircular.current.handleOpen(false)
          return
        }else{
          setOpenProgress(false)
          props.history.replace('/dashboard')
        } 
      });
      
  };


  const getLoginMenu = () => {

    if(userId){

      return <div>
          <Tooltip title={t('sidebar.dashboard')} arrow={true}>
              <IconButton 
                  color="inherit"
                  onClick={handleClickLink("dashboard")}
                >
                 
                  <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                    {t('sidebar.dashboard')}
                  </Typography>
                  
              </IconButton>
          </Tooltip>  
          <Tooltip title={t('sidebar.simulation')} arrow={true}>
            <IconButton 
                color="white"
                onClick={handleClickLink("simulations")}
              >
                
                 <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                      {t('sidebar.simulation')}
                </Typography>
            </IconButton>
          </Tooltip>
          <Tooltip title={t('trades.rate')} arrow={true}>
            <IconButton 
                color="white"
                onClick={handleClickLink("market-rates")}
              >
                
                 <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                      {t('trades.rate')}
                </Typography>
                
            </IconButton>
          </Tooltip>
          <Tooltip title={t('menu.faq')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("faq")}
              >
                
                <Typography
                  className={classes.quoteTextPrimary}
                  variant="h6"
                >
                  {t('menu.faq')}
                </Typography>
                
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={t('P2P Game')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("p2pgames")}
              >
                
                <Typography
                  className={classes.quoteTextPrimary}
                  variant="h6"
                >
                  {t('P2P Game')}
                </Typography>
                
            </IconButton>
          </Tooltip> */}
          <Tooltip title={t('menu.logout')} arrow={true}>
              <IconButton 
                  color="inherit"
                  onClick={handleClickLogout}
                >
                  <Typography
                      className={classes.quoteTextPrimary}
                      variant="h6"
                    >
                    {t('menu.logout')}
                  </Typography>
              </IconButton>
            </Tooltip>
            
          </div>
    }else{

      return <div>
          <Tooltip title={t('menu.signup')} arrow={true}>
                <IconButton 
                    color="inherit"
                    onClick={handleClickLink("sign-up")}
                  >
                   
                        <Typography
                          className={classes.quoteTextPrimary}
                          variant="h6">
                            {t('menu.signup')}
                        </Typography>
                    
                </IconButton>
              </Tooltip>     
          <Tooltip title={t('menu.login')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("sign-in")}
              >
                
                  <Typography
                      className={classes.quoteTextPrimary}
                      variant="h6"
                    >
                    {t('menu.login')}
                  </Typography>
                
            </IconButton>
          </Tooltip>
          <Tooltip title={t('menu.demo')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleSignIn}
              >
                
                <Typography
                  className={classes.quoteTextPrimary}
                  variant="h6"
                >
                  {t('menu.demo')}
                </Typography>
                
            </IconButton>
          </Tooltip>          
          <Tooltip title={t('simulation.simulate')} arrow={true}>
            <IconButton 
                color="white"
                onClick={handleClickLink("simulations")}
              >
                
                 <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                      {t('simulation.simulate')}
                </Typography>
               
            </IconButton>
          </Tooltip>
          <Tooltip title={t('trades.rate')} arrow={true}>
            <IconButton 
                color="white"
                onClick={handleClickLink("market-rates")}
              >
                
                 <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                      {t('trades.rate')}
                </Typography>
               
            </IconButton>
          </Tooltip>
          <Tooltip title={t('menu.faq')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("faq")}
              >
                
                <Typography
                  className={classes.quoteTextPrimary}
                  variant="h6"
                >
                  {t('menu.faq')}
                </Typography>
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={t('P2P Game')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("p2pgames")}
              >
                
                <Typography
                  className={classes.quoteTextPrimary}
                  variant="h6"
                >
                  {t('P2P Game')}
                </Typography>
            </IconButton>
          </Tooltip> */}

              </div> 
    }
  }

  const getLangMenu = () => {

    return <div>
            <Menu
              id="lang-menu"
              anchorEl={anchorLG}
              keepMounted
              open={Boolean(anchorLG)}
              onClose={handleCloseLang}
            >
              <MenuItem onClick={handleChangeSimpleLang('en')}>
                    <Typography
                        className={classes.quoteText}
                        variant="h6"
                      >
                     {t('common.language-english')}
                    </Typography>
                  
              </MenuItem>
              <MenuItem onClick={handleChangeSimpleLang('ja')}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                        {t('common.language-japanese')}
                    </Typography>
               
              </MenuItem>
              <MenuItem onClick={handleChangeSimpleLang('cn')}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                       {t('common.language-chinese')}
                    </Typography>
               
              </MenuItem>
          </Menu>
      </div> 
  }

  const getLoginSmallMenu = () => {

    if(userId){

      return <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              
              <MenuItem onClick={handleClickLink("dashboard")}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                         {t('sidebar.dashboard')}
                    </Typography>
              </MenuItem>
              <MenuItem onClick={handleClickLink("simulations")}>
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                         {t('sidebar.simulation')}
                  </Typography>
                 
              </MenuItem>
              <MenuItem onClick={handleClickLink("market-rates")}>
                  
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                        {t('trades.rate')}
                  </Typography>
                 
              </MenuItem>
              <MenuItem onClick={handleClickLink("faq")}>
                 
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                         {t('menu.faq')}
                  </Typography>
                 
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                    <Typography
                        className={classes.quoteText}
                        variant="h6"
                      >
                      {t('menu.logout')}
                    </Typography>
                  
              </MenuItem>
              {/* <MenuItem onClick={handleClickLink("p2pgames")}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                         {t('sidebar.bet')}
                    </Typography>
              </MenuItem> */}
            </Menu>
          </div>
    }else{

      return <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              
              <MenuItem onClick={handleClickLink("sign-up")}>
               
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                        {t('menu.signup')}
                    </Typography>
               
              </MenuItem>
              <MenuItem onClick={handleClickLink("sign-in")} >
                    
                    <Typography
                        className={classes.quoteText}
                        variant="h6"
                      >
                      {t('menu.login')}
                    </Typography>
                 
              </MenuItem>
              <MenuItem onClick={handleSignIn}>
                <Typography
                  className={classes.quoteText}
                  variant="h6">
                    {t('menu.demo')}
                </Typography>
              </MenuItem>
              
              
              <MenuItem onClick={handleClickLink("simulations")}>
                  
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                        {t('sidebar.simulation')}
                  </Typography>
                 
              </MenuItem>
              <MenuItem onClick={handleClickLink("market-rates")}>
                 
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                         {t('trades.rate')}
                  </Typography>
                  
              </MenuItem>
              <MenuItem onClick={handleClickLink("faq")}>
                  
                  <Typography
                      className={classes.quoteText}
                      variant="h6"
                    >
                        {t('menu.faq')}
                  </Typography>
                 
              </MenuItem>
              {/* <MenuItem onClick={handleClickLink("p2pgames")}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                         {t('sidebar.bet')}
                    </Typography>
              </MenuItem> */}
              
            </Menu>
              </div> 
    }
  }
  
  // const handleCloseProgress = () => {
  //   setOpenProgress(false);
  // };
  
  
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Circular ref={refCircular} />
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/avatars/xWinLogo-2.jpg"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <div className={classes.div} >
            {getLoginMenu()}
          </div>  
        
        <div className={classes.divsmall} >
          <IconButton
            color="inherit"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          {getLoginSmallMenu()}
        </div>
        <div className={classes.divsmall} >
          <IconButton
            color="inherit"
            onClick={handleClickLang}
          >
            <LanguageIcon />
          </IconButton>
          {getLangMenu()}
        </div>
      
        <IconButton
            className={classes.signOutButton}
            color="inherit"
          >
            <FormControl className={classes.formControl}>
              <Select
                value={values.lang || i18n.language}
                onChange={handleChangeLang()}
                classes={{
                  root: classes.whiteColor,
                  icon: classes.whiteColor
                }}
                inputProps={{
                  name: 'lang',
                  id: 'lang',
                }}
              >
                <MenuItem value="en">{t('common.language-english')}</MenuItem>
                <MenuItem value="ja">{t('common.language-japanese')}</MenuItem>
                <MenuItem value="cn">{t('common.language-chinese')}</MenuItem>
              </Select>
            </FormControl>
            </IconButton>
      </Toolbar>
      
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

//export default Topbar;
export default withRouter(Topbar);

import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import appGlobalConfig from "appGlobalConfig"
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAuth } from "../../use-auth.js";
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import PopupMessage from '../PopupMessage/PopupMessage'
import { TermService, TermServiceja } from '../TermService';
//import { TermService } from './components/TermService/xLendingTNS.htm';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  // lastName: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 32
  //   }
  // },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  },
  Risk1: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  },
  Risk2: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    //height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '700px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(2),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    }
    
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 105,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignUp = props => {
  const { history } = props;

  const auth = useAuth()
  const classes = useStyles();
  const { t, i18n } = useTranslation()

  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (myuser && myuser.emailVerified) {
        props.history.replace('/dashboard')
        return
    }
  });
  
  const ref = useRef(null);
  
  const [termOpen, setTermOpen] = useState(false)
  const [readTerm, setReadTerm] = useState(false)
  
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

//    console.log(errors)
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleMessageOpen = (msg, status) => {
    //console.log(msg)
    ref.current.handleOpen(msg, status);
  }

  const handleTermOpen = event => {
    event.persist();
    setTermOpen(true)
    
    //formState.values.policy = true
  }
  
  const handleCloseTerm = () => {
    setTermOpen(false);
    setReadTerm(true)
  };



  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSignUp = event => {
    event.preventDefault();

    try {

      auth.signup(formState.values.email, formState.values.password)
      .then(response => {
        //console.log(response)

        if(response !== undefined){
          let uID = response.uid
          let referal = ""
          if(formState.values.referral !== undefined)
          {
            referal = formState.values.referral
          }
          firebase.firestore().collection("users").doc(uID).set({
            "name": formState.values.firstName, // + " " + formState.values.lastName,
            "email": formState.values.email,
            "user": uID,
            "phone": "xxxxxxxx",
            "country": "Japan",
            "sex": "m",
            "lang": "en",
            "created_at": new Date,
            "referral" : referal
          })
            .then(function () {
  
              firebase.firestore().collection("user_config").doc(uID).set({
  
                exchanges: {
                  bitfinex: {
                    key: "your-bitfinex-api-key-xxxxxx",
                    secret: "your-bitfinex-secret-key-yyyyyy"
                  },
                  poloniex: {
                    key: "your-poloniex-api-key-xxxxxx",
                    secret: "your-poloniex-secret-key-yyyyyy"
                  },
                },
                notify: {
                  line: {
                    token: "your-line-token-zzzzzz",
                    enable: false
                  },
                  telegram: {
                    token: "",
                    enable: true
                  }
                },
                user : uID
  
              })
  
            })
            .then(function () {
  
              firebase.firestore().collection("bot_config").doc(uID).set({
                "host": appGlobalConfig.freePlanServer,
                "status": "stop",
                "user": uID
              })
  
            })
            .then(function () {
  
              let today = new Date()
              let date_expired = new Date(today.setMonth(today.getMonth() + 120))

              firebase.firestore().collection("subscriptions").doc(uID).set({
                "plan_id": "F-001",
                "limitloan": 2000000,
                "payment_method": "",
                "expiry_date": date_expired
              })
  
            })
            .then(function () {
              
              let actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: appGlobalConfig.actionCodeSettingsURL, // 'https://cryptolending-7b810.firebaseapp.com/sign-in',
                handleCodeInApp: false
              };
              auth.sendEmailVerification(actionCodeSettings)
              .then(response => {
                if(response === "ok"){
                  //handleMessageOpen(t('signup.verify.msg'), "info")
                  alert(t('signup.verify.msg')) 
                  sendAdminEmail()
                }
              })  
            })
            props.history.replace('/thank-you')
            
        }else{

        }
      });
    } catch (error) {
      handleMessageOpen(error, "error")
      //alert(error)
      //ref.current.handleOpen(error); 
    }

  };

  const sendAdminEmail = () => {

      const response = fetch(appGlobalConfig.sendSignUpAdminEmail, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "cust_name": formState.values.firstName, // + " " + formState.values.lastName,
          "cust_email": formState.values.email,
        }),
      }).then(response => response.json())
      
      .then(data => {
        console.log(data)
    });
  }

  const getTermService = event => {
    if(i18n.language === "ja"){
      return <TermServiceja />
    }else{
      return <TermService />
    }
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <PopupMessage ref={ref} />
      <Dialog
        open={termOpen}
        keepMounted
        fullWidth={true}
        maxWidth = {'lg'}
        //onClose={handleCloseTerm}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{t('signup.termcondition')}</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
              {/* <TermService /> */}
              {getTermService()}
          </DialogContentText>
        </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleCloseTerm} 
              color="primary"
              startIcon={<VerifiedUserIcon />}>
            {t('common.understand.button')}
            </Button>
          </DialogActions>
        </Dialog>

      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >

                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  {/* Create new account */}
                  {t('signup.createnewaccount')}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  {t('signup.useemail')}
                  {/* Use your email to create new account */}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  label={t('signup.firstname') + " & " + t('signup.lastname')}
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                />
                {/* <TextField
                  className={classes.textField}
                  error={hasError('lastName')}
                  fullWidth
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  label={t('signup.lastname')}
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastName || ''}
                  variant="outlined"
                /> */}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={t('account.email')}
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label={t('signin.password')}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  //error={hasError('referral')}
                  fullWidth
                  // helperText={
                  //   hasError('referral') ? formState.errors.referral[0] : null
                  // }
                  label={t('signup.referalcode')}
                  name="referral"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.referral || ''}
                  variant="outlined"
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    disabled={!readTerm}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {t('signup.ihaveread')} {' '}
                    <Button
                      //className={classes.signUpButton}
                      color="primary"
                      //disabled={!formState.isValid}
                      //fullWidth
                      size="small"
                      //type="submit"
                      onClick={handleTermOpen}
                      variant="outlined"
                    >
                      {t('signup.termservice')} 
                      {/* Terms and Conditions */}
                    </Button>                    
                  </Typography>
                  
                  </div>
                  {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.Risk1 || false}
                    className={classes.policyCheckbox}
                    disabled={!readTerm}
                    color="primary"
                    name="Risk1"
                    onChange={handleChange}
                  />
                   <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                      {t('signup.term1')}
                  </Typography>
                  
                </div>
                {hasError('Risk1') && (
                    <FormHelperText error>
                      {formState.errors.Risk1[0]}
                    </FormHelperText>
                  )}
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.Risk2 || false}
                    className={classes.policyCheckbox}
                    disabled={!readTerm}
                    color="primary"
                    name="Risk2"
                    onChange={handleChange}
                  />
                   <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {t('signup.term2')}
                  </Typography>
                  
                </div>
                {hasError('Risk2') && (
                  <FormHelperText error>
                    {formState.errors.Risk2[0]}
                  </FormHelperText>
                )}
                
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign up now
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                   {t('signup.haveaccount')} {' '}
                  {/* Have an account?{' '} */}
                  <Link
                    component={RouterLink}
                    to="/sign-in"
                    variant="h6"
                  >
                     {t('signin.signin')} 
                    {/* Sign in */}
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
//import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import {
  Card,
  Typography,
  Grid,
  CardMedia,
  Link
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3,3,10,3),
    
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  actions: {
    padding: theme.spacing(3, 15, 3),
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 5, 3),
      textAlign: 'center',
      justifyContent: 'center',
      },
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(20),
    //flexBasis: '33.33%',
    //flexShrink: 0,
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: theme.typography.pxToRem(10),
    // },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  quoteContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    //height: 200,
    position: 'relative',
    zindex: 0, 

  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  grid: {
     padding: theme.spacing(1, 3, 1),
     justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  card: {
    padding: theme.spacing(0, 0, 0),
    justifyContent: 'center',
    alignItems: 'center',
 },
}));



const LearnPlatform = props => {
  const { className } = props;
  
  const classes = useStyles();
  const { t } = useTranslation()
  return (

    
    <div className={classes.root}>
     
     <div className={classes.quoteInner}>
     <Typography variant="h2" color="textSecondary" component="p">
          {t("learnplatform.supported")}
        </Typography>
     </div>
        
      <Grid
        className={classes.grid}
        container
      >
     
        
        <Grid
            className={classes.grid}
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Card
              className={classes.card}
            >
              <Link
                component="a"
                href="https://bitfinex.com/"
                target="_blank"
              >
              <CardMedia
                component="img"
                alt="Bitfinex Exchange"
                height="80"
                image="/images/logos/bitfinex_header.jpg"
                title="Bitfinex Exchange"
              />
              </Link>
            </Card>
        </Grid>
        <Grid
            className={classes.grid}
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Card
              className={classes.card}
            >
              <Link
                component="a"
                href="https://poloniex.com/"
                target="_blank"
              >
              <CardMedia
                component="img"
                alt="Poloniex Exchange"
                height="80"
                image="/images/logos/poloniex_header.png"
                title="Poloniex Exchange"
              />
              </Link>
            </Card>
        </Grid>
        <Grid
            className={classes.grid}
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Card
              className={classes.card}
            >
              <Link
                component="a"
                href="https://compound.finance/"
                target="_blank"
              >

              <CardMedia
                component="img"
                alt="Compound Finance"
                height="80"
                image="/images/logos/compound_header.png"
                title="Compound Finance"
              />
              </Link>
            </Card>
        </Grid>
      </Grid>
    </div>

    
  );
};

LearnPlatform.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LearnPlatform;

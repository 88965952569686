import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import palette from 'theme/palette';
import { Bar  } from 'react-chartjs-2';
import moment from 'moment';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent,
} from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 300,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  content: {
    padding: 0
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 250,
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));


const SymbolProfits = props => {
  const { className, mainData, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  let colldates = []
  
  const getFinalData = () => {
    
    let colldatas = []
    let collsymbol = []
    

    //console.log(mainData)
    mainData.forEach(p => {
      let desc = p.description
      if(desc.includes("Margin Funding")){ //temporary. to be changed to filter by type
        let s = p.symbol
        let d = moment(new Date(p.pay_time.seconds * 1000)).format('L')
        let myD = new Date(d)
        let forD = myD.getFullYear().toString() + myD.getMonth().toString() + myD.getDate().toString()
        let mykey = forD + s
        let bal = Number(p.amountBTC).toPrecision() 

        //console.log(s + "->" +bal)
        let r = colldatas.find(t => t.key === mykey)
        if(r) //collection exists
        {
          r.balance = Number(r.balance) + Number(bal)
          r.date = d
        }else{
          colldatas.push({
            key: mykey,
            symbol: s,
            date: d,
            balance: bal,
            //period : period
          })
        }
      }
    })

    //console.log(colldatas)
    //console.log(colldatas)
    let myPayLabel = []
    let myPayData = []
    
    colldatas.forEach(d => {
      if(d.key !== ""){
        myPayData.push(d.balance)

        //collect all the symbols
        let mysymbol = collsymbol.find(t => t.symbol === d.symbol)
        if(mysymbol === undefined) //collection exists
        {
          collsymbol.push({
            symbol: d.symbol
          })
        }

        //collect unique dates
        let mydate = colldates.find(t => t.date === d.date)
        if(mydate  === undefined) //collection exists
        {
          colldates.push({
            date: d.date
          })
        }

      }
    });

    colldates.forEach(d => {
      if(d.date !== ""){
        myPayLabel.push(d.date)
      }
    });

    //console.log(colldatas)
    let paymentdata = colldatas
    let paymentlabel = myPayLabel
    let totalSymbols = collsymbol
    return {paymentdata, paymentlabel, totalSymbols}

  }

  const {paymentdata, paymentlabel, totalSymbols} = getFinalData()


var numberWithCommas = function(x) {

  return xlendingUtils.roundTo(Number(x).toPrecision(), 8)
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: 
  { 
    display: true,
    position: 'bottom'
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function(tooltipItem, data) { 
        return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.value);
      }
    }
   },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        stacked: true, 
        barThickness: 10,
        maxBarThickness: 10,
        //barPercentage: 1,
        //categoryPercentage: 1,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        stacked: true, 
        ticks: {
          callback: function(value) { return numberWithCommas(value); },
         }, 
        gridLines: {
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};

const backgroundColors = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.text.primary,
  theme.palette.info.main,
  theme.palette.success.main,
  theme.palette.text.disabled,
  theme.palette.primary.main,
  theme.palette.secondary.main
]


const getDatasets = () => {

  let arr = []

  let i = 0
  totalSymbols.forEach(s => {

    if(s.symbol !== ""){
      let firstData = paymentdata.filter(x=>x.symbol === s.symbol)
      //console.log(firstData)
  
      let dataset1 = []
      let temp = []

      colldates.forEach(d => {
        temp.push({
          symbol: d.symbol,
          date: d.date,
          bal : 0
        })
      });

      temp.forEach(t => {
        let founddata = firstData.find(x=>x.date === t.date)
        if(founddata){
          dataset1.push(founddata.balance)
        }else{
          dataset1.push(0)
        }
      });


      //console.log(dataset1)
      arr.push({
        label: s.symbol,
        backgroundColor: backgroundColors[i],
        data: dataset1
      })
      i++
    }
  });

  //console.log(arr)

  return arr
}

// const getDatasets = () => {

//   let arr = []

//   let i = 0
//   totalSymbols.forEach(s => {

//     if(s.symbol !== ""){
//       let firstData = paymentdata.filter(x=>x.symbol === s.symbol)
//       //console.log(firstData)
  
//       let dataset1 = []
//       firstData.forEach(d => {
//         dataset1.push(d.balance)
//       });

//       arr.push({
//         label: s.symbol,
//         backgroundColor: backgroundColors[i],
//         data: dataset1
//       })
//       i++
//     }
//   });

//   //console.log(arr)

//   return arr
// }

const data = {
  labels: paymentlabel,
  datasets: getDatasets()
};

const getLoadingContent = () => {

    return <div className={classes.chartContainer}>
            <Bar 
              data= {data}
              options={options}
            />
          </div>
}
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        {getLoadingContent()}
      </CardContent>
    </Card>
  );
};

SymbolProfits.propTypes = {
  className: PropTypes.string
};

export default SymbolProfits;

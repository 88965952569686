import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Button,
  CardContent,
  CardActions,
  CardHeader,
  Tooltip,
  Link,
  IconButton
} from '@material-ui/core';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  image: {
    width: "50%",
    //height: "200",
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <SettingsIcon />,
    3: <SettingsIcon />,
    4: <SettingsIcon />,
    5: <SettingsIcon />,
    6: <SettingsIcon />,
    7: <SettingsIcon />,
    8: <SettingsIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  div: {
    alignItems: 'center',
    justifyContent: 'center',
    height: "150"
  },
  actions: {
    justifyContent: 'flex-end'
  },
  iconbutton: {
    height: 66,
    width: 66,
    color: theme.palette.primary.main
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
    width: "80%",
    [theme.breakpoints.down('xs')]: {
      width: "80%",
    }
    //height: "200",
    //maxHeight: "200",
  },
  image2: {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: "80%",
    }
  },
  content: {
    padding: 0
  },
  headertitle : {
    variant:'h2', 
    align: 'center', 
    color:'primary'
  }
}));


function getStepImage(step) {
  switch (step) {
    case 0:
      return "/images/products/quickstart1.png";
    case 1:
      return "/images/products/quickstart2.png";
    case 2:
      return "/images/products/quickstart4.png";
    case 3:
      return "/images/products/quickstart5.png";
    default:
    //return 'Unknown step';
  }
}

// function getStepImage(step) {
//   switch (step) {
//     case 0:
//       return "/images/products/quickstart1.png";
//     case 1:
//       return "/images/products/quickstart2.png";
//     case 2:
//       return "/images/products/quickstart3.png";
//     case 3:
//       return "/images/products/quickstart4.png";
//     case 4:
//       return "/images/products/quickstart5.png";
//     default:
//     //return 'Unknown step';
//   }
// }



const QuickGuide = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function getSteps() {
    return [
      t('howitwork.step') + '-1', 
      t('howitwork.step') + '-2', 
      t('howitwork.step') + '-3',
      t('howitwork.step') + '-4',
      t('howitwork.step') + '-5',
      //t('howitwork.step') + '-6',
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return t('howitwork.step1') //'Get your coins ready in Bitfinex or Poloniex';
      case 1:
        return t('howitwork.step2')
      case 2:
        return t('howitwork.step4')
      case 3:
        return t('howitwork.step5')
      // case 4:
      //   return t('howitwork.step5')
      // case 5:
      //   return '';  
          default:
      //return 'Unknown step';
    }
  }

  const handleNext = () => {
    
    if(activeStep === steps.length -1){
      //setOpen(false)
      handleReset()
      return
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    
    if(activeStep === 0){
      //setOpen(false)
      handleReset()
      return
    }

    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getLink = (step) => {

    if(step < 2 || step === 3){
      return <img alt="" className={classes.image} src={getStepImage(step)} />
    }else if(step < 4){
      return <img alt="" className={classes.image2} src={getStepImage(step)} />
    }else{
      return <Typography variant="h3" alignItems="center">
              {t('howitwork.visitfaq')}
              <Link
              component={RouterLink}
              to="/faq"
            > -> FAQ </Link>
            <br/>
            <br/>
            <br/>
            </Typography>
      
    }
  
  }
  
  return (
    <Card
      // {...rest}
      // className={clsx(classes.root, className)}
    >
      <CardHeader
              action={
                <div>
                  <IconButton 
                    onClick={handleBack}
                    className={classes.iconbutton}>
                      <SkipPreviousIcon />
                  </IconButton>
                  <IconButton 
                    onClick={handleNext}
                    className={classes.iconbutton} >
                      <SkipNextIcon />
                  </IconButton>
                </div>
              }
              title={t('home.howitwork')}
              titleTypographyProps= {{variant:'h2', align: 'center', color:'primary'}}
              subheaderTypographyProps={{variant:'body1' }}
            />

        {/* <CardContent className={classes.content}> */}
            <div>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              </div>
              <div align="center" className={classes.image}>
                <Typography variant="h4" >{getStepContent(activeStep)}</Typography>
               
              </div>     
              <div align="center">
              {getLink(activeStep)}
              </div>
        {/* </CardContent> */}
        
     

    </Card>
  );
});

QuickGuide.propTypes = {
  className: PropTypes.string
};

export default QuickGuide;

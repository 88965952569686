import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import { Bar  } from 'react-chartjs-2';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent
} from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 350,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  content: {
    padding: 0
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 250,
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));


const Usages = props => {
  const { className, mainData, ...rest } = props;

  //console.log(mainData)

  const classes = useStyles();
  //const { t } = useTranslation()

  const getFinalData = () => {
    let myPayLabel = []
    let myOnLoanData = []
    let myAvaiableData = []
    mainData.forEach(p => {

      if(p.balance > 0){
        let label = p.symbol +"-"+ p.exchange.toUpperCase()
        let onloan = xlendingUtils.roundTo(p.onLoan / p.balance * 100, 0)
        //let bal = p.balance
        let avail = (100 - onloan)
        //console.log(label)
        myPayLabel.push(label)
        myOnLoanData.push(onloan)
        myAvaiableData.push(avail)
      }
    })

    return {myPayLabel, myOnLoanData, myAvaiableData}

  }

  const {myPayLabel, myOnLoanData, myAvaiableData} = getFinalData()

  

var numberWithCommas = function(x) {
  return x + "%"  //x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: 
  { 
    display: true,
    position: 'bottom'
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function(tooltipItem, data) { 
        return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.value);
      }
    }
   },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        stacked: true, 
        barThickness: 30,
        maxBarThickness: 30,
        barPercentage: 1,
        categoryPercentage: 1,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        stacked: true, 
        ticks: {
          callback: function(value) { return numberWithCommas(value); },
         }, 
        gridLines: {
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
const data = {
  labels: myPayLabel,
  datasets: [
    {
      label: 'On Loan',
      backgroundColor: palette.primary.main,
      data: myOnLoanData
    },
    {
      label: 'Available',
      backgroundColor: palette.primary.light,
      data: myAvaiableData
    }
    
  ]
};
  
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar 
            data= {data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

Usages.propTypes = {
  className: PropTypes.string
};

export default Usages;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LearnMore, LandingHeader, LearnCompound, LearnSimulation, LearnPlatform, LearnPayment, LearnStart } from './components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    
    alignItems : 'center'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  header: {
    padding: theme.spacing(0,0,0,0),
    alignItems : 'center',
    flexGrow: 1,
    height: "100%",
  },
}));

const Home = () => {
  const classes = useStyles();

  

  
  
  

    
  

  return (
    <div >
      <Grid
        container
        spacing={2}
      >
        
        <Grid
          className={classes.header}
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <LandingHeader />
        </Grid>
        
        <Grid
          className={classes.header}
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <LearnMore />
        </Grid>
        <Grid
          className={classes.header}
          item
          lg={12}
          md={6}
          xl={9}
          xs={12}
        >
       
        <LearnStart />
         
        </Grid>
        {/* <Grid
          container
          item
          lg={12}
          md={6}
          xl={9}
          xs={12}
        >
          
          <LearnPayment />
         
          
        </Grid> */}
        

        <Grid
          className={classes.header}
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <LearnSimulation />
           
          {/* <LearnCompound /> */}
        </Grid>
        
        {/* <Grid
          className={classes.header}
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <LearnPayment />
        </Grid> */}
        <Grid
          className={classes.header}
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <LearnPlatform />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowNotificationTelegramcn = props => {
  const { className, } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
      <Typography>
          在“ xLending API设定”选择Telegram选框
          <br/>
          点击“Connect Telegram”
          <br/>
          它加打开Telegram
          <br/>
          <div align="center">
            <img className={classes.image} src={"/images/products/telegram-1.png"} width="75%" height="200"/>
          </div>
          点击Telegram里的“开始”。
          <br/>
          <div align="center">
            <img className={classes.image} src={"/images/products/telegram-2.png"} width="75%" height="200"/>
          </div>
          返回xLending画面，点击“TEST Telegram”按钮以确保其正常
          <br/>
          点击更新以保存Telegram的Token
          <br/>
          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowNotificationTelegramcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowNotificationTelegramcn;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';




const WhatIsXLendingcn = props => {
  

  return (

    <div>
      
        
        <Alert severity={"info"}>
                  <Typography>
                  xLending平台成立于2020年，由日本的技术公司Win Innovation K.K提供。
                  通过我们公司的专有技术，我们提供了自动化平台来替用户更轻松的把代币借出赚取回报。
                  对于要在交易所（例如Bitfinex，Poloniex）上借出代币的用户，用户们需要链接，计算和设置许多琐琐碎碎的步骤来借出代币，
                  并不断地需要手动地去把贷款借出。
                  由于贷款定价过高或过低，这会导致没法借出而失去赚取利息的机会。不但如此，还需要用户持续的监控。
                  <br/>
                  <br/>
                <Divider/>
                xLending的技术通过让其自动化的系统，以最佳利率促进代币的借贷，
                从而优化了借贷流程，并不断监控代币贷款的市场。
                这样以来，用户可以提高收益，减少花在监控贷款帐户上的时间。
                要使用xLending服务，用户需要做的就是注册一个帐户，
                并将其APIKey从交易所链接到xLending帐户。
                使用xLending不会带来额外的风险，因为xLending（或黑客）无法使用用户的代币。
                <br/>
                <br/>
                <Divider/>
                <br/>
                xLending”的特点：
                <br/>
                <br/>■可以以较低的风险赚取利润。
                <br/>■贷款是自动的，没有麻烦。
                <br/>■可以使用高达24种虚拟货币。当然只要是Bitfinex和Poloniex都有的话。
                <br/>■您可以根据预算选择计划。特别的是，不像别的平台，你不需要把你的代币转给第三者。不必担心xLending持有你的代币。
                <br/>■省下你的劳力。且价格大众化。在基本计划的话，如果您经营一个价值1百万日元（年利率10％）的虚拟货币1个月，您将获得10,000日元，因此5美元的佣金并不多。
                <br/>■我们有支持日语，中文和英语的平台。 xLending的WinInnovation Co.，Ltd.是一家日本公司，总部位于东京。 “ XLending”以日语，中文和英语提供，并且已对使用指南进行了编辑以方便理解。
                <br/>
                <br/>
                使用“ Poloniex”或“ Bitfinex”提供的自动交易API设置借贷机制。
                之后，使用xLending的统计算法自动执行贷款。
                如果您进行借阅，请尝试充分利用xLending。


                </Typography>
                </Alert>
              
     
    </div>

    
  );
};

WhatIsXLendingcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatIsXLendingcn;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const WhyNoLendingja = props => {
  
  return (

    <div>
      
        
        <Alert severity={"info"}>
        <Typography>
        次のようないくつかの理由が考えられます。
                  <br/>
                  <br/>1. ラストオファーレートは、最小レートの設定よりも低くなっています。
                  <br/>2. 貸し出す資産が`取引所の最低額（Bitfinex 50 USD / Poloniex 0.01 BTC）よりも低くなっています。
                  </Typography>
          </Alert>
          
    </div>

    
  );
};

WhyNoLendingja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhyNoLendingja;

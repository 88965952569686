import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToStart0ja = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            1．	Bitfinexのホームページにログインする　https://www.bitfinex.com/
            <br/>
            2．	下記、［Wallet］→［Deposit］をクリックする
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-1.png"} width="100%"/>
            </div>
            3．	下記、送金したい仮想通貨を選択し、クリックする。【重要：しっかりとコインの種類を確認してください】
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-2.png"} width="100%"/>
            </div>
            4．【例】サンプルとして、Bitcoin Cashを選択したとして説明を続けます。
            <br/>
            5．下記のように、自分の選んだ仮想通貨になっているか？確認ください。
            <br/>
            6．［Funding Wallet］の［Click to generate address（アドレスの発行）］をクリックし、アドレスを発行してください。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            7．	そして、このアドレスをコピーして、ご自身が管理されている仮想通貨交換所から、選択した仮想通貨を送金してください。
            <br/>
            8．	下記のように、仮想通貨が送金されているか確認ください。
            ［Wallet］→［Wallets］をクリックし、以下の画面に移動します。
            そして確認ください。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start0-4.png"} width="100%"/>
            </div>
           
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToStart0ja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToStart0ja;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Portfolio, Usages } from './components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";

import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  Tooltip
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const PortfolioWallet = props => {
  const { className, exRates, ...rest } = props;
    
  const classes = useStyles();
  const { t } = useTranslation()
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [mainData, setMainData] = React.useState([]);
  const { user } = useAuth()
  const [tickerPrices, setTickerPrices] = useState([])
  
  let { uid: userId } = user || {}
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  useEffect(() => {
    //let mydate = getFirstLastDate(SaleChart.month)
    let mydate = new Date();
    mydate.setHours(0, 0, 0, 0);
    getData(mydate)
  }, [])

  const getData = (mydate) => {
    
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        //setIsLoading(true)
        userId = myuser.uid
        const unsubscribe = firebase
        .firestore()
        .collection('wallets')
        .where('user', '==', userId)
        .where('updatedAt', ">", mydate)
        .orderBy("updatedAt", "desc")
        //.limit(pairs.length || 10)
        .onSnapshot((snapshot) => {

          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))

          setMainData(newValues)
        })

        return () => { unsubscribe() }
      }
    })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tooltip title={t("botsetup.positiondesc")} arrow={true}>
          <Tab label={t('portfolio.position')} {...a11yProps(0)} />
        </Tooltip>
        <Tooltip title={t("botsetup.onloandesc")} arrow={true}>
          <Tab label={t('portfolio.usage')} {...a11yProps(1)} />
        </Tooltip>
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Portfolio 
            mainData={mainData}
            mainTickerPrices={exRates}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Usages mainData={mainData}/>
        </TabPanel>
        
      </SwipeableViews>
      </Card>
  );
};


PortfolioWallet.propTypes = {
  className: PropTypes.string
};

export default PortfolioWallet;


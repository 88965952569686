import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useAuth } from "../../use-auth.js";
import firebase from "firebase/app";

import {
  Bitfinex,
  Poloniex,
  BotAvailables
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
}));

const BotSetup = props => {
  const { subsPlans, ...rest } = props;
  const classes = useStyles();
  const [userplan, setUserplan] = useState([])
  const [symbols, setSymbols] = useState([])
  
  const { user } = useAuth()
  let { uid: userId } = user || {}
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
  });


  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
        const unsubscribe = firebase
        .firestore()
        .collection('subscriptions')
        .doc(userId).onSnapshot(doc => {
          setUserplan(doc.data())
        })
        return () => { unsubscribe() }
      }
    })
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
        const unsubscribe = firebase
        .firestore()
        .collection('symbols_config')
        .where('user', '==', userId)
        .onSnapshot((snapshot) => {
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          console.log(newValues)
          setSymbols(newValues);
        })
        return () => { unsubscribe() }
      }
    })
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          //className={classes.grid}
          item
          lg={4}
          md={10}
          xl={7}
          xs={12}
        >
          <BotAvailables 
            subsPlan={subsPlans}
            userplan={userplan}
            symbols={symbols}
            />
            
        </Grid>

        <Grid
          //className={classes.grid}
          item
          lg={4}
          md={10}
          xl={7}
          xs={12}
        >
          <Bitfinex 
            subsPlan={subsPlans}
            userplan={userplan}
            symbols={symbols}
            />
           
        </Grid>
        <Grid
          //className={classes.grid}
          item
          lg={4}
          md={10}
          xl={7}
          xs={12}
        >
          <Poloniex 
            subsPlan={subsPlans}
            userplan={userplan}
            symbols={symbols}
            />
        </Grid>
        
        
      </Grid>
    </div>
  );

};

export default BotSetup;

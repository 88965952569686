import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';




const WhatCostja = props => {
  

  return (

    <div>
     
        
        <Alert severity={"info"}>
        <Typography>
                      
        次の2つのサブスクリプションプランを提供しています。
                      <br/>
                      <ui>
                        <li>Basic（USD 5/月）。 初心者にぴったり</li>
                        <li>Premium（USD 35/月）。 より多きなポートフォリオに適しています</li>
                        <li>Platinum（USD 95/月）。 機関顧客やヘッジファンドに適しています</li>
                      </ui> <br/>
                      
                      
                    </Typography>
          </Alert>
       
    </div>

    
  );
};

WhatCostja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatCostja;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';




const WhatCostcn = props => {
  
  return (

    <div>
     
        <Alert severity={"info"}>
        <Typography>
                      
        我们提供以下两个订阅计划。
                      <br/>
                      <ui>
                        <li>Basic（5美元/月）。适合初学者体验者</li>
                        <li>Premium（35美元/月）。适用于较大的投资组合</li>
                        <li>Platinum（95美元/月）。适用于专业基金管理</li>
                      </ui> <br/>
                      
                      
                    </Typography>
          </Alert>
       
    </div>

    
  );
};

WhatCostcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatCostcn;

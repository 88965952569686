import React, { useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { useAuth } from "./use-auth.js";
import firebase from "firebase/app";
import xlendingUtils from 'xlendingUtils'


import {
  Dashboard as DashboardView,
  Compound as CompoundViewDashboard,
  Compound as CompoundView,
  ProductList as ProductListView,
  BotsList as BotsListView,
  BotSetup as BotSetupView,
  Subscription as SubscriptionView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Apisetting as APISettingView,
  NotFound as NotFoundView,
  ThankYou as ThankYouView,
  MarketRates as MarketRatesView,
  MarketRates as MarketRatesViewDashboard,
  Simulations as SimulationsView,
  Simulations as SimulationsViewDashboard,
  FAQ as FAQView,
  P2PGames as P2PGamesView,
  Home as HomeView
  
} from './views';


const Routes = props => {
  const { ...rest } = props;
  const [subsPlans, setSubsPlans] = useState([])
  const [exRates, setExRates] = useState([])

  const auth = useAuth()
  //auth.signout()
  // if (auth.user) {
  //   alert(auth.user.uid)
  // }

  useEffect(() => {
    
      const unsubscribe = firebase
      .firestore()
      .collection('subsription_plans')
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          ...doc.data()
        }))
        //console.log(newValues)
        
        newValues.sort(xlendingUtils.compareValues('id', 'asc'));
        setSubsPlans(newValues)
      })
      return () => { unsubscribe() }
    
    
  }, [])


  useEffect(() => {

    var today = new Date();
    today.setHours(0, 0, 0, 0);

    const unsubscribe = firebase
    .firestore()
    .collection('tickers')
    .where('type', '==', "t")
    .where('exchange', '==', "bitfinex")
    .where('ticker_time', '>=', today)
    .orderBy("ticker_time", "desc")
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))

      //console.log(newValues)
      let tickerarr = []
      newValues.forEach(r => { 
        tickerarr.push({
          symbol: r.symbol,
          last: r.last,
          ask: r.ask,
          bid: r.bid
        })
      })
      setExRates(tickerarr);
    })
    return () => { unsubscribe() }

}, [])
  

  return (
    <Switch>

      <RouteWithLayout exact path="/">
        <Redirect to="/home"/>
      </RouteWithLayout>
      <RouteWithLayout
        exRates={exRates}
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={CompoundView}
        exact
        layout={MinimalLayout}
        path="/compound"
      />
      <RouteWithLayout
        component={CompoundViewDashboard}
        exact
        layout={MainLayout}
        path="/compound-d"
      />
      <RouteWithLayout
        component={APISettingView}
        exact
        layout={MainLayout}
        path="/apisetting"
      />
      <RouteWithLayout
        component={BotsListView}
        exact
        layout={MainLayout}
        path="/bots"
      />
      <RouteWithLayout
        subsPlans={subsPlans}
        component={BotSetupView}
        exact
        layout={MainLayout}
        path="/botsetup"
      />
      <RouteWithLayout
        subsPlans={subsPlans}
        component={SubscriptionView}
        exact
        layout={MainLayout}
        path="/subscription"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ThankYouView}
        exact
        layout={MinimalLayout}
        path="/thank-you"
      />
      <RouteWithLayout
        component={MarketRatesView}
        exact
        layout={MinimalLayout}
        path="/market-rates"
      />
      <RouteWithLayout
        component={MarketRatesViewDashboard}
        exact
        layout={MainLayout}
        path="/market-rates-d"
      />
      <RouteWithLayout
        exRates={exRates}
        subsPlans={subsPlans}
        component={SimulationsViewDashboard}
        exact
        layout={MainLayout}
        path="/simulations-d"
      />
      <RouteWithLayout
        exRates={exRates}
        subsPlans={subsPlans}
        component={SimulationsView}
        exact
        layout={MinimalLayout}
        path="/simulations"
      />
      <RouteWithLayout
        component={FAQView}
        exact
        layout={MinimalLayout}
        path="/faq"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/home"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={P2PGamesView}
        exact
        layout={MinimalLayout}
        path="/p2pgames"
      />
      <Redirect to="/not-found"/>
      
    </Switch>
  );
};

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },
  image: {
    width: "100%",
    height: "100%",
  },

}));


const HowMonarchPayja = props => {
  const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
            1. 登録するプランの[購読]をクリックします
            <br/>
            2. Monarch Payの支払い方法を選択
            <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/payment-2.png"} width="100%" height="600"/>
          </div>
            3. まず、Monarch Pay Walletが必要です
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-1.png"} width="100%" />
          </div>
          <br/>
            4. 新しいウォレットの作成または既存のインポート
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-2.png"} width="100%" />
          </div>
          <br/>
            5. Monarch Pay Walletの準備ができたら、パブリックETHアドレスをテキストボックスに保存してください
          <br/>
            6. [次へ]ボタンをクリックして、指示に従います
          <br/>
            7. Monarch Payウォレットの請求書にリダイレクトされます。 ウォレットにログインしてください
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-4.png"} width="100%" />
          </div>
          <br/>
            8. Monarch Payウォレットの請求書にリダイレクトされます。 ウォレットにログインしてください
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/monarch-6.png"} width="100%" />
          </div>
          <br/>
            9. UserName / EmailにxLendingで登録したメールアドレスを入力します
            <br/>
            10. xLendingのサブスクリプションページからコピーできるユーザーIDを追加情報に入力します
            <br/>
            11. 購読をクリックします
          
            <br/>
            <br/>
            <Divider/>
            Once the payment is done in Monarch Pay, please wait for a while for the system get updated
            <br/>
            If you still see "Payment Not Paid" in the tab after 30 to 60 mins. Please contact our support at support@xlending.cc 
            
          </Typography>
        </Alert>
    </div>

    
  );
};

HowMonarchPayja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowMonarchPayja;

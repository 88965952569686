import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  const { subsPlans, exRates } = props;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component 
            exRates={exRates} 
            subsPlans={subsPlans} 
            {...props} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;

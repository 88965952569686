import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const WhatRiskcn = props => {
  
  return (

    <div>
     
        <Alert severity={"info"}>
                  <Typography>
                      
                  在xLending上存储API Key不会带来任何风险。因为你的API Key是无法提款的。
                  如果xLending被黑客入侵并且您的API Key被盗的可能性很小，因为APIKEY对黑客无用。
                  同样，主要风险之一是交易所的风险。原因是：<br/>
                  <ui>
                    <li>交易所失误：欺诈或扣押，</li>
                    <li>很大的价格变动，贷款人无法无法还款。</li>
                    <li>请再次检查和确认你的API key是不允许提款的。</li>
                    <li>还有一点，不要忘记在贷款期间您的资金将无法使用。</li>
                  </ui>
                  </Typography>
                </Alert>
     
    </div>

    
  );
};

WhatRiskcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatRiskcn;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';





const WhatMinThresholdcn = props => {
 
  return (

    <div>
      
        <Alert severity={"info"}>
                <Typography>
                Bitfinex API最低金额为50美元。
                我们在其顶部添加了一个额外的缓冲区来补偿价格波动。
                这意味着您的资金钱包中有要有55美元或更多。
                <br/>对于Poloniex，以下是最低金额：<br/>
                    Bitcoin: 0.01 BTC<br/>
                    Ethereum: 0.1 ETH<br/>
                    Dash: 0,1 DASH<br/>
                    Clams: 5 CLAM<br/>
                    Factom: 5 FCT<br/>
                    Stellar: 100 STR<br/>
                    Dogecoin: 100 DOGE<br/>
                    Bitcoin Cash: 0.1 BAB<br/>
                    Bitcoin SV: 0.1 BSV<br/>

                  </Typography>
                </Alert>
     
    </div>

    
  );
};

WhatMinThresholdcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatMinThresholdcn;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';


import {
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const TermServiceja = props => {
  const { className, ...rest } = props;
  

  const classes = useStyles();

  return (

    <div className={classes.root}>
      
       <div>
        <Typography variant="h1">
          xLending利用規約
        </Typography>
        <br/>
        <Typography variant="h3">
        利用規約の適応：
            <Typography variant="body1">
            このxLending利用規約（以下「本利用規約」といいます。）は、WIN Innovation株式会社（以下「当社」といいます。）がお客様に提供するxLendingに関わるサービス（以下「本サービス」といいます。）をお客様がご利用される場合に適用されるものとします。今後は、本利用規約を読み、完全な同意を確認した上で、xLendingのサイト（以下「本サイト」といいます。）にログインしたこととなります。本利用規約はいつでも変更できます。xLending は、使用できなくなった古いバージョンをキャンセルして置き換えることもあります。当社は、xLendingのサービスの悪用、利用間違いに対して、いかなる責任を負うものではありません。当社のサービスを利用しているお客様は、(1)本サービスの改変、派生物の作成、またはリバースエンジニアリングを行わないと合意していることになります。(2) 当社のネットワーク、ユーザーアカウント、または本サービスを乱用または破壊する目的で、故意または過失で、当社のサービスを使用することはできません。本利用規約は、xLendingにより予告なく変更されることがあります。したがって、サイトのユーザーは定期的に本利用規約を確認してください。
                      </Typography>
        </Typography>
        <br/>

        <Typography variant="h3">
        サービスについて：
         </Typography>
        <Typography variant="body1"> 
          <Typography variant="h4">提供されるサービスの説明</Typography>
          xLendingは、マージンレンディングに伴う暗号通貨貸付に関連するサービスを提供しています。ユーザーが暗号通貨貸付で利益を最大化するために、独自のテクノロジーを構築し、それを管理するために必要なツールを提供しています。xLendingはビジュアル、レポート、ダッシュボードを提供することで、暗号通貨貸出の監視と管理も容易にしています。xLendingは、可能な限り正確な情報を提供するよう努めています。ただし、当社は、情報がxLendingまたは第三者によって提供されるかどうかにかかわらず、サイト上で提供される情報の省略、不正確性および欠陥に対して責任を負うものではありません。
                  </Typography>
        <br/>

        <Typography variant="body1"> 
          <Typography variant="h4">仕組みについて：</Typography>
          xLendingウェブサイトのユーザーは、メールアドレス、ユーザー名、パスワードを登録して本サービスを利用することが出来ます。登録は、これらの本利用規約の承諾を必要とします。ユーザーは、1 つ以上のサポートされている暗号通貨取引所で、アカウントの資産を持っている必要があります。ユーザーは、APIキーを作成し、xLending ウェブサイトに通知する必要があります。ユーザー アカウントが作成され、API に通知されると、ユーザーはダッシュボードにアクセスして自分のアカウントを表示し、戦略的な観点から決定を下し、ボットが適用する戦略を構成します。ユーザーの戦略を適用します。xLending は、財務上のアドバイスは提供しません。パラメーターの設定をしたり、ボットを適切かつ正確に設定したり、"基本" セットアップをするのはユーザーの役割です。本サービスは、xLending を介して提供された情報を他のソースとクロスチェックするようユーザーにお勧めします。アカウントはいつでもキャンセルできます。
        </Typography>
        <br/>

        <Typography variant="body1"> 
          <Typography variant="h4">支払いについて</Typography>
            xLendingは「ベーシックプラン」と「プレミアムプラン」の2つのサービスを提供しています。適用される価格は、xLendingのウェブサイトに明確に掲載されています。サービスのコスト、ストラクチャー、条件および内容は、いつでも、予告なしに、xLendingによって変更されることがあります。支払いは、ユーザーが支払ったPayPalサブスクリプションベースまたはMonarch Payを通じて実施されます。xLendingは、詐欺者の不当な行為によって、ユーザーの不利益が出た場合でも、払い戻しの責任を負いません。また、Paypalに関連する不具合でも一切の責任を負いません。
          </Typography>
        <br/>

        <Typography variant="body1"> 
          <Typography variant="h4">使用の一般的な注意事項について</Typography>
          xLending は、不正使用を防止するために利用可能なすべての予防措置をすることを強くお勧めします。


          不正使用予防措置例:
          <br/><br/>
          <ul>
            <li> 複雑なパスワードを使用してください。</li>  
            <li> パスワードは定期的に変更してください。</li>  
            <li> xLending は、API キーに必要な最低限の権限しか与えていないことを確認してください。本サイトのFAQで説明しています。</li>  
            <li> IP 制限機能が使用可能な場合は、使用してください。</li>  
            <li> 使用するPCやスマートフォンへの侵入を避けるためのその他の特別な予防措置(ウイルス対策、トロイの木馬対策、盗難対策など)をしてください。</li>  
          </ul>
          <br/>
          さらに、ダッシュボードに与えられデータは情報としてのみであり、正確性は保証しません、また、正確な情報提供義務はありません。この情報は、xLending からの強制的なサービスではありません。ユーザーは、いろいろなソースから、情報を収集し、その正確なデータを得てください。

        </Typography>
        <br/>
        <Typography variant="h3">
        責任の制限について:
         </Typography>

         <br/>
        <Typography variant="body1"> 
          <Typography variant="h4">資産、親権、APIに関して、貸し出す資産の管理人は、xLendingではなく取引所です。</Typography>
          
            <ul>
            <li>xLending は、ユーザーの資産を持つものではありません。したがって、カウンターパーティリスクは、使用される取引所に帰属されています。そのため、xLendingは、取引所上の資産の損失または不正使用に対して責任を負いません。
              </li>  
            <li> xLending は、利用可能なすべてのセキュリティ機能を使用することをお勧めします。これは、xLendingアカウント、Exchangeアカウント、パーソナルデバイス、APIなどにも適用されます。、xLendingには、ユーザーを保護するための完璧なガイドラインが掲載されていません。</li>  
            <li> xLendingは、API キーに必要な最低限の権限しか与えていないことを確認してください。本サイトのFAQで説明しています。</li>  
            <li> xLendingは、ユーザーの過失での取引所に関する資産の損失または不正使用を防ぐために利用可能なすべてのセキュリティ機能を持っていません。xLending は、ユーザーデータを保護するために、あらゆる合理的な措置を講じています。ただし、利用可能なデータの機密性のため、xLendingは唯一のセキュリティレベルにすることはできません。万が一、xLendingにおいて、セキュリティの侵害が発生した場合でも、データと資産が確実に保護されるようにする責任を負うのはユーザーです。</li>  
              <li>xLendingは、xLendingから不正に取得されたデータの悪用に関連する直接的または間接的な影響に対して責任を負いません。</li>  
          </ul>
            
        </Typography>
        <br/>
       
        <Typography variant="body1"> 
          <Typography variant="h4">情報について：</Typography>
          本サイトに掲載されている情報は、可能な限り正確であり、定期的に更新されますが、不正確、不作為、または不備が含まれている場合があります。不具合、エラー、または誤動作と思われる問題に気付いた場合は、問題をできるだけ正確に説明する電子メールで連絡ください(ページポージの問題、トリガーアクション、使用されるコンピュータとブラウザの種類など..．)
                  </Typography>
        <br/>

        <Typography variant="body1"> 
          <Typography variant="h4">その他</Typography>
          ダウンロードされたコンテンツは、ユーザー自身の責任の下で行われます。その結果、xLendingは、ユーザーのコンピュータの損傷やダウンロードに起因するデータの損失に対して責任を負いません。ウェブサイト上で、インターネットの他のリソースにリダイレクトしているハイパーリンクに関して、そのコンテンツや使用に関してxLendingの責任を負いません。
          <br/><br/>
          xLendingは、本サイトで入手可能な情報および/または文書の解釈または使用に起因するいかなる種類の損害に対しても、いかなる責任も負いません。
            <br/><br/>
            xLendingは、サイトの利用に関連する重大な損害に対して責任を負いません。さらに、本サイトのユーザーは、ウイルスを含まない、最新のバージョンが更新されたブラウザで、最近の機器を使用してサイトにアクセスすることに同意します。
            <br/><br/>
            xLendingは、サイトの利用に起因する間接的な損害(例えば、市場の損失や機会の損失など)に対して責任を負いません。
            <br/><br/>
            インタラクティブなスペース(例えば問い合わせなど)は、ユーザーは利用できます。該当する場合、xLendingは、特に使用される媒体(テキスト、写真)に関係なく、人種差別的、虐待的、中傷的または不正なメッセージが発生した場合に、ユーザーを起訴する権利を保持します。ローン、利子支払い、貸し手と借り手を結びつける一般的な管理は、取引所によって管理されています。xLendingは、これらの活動に関連するいかなる紛争に対しても責任を負いません。ユーザーに、直接取引所に連絡するように求められても、xLendingは仲介者の役割を果たすことはできません。
                    </Typography>
        <br/>

        <Typography variant="h3">
        補償条項と集団訴訟の放棄
         </Typography>
         <br/>
        <Typography variant="body1"> 
        故意の場合を除き、xLendingの全責任は、契約、不法行為(過失および厳格責任)、保証、補償、またはその他の場合を問わず、過去1年間にユーザーが支払った合計手数料を超えません。
        <br/>
            <br/>
            上記の例外を条件に、xLendingはいかなる状況下でも、使用の損失、事業中断、利益の損失、収益または機会、またはいかなる種類の損失に責任を負いません。ユーザーとxLendingは、間接的、例示的、偶発的、懲罰的または結果的な損失または損害に対しても責任を負わないと同意しました。
                </Typography>
        <br/>

        <Typography variant="h3">
        サイトへのアクセス:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        xLendingは、「不可抗力」またはxLendingの制御を超えた出来事が起きた場合を除き、24時間年中無休でサイトへのアクセスを提供するよう努めておりますが、サイトが止まる可能性があります。技術的なメンテナンスによる中断は、介入の日時を事前にユーザーに伝えるために最善を尽くしますが、xLendingによって決定・実行される可能性があります。xLendingは、サイトおよび/またはサービスの可用性、応答時間または品質の面でのおよび性能の信頼性を保証することはできません。また、xLendingは、本サイトへのアクセスが不可能な場合やサービスの利用ができない場合には、責任を負うことができません。さらに、xLendingは、通知なしにいつでも、サイトまたはサービスの一部を中断し、補償を受ける権利を有しない場合があります。ユーザーは、xLendingが中断、およびユーザーまたは第三者に発生する可能性のある直接的または間接的な影響に対して責任を負わないことを認め、同意します。
                </Typography>
        <br/>

        <Typography variant="h3">
        知的財産:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        xLending は、サイトの構造とコンテンツの両方に関する知的財産権の独占的な所有者です[およびそのサブサイトも含めて]。本サイトに掲載されているすべての要素(戦略、画像、データベース、ブランド、イラスト、ロゴ、図面、モデル、レイアウト、ダウンロード可能な文書等)は、著作権および知的財産に関する国際法によって保護されています。事前の書面による許可を除き、サイトを構成する任意の要素の複製、表現、適応、部分的または完全な変更は、いかなる手段によっても、法律上、禁止されています。ウェブサイトのユーザーおよび訪問者は、このサイトへのハイパーリンクを設定することができますが、新しいウィンドウが開き、www.xLending.netのURLにアクセスすることになります。サブページ(「ディープリンク」)へのリンクは、xLendingの明示的な事前承認を除き、フレーム内でこのサイトを開く(「フレーミング」)と同様に禁止されています。
                </Typography>
        <br/>

        <Typography variant="h3">
        準拠法:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        本サイト及びその利用に関する一般規約は、利用場所に関係なく日本の法律に準拠しています。紛争が発生した場合、また、友好的な解決策を見つけようとしなかった場合、法的手続きは日本の東京の裁判所にのみ提起されるものとします。ユーザーは、この場合、無条件で、その裁判所の専属管轄権に提出するものとします。紛争解決の言語は日本語です。
        </Typography>
        <br/>

        <Typography variant="h3">
        分離条項:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        本契約のいずれかの条項が無効または拘束力を持たないことが判明しても、その条項がいずれか一方の当事者にとって重大または不可欠なものでない限り、それは他の条項の有効性や拘束力にいかなる影響も及ぼしません。

          </Typography>
        <br/>

        <Typography variant="h1">
        個人データについて
        </Typography>
        <br/>
        <Typography variant="h3">
        個人データ:
            <Typography variant="body1">
            一部のデータは、サイトのユーザー インターフェイスを向上させるだけでなく、匿名のマーケティング目的のために、匿名表示で統計に使用されます。xLendingは、以下の個人データを保持します。
            <ul>
              <li>ユーザー名、パスワード、メールアドレス</li>
              <li>API キー</li>
              <li>ユーザー貸出設定</li>
              <li>貸出金、貸付金、収益、残高に関する履歴データ</li>
              <li>通信に関連する履歴データ(電子メール、電報、メッセンジャー、メッセージボックスなど)</li>
              <li>インターネット サイトへのアクセス日時、インターネット プロトコル アドレス (IP アドレス) が、ただし、特定されません。このデータのセットは、識別後にxLendingインターフェイスを介してユーザーにアクセスして表示されます。</li>
            </ul>
            <br/>
            <br/>
            個人データは、プロモーションまたはイベント情報のためにxLendingによって使用される場合があります。データを渡す法的義務がない限り、または刑事訴追で提供が必要な場合を除き、この個人データはxLendingによって第三者に伝達されません。
                      </Typography>
        </Typography>
        <br/>

        <Typography variant="h3">
        アクセスおよび修正の権利:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        xLendingのユーザーに対しては、個人データにアクセスして修正する権利があります。これを行うには、ユーザーの個人的なスペースにアクセスします。また、お問い合わせにより、お客様のデータの完全な削除をお願いすることもできます。



        </Typography>
        <br/>

        <Typography variant="h3">
        機密性：
         </Typography>
         <br/>
        <Typography variant="body1"> 
        お客様の個人情報は機密であり、特に通知した後を除き、第三者に伝達されるものではありません。
                </Typography>
        <br/>

        <Typography variant="h3">
        保全期間:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        上記の個人データは、必要な期間、またはユーザーが削除を要求するまで保持されます。セキュリティのために、365日を超えて非アクティブな(ログなし、ボットがオンにされていない)アカウントは自動的に廃止とみなされ、ユーザーが要求したかのように削除されます。


</Typography>
        <br/>

        
        <Typography variant="h3">
        責任：
         </Typography>
         <br/>
        <Typography variant="body1"> 
        ユーザーはxLendingに関わる情報を安全な場所に保管する責任を負います。また、第三者により情報がアクセスされる可能性、そしてユーザーによる任意または不本意な送信の場合も含めて、xLendingは、すべての責任を拒否します。ユーザーの個人データ、そして変更または削除に関しても、個人データを処理する権限を有する者が機密性を尊重するか、または適切な対象となるようにします。

      </Typography>
        <br/>

        <Typography variant="h3">
        データセキュリティ:
         </Typography>
         <br/>
        <Typography variant="body1"> 
        お客様の個人情報のセキュリティは重要ですが、インターネット上での送信方法や電子ストレージの方法は100%安全ではありません。当社は、お客様の個人情報を保護するために可能な手段を実行しますが、その絶対的なセキュリティを保証することはできません。
        </Typography>
        <br/>
        <Typography variant="h3">
        クッキー：
         </Typography>
         <br/>
        <Typography variant="body1"> 
        統計および表示のために、このサイトはクッキーを使用します。これらは、ユーザーのナビゲーションに関する技術的なデータを記録するために、ハードディスクに保存された小さなテキストファイルです。本サイトの一部は、クッキーの受け入れなしに機能することはできません。お使いのブラウザからクッキーを保存するための設定をお願いします。</Typography>
        <br/>


        </div>

     
    </div>

    
  );
};

TermServiceja.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default TermServiceja;

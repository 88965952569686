import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowBitfinex = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
        
        <Alert severity={"info"}>
            <Typography>
            1．	Please login to Bitfinex　https://www.bitfinex.com/
            <br/>
            2．	Click on［API Keys］as follow:
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-1.png"} width="100%"/>
            </div>
            3．	Click on［Create New Key］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-2.png"} width="100%"/>
            </div>
            <br/>
            4.【Important】Please check the access rights as follow:
            Margin Funding Read / Margin Funding Write / Wallets Read
            Please just allow xLending to use this API
            <br/>
            5．	Give a label to your API. You can name it as xLending
            <br/>
            6．	Click on［Generate API Key］to get the API key
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-3.png"} width="100%"/>
            </div>
            <br/>
            6．It shows as follow:【Important】Please make a copy of this API key and save it in the safer place.
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-4.png"} width="100%"/>
            </div>
            <br/>
            7．	Next, back to xLending Dashboard
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
            8．	Go to xLending ［API Setting］ tab. 
            <br/>
            9．	Fill in your Bitfinex API and Secret Key
            <br/>
            ＊You can use a QR code reader. In particular, it is convenient for those who operate with a smartphone. 
            Scan the QR code and the API key and secret key will be entered automatically

            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-6.png"} width="100%"/>
            </div>
            <br/>
            10．After entering the Bitfinex API key and secret code, click on [Test BITFINEX].
            <br/>

            11．[Important] Finally, click [Update].
            <br/>
            12．API was set in Bitfinex, and consolidation with xLending was completed.

            <br/>


            
            </Typography>
          </Alert>
        
    </div>

    
  );
};

HowBitfinex.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowBitfinex;

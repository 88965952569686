import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const WhyNoLending = props => {
  

  return (

    <div>
    
        <Alert severity={"info"}>
          <Typography>
                  There could be several reason such as:
                  <br/>
                  <br/>1. The Last Offer Rate is lower than your setting for Minimum rate.
                  <br/>2. Your assets to lend are lower than the xchange's minimum amount (Bitfinex 50 USD / Poloniex 0.01 BTC).
                  </Typography>
          </Alert>
       
    </div>

    
  );
};

WhyNoLending.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhyNoLending;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  inner: {
    minWidth: 1050
  },

}));


const HowNotification = props => {
  const { className,  } = props;
  

  const classes = useStyles();

  return (

    <div
      className={classes.root}
      >
      <Alert severity={"info"} >
          <Typography>
          To set up LINE notifications, you first need to get a token from LINE
          <br/>
          1. Login to <a href="https://notify-bot.line.me/my/" target="blank">https://notify-bot.line.me/my/</a>
          <br/>
          2．	Click [Issue a Token] as shown below.
          <br/>
          
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-1.png"} width="100%"/>
          </div>
          <br/>
          3．	As shown below, enter xLending in the blank of [Please enter the token name (It will be displayed at the time of notification)].
          <br/>
          4．	Click [1: 1 Receive Notification from LINE Notify] as shown below.
          <br/>
          5．	Click [Issue] as shown below.
          <br/>
          

          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-2.png"} width="100%"/>
          </div>
          Select one-on-one chat with LINE Notify. At this point a token ID is generated
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-3.png"} width="100%"/>
          </div>
          <br/>
          6． A token has been issued. Click [Copy] as shown below.
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-4.png"} width="100%"/>
          </div>
          <br/>
          7． Next, return to the xLending dashboard.
          <br/>
          8． Click API Settings on the xLending dashboard as described above.
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-5.png"} width="100%"/>
          </div>
          <br/>
          9． Enter your LINE token as shown below. Paste what you copied earlier.
          <br/>
          <div align="center">
            <img alt="" className={classes.image} src={"/images/products/bitfinex_start2-6.png"} width="100%"/>
          </div>
          10. After entering the LINE token, click [Test LINE].
          <br/>
          11． [Important] Finally, click [Update].
          <br/>
          12． LINE and xLending have been done.

          
          </Typography>
        </Alert>
    </div>

    
  );
};

HowNotification.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowNotification;

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LatestPayment, LatestOffers } from './components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'

import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  Tooltip
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  inner: {
    minWidth: 1050
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const PaymentOffers = props => {
  const { className, userPayments, ...rest } = props;
    
  const classes = useStyles();
  const { t } = useTranslation()
  // const { user } = useAuth()

  // const { uid: userId } = user || {}

  // if (!userId) {
  //   props.history.replace('/sign-in')
  // }
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tooltip title={t("botsetup.activeofferdesc")} arrow={true}>
          <Tab label={t('payments.activeoffer')} {...a11yProps(0)} />
        </Tooltip>
        <Tooltip title={t("botsetup.paymentdesc")} arrow={true}>
          <Tab label={t('payments.activepayment')} {...a11yProps(1)} />
        </Tooltip>
        
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <LatestOffers/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <LatestPayment
            userPayments={userPayments}
          />
        </TabPanel>
        
      </SwipeableViews>
      </Card>
  );
};


PaymentOffers.propTypes = {
  className: PropTypes.string
};

export default PaymentOffers;


import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowToStartPoloniexcn = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
      
        
        <Alert severity={"info"}>
            <Typography>
            1．	登录Poloniex主页ジムペジジ　https://www.poloniex.com/
            <br/>
            2．	单击下面的 ［BALANCES］→［DEPOSITS WITHDRAWALS］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-1.png"} width="100%"/>
            </div>
            3．	选择您要汇出的虚拟货币，然后单击。 [重要提示：请确认你要的代币]
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-2.png"} width="100%"/>
            </div>
            4．[示例]我们以BTC作为示例，继续进行说明。
            <br/>
            5．如下所示，请确认那个是您选择的虚拟货币。
            <br/>
            6．单击[Deposit]。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-3.png"} width="100%"/>
            </div>
            <br/>
            7．	然后从您自己的虚拟货币钱包发送到这个交易所的地址。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-4.png"} width="100%"/>
            </div>
            <br/>
            8．	请确认虚拟货币是否已成功汇出。单击[Wallet]→[Wallets]，然后移至以下屏幕。并检查。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-5.png"} width="100%"/>
            </div>
            <br/>
            9。 最后，将资金转入Lending Account进行贷款。
             <br/>为了进行借出，代币必须在借出帐户中。
             <br/>单击[BALANCE]→[TRNSFER BALANCES]。
             <br/>请在下面的[Tranfer]框中输入货币金额。
             <br/>请选择您要移动的货币。 在这种情况下，它是BTC。
             <br/>选择源帐户和目标帐户[Lending]。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start-6.png"} width="100%"/>
            </div>
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowToStartPoloniexcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowToStartPoloniexcn;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';



const WhatPurpose = props => {
  

  return (

    <div>
     
        <Alert severity={"info"}>
                  <Typography>
                  
                  It is possible to lend Bitcoins as well as other cryptocurrencies at very lucrative interest rates and with limited risk at exchanges like Poloniex, Bitfinex or Liquid. 
                  The difficulties in doing this manually are:
                  <br/>
                    <ui>The interest rates are very volatile.</ui>
                    <ui>The loans can be paid back by the borrower at any time.</ui>
                    <br/>
                  To achieve the best possible revenue, it is necessary to log in at regular intervals to manage and renew the loans. On these platforms, 
                  there is an auto-renew functionality, that renews loans automatically, if they are paid back. 
                  The renewed loans are created with the same interest rate as the previous one. 
                  That is why they are now probably above or below the current optimal interest rate. 
                  Therefore, the loans are priced too low and potential interest is thrown away or the loans are priced too high and will not be taken by borrowers. 
                  Lenders also miss out on interest during the time when the loan is not taken.
          
          
                    <br/>
                  <Divider />    
                  This is where the xLending bot comes into play. The fully automated bot works 24 hours a day to lend out all your funds for the best possible rates.
                  </Typography>
                </Alert>
    </div>

    
  );
};

WhatPurpose.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatPurpose;

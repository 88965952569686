//import React, { useRef } from 'react';
import React, { useState, useEffect, useRef,  } from "react";
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import '@icon/cryptocurrency-icons/cryptocurrency-icons.css'
import palette from 'theme/palette';
import moment from 'moment';
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  Grid,
  TableHead,
  CardHeader,
  Avatar,
  TableBody
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,2,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,0,0),
    }
  },
  content: {
    marginTop: theme.spacing(1),
    maxHeight: 600,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    }
  },
  contentgraph: {
    marginTop: theme.spacing(1),
    maxHeight: 600,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    }
  },
  table: {
    height: 3,
    border: 1,
    //backgroundColor: '#eaeaf0',
    //borderRadius: 1,
  },
  chartContainer: {
    height: 500,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    }
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  grid: {
    padding: theme.spacing(0,1,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,1,0),
    }
  },

}));



function getAvatarSource(exchange) {
  //alert(exchange.toUpperCase())
  let avatarUrl = ''
  if (exchange.toUpperCase() === "POLONIEX") {
    avatarUrl = '/images/avatars/poloniex.png'
  }
  else if (exchange.toUpperCase() === "BITFINEX") {
    avatarUrl = '/images/avatars/bitfinex.png'
  }
  else if (exchange.toUpperCase() === "COMPOUND") {
    avatarUrl = '/images/avatars/compoundlog.png'
  }
  else if (exchange.toUpperCase() === "DYDX") {
    avatarUrl = '/images/avatars/dydx.png'
  }
  else if (exchange.toUpperCase() === "FULCRUM") {
    avatarUrl = '/images/avatars/fulcrum.png'
  }
  else if (exchange.toUpperCase() === "MAKER") {
    avatarUrl = '/images/avatars/maker.png'
  }
  else if (exchange.toUpperCase() === "NUO NETWORK") {
    avatarUrl = '/images/avatars/nuo.png'
  }
  return avatarUrl
}




const Bitfinex  = props => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { user } = useAuth()
  const [rates, setRates] = useState([])
  const [paymentdata, setPaymentdata] = useState([])
  const [paymentlabel, setPaymentlabel] = useState([])
  const [selectedSymbol, setSelectedSymbol] = React.useState("");
  
  const [cacheColl, setCacheColl] = useState([])

  // const getDateRange = () => {

  //   let date = new Date();
  //   date.setHours(0, 0, 0, 0);
  
  //   var today = new Date();
  //   today.setHours(24, 0, 0, 0);
  //   var firstDay = new Date(today)
  //   firstDay.setDate(today.getDate() - 30);
  //   let fromDate = firstDay
  
  //   return {fromDate, today}
  // }

  useEffect(() => {
    
        let today = new Date()
        today.setHours(0, 0, 0, 0);
        const unsubscribe = firebase
        .firestore()
        .collection('tickers')
        .where('type', '==', "f")
        .where('exchange', 'in', ['bitfinex', 'poloniex', 'Compound'])
        .where('ticker_time', '>=', today)
        .orderBy("ticker_time", "desc")
        .onSnapshot((snapshot) => {
          
          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            key: doc.data().ticker_time.seconds * 1000,
            ...doc.data()
          }))
          let myRates = []

          //console.log(newValues)
          // console.log(newValues.length)

          newValues.sort(xlendingUtils.compareValues('key', 'asc'));

          newValues.forEach(r => { 
            
            if(!r.symbol.includes('TEST')){
              let annAsk = 0.00
              let finalrate = 0.00
              if(r.last !== null){
                finalrate = r.last
              }else if(r.ask !== null){
                finalrate = r.ask
              }
  
              if(finalrate > 0){
                if(r.exchange === "bitfinex" || r.exchange === "poloniex"){
                  annAsk = xlendingUtils.getAnnualRate(finalrate)
                }else{
                  annAsk = xlendingUtils.roundTo(finalrate * 100, 2)
                }
                if(annAsk !== Infinity){
                  myRates.push({
                    symbol: r.symbol,
                    exchange: r.exchange,
                    rate: annAsk
                  })
                }
              }
            }
            
          })

          // array is sorted by band in descending order
          myRates.sort(xlendingUtils.compareValues('rate', 'desc'));

          setSelectedSymbol(myRates[0].symbol)
          //setSelectedExchange(myRates[0].exchange)
          setRates(myRates);

        })
        return () => { unsubscribe() }
      
  }, [])

  
  const getIcons = (symbol) => {
    //let iconname = "crypto crypto-" + symbol.toLowerCase()

    let finalsymbol = symbol
    if(symbol === "BCHSV"){
      finalsymbol = "BSV"
    }
    if(symbol === "BCHABC"){
      finalsymbol = "BCH"
    }
    if(symbol === "DSH"){
      finalsymbol = "DASH"
    }
    if(symbol === "STR"){
      finalsymbol = "XLM"
    }
    if(symbol === "UST"){
      finalsymbol = "USDT"
    }

    let iconname = "crypto crypto-" + finalsymbol.toLowerCase()
    return <i className={iconname} ></i>
  }

  var numberWithCommas = function(x) {
    return xlendingUtils.roundTo(Number(x).toPrecision(), 2) + "%"
  };

  useEffect(() => {
    getData("BTC", "bitfinex")
  }, [])

  const getData = (mySymbol, myEx) => {
    
        let dateRange = xlendingUtils.getDate30DRange()
    
        const unsubscribe = firebase
        .firestore()
        .collection('tickers')
        .where('type', '==', "f")
        .where('symbol', '==', mySymbol)
        .where('exchange', '==', myEx)
        .where("ticker_time", '>=', dateRange.fromDate)
        .where("ticker_time", '<=', dateRange.today)
        .orderBy("ticker_time", "desc")
        .limit(30)
        .onSnapshot((snapshot) => {

          const newValues = snapshot.docs.map((doc) => ({
            id: doc.id,
            key: doc.data().ticker_time.seconds * 1000,
            ...doc.data()
          }))
          //console.log(newValues)
          // console.log(newValues)
          // console.log(newValues.length)

          newValues.sort(xlendingUtils.compareValues('key', 'asc'));
          
          let myPayLabel = []
          let myPayData = []
          let forCacheData = []

          newValues.forEach(r => { 
            let d = moment(new Date(r.ticker_time.seconds * 1000)).format('L')
            myPayLabel.push(d)

            let finalrate = 0.00
            let annAsk = 0.00
            if(r.last !== null){
              finalrate = r.last
            }else if(r.ask !== null){
              finalrate = r.ask
            }

            if(r.exchange === "bitfinex" || r.exchange === "poloniex"){
              annAsk = xlendingUtils.getAnnualRate(finalrate)
            }else{
              annAsk = xlendingUtils.roundTo(finalrate * 100, 2)
            }
            //let annAsk = getAnnualRate(r.last)
            //console.log(annAsk)
            myPayData.push(annAsk)
            
            forCacheData.push({
              symbol: r.symbol,
              exchange: r.exchange,
              rate: annAsk,
              label: d
            })
          })

          cacheColl.forEach(r => {
            forCacheData.push({
              symbol: r.symbol,
              exchange: r.exchange,
              rate: r.rate,
              label: r.label
            })
          });
          
          setCacheColl(forCacheData)

          //console.log(myPayData)
          setPaymentdata(myPayData)
          setPaymentlabel(myPayLabel)
          
        })
        return () => { unsubscribe() }
  }
  
  const datanew = {
    labels: paymentlabel,
    datasets: [
      {
        label: "Rate",
        backgroundColor: palette.primary.main,
        data: paymentdata,
        fill: false,
        lineTension: 0.1,
        //backgroundColor : gradientStroke, 
        //backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: palette.primary.main,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: palette.primary.main,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: palette.primary.main,
        pointHoverBorderColor: palette.primary.main,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function(tooltipItem, data) { 
          return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.value);
        }
      }
     },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          // barPercentage: 0.5,
          // categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(value) { return numberWithCommas(value); },
           }, 
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    }
  };

  const handleChangeGraph = (symbol, exc) => event => {
    
    setSelectedSymbol(symbol)
    let existcol = cacheColl.filter(x=>x.symbol === symbol && x.exchange === exc)

    //console.log(existcol)
    if(existcol.length === 0){
      console.log("read from firestore")
      getData(symbol, exc)
    }else{

      console.log("read from cache")
      let label = []
      let data = []
      
      existcol.forEach(e => {
        label.push(e.label)
        data.push(e.rate)
      });

      setPaymentlabel(label)
      setPaymentdata(data)
    }

  };

  return (
    <Grid
        className={classes.root}
        container
        spacing={1}
      >
        <Grid
          item
          lg={5}
          md={5}
          xl={7}
          xs={12}
        >
          <Card
          >
              <CardContent className={classes.content}>
              <Table 
                className={classes.table}
              >
              <TableHead>
                <TableRow>
                  
                  <TableCell>{}</TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell className={classes.cell}>{t('botsetup.exchange')}</TableCell>
                  <TableCell align="right">{t('botsetup.annualrate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {rates.map((rate, i) => ( 
                <TableRow
                    hover
                    key={i}
                    onClick={handleChangeGraph(rate.symbol, rate.exchange)}
                  >
                    <TableCell>
                    <Typography gutterBottom variant="h3" component="h3">
                        {getIcons(rate.symbol)}
                      </Typography> 
                    </TableCell>
                    <TableCell>
                        {rate.symbol}
                    </TableCell>
                    <TableCell>
                      <Avatar
                            className={classes.avatar}
                            src={getAvatarSource(rate.exchange)}
                          >
                            {rate.exchange}
                          </Avatar>  
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {rate.exchange.toUpperCase()}
                    </TableCell>
                    <TableCell align="right">
                        {rate.rate}% 
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>


              </CardContent>
           
          </Card>
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          xl={7}
          xs={12}
        >
          <Card
          >
            

            
            <CardHeader
              title={selectedSymbol}
              avatar={
                  <Typography gutterBottom variant="h1" component="h1">
                        {getIcons(selectedSymbol)}
                      </Typography> 
              }
              subheader={"Historic Lending Rates"}
              titleTypographyProps={{variant:'h4' }}
              subheaderTypographyProps={{variant:'h6' }}
            />
              <CardContent className={classes.contentgraph}>
              <div className={classes.chartContainer}>
                <Line
                  data={datanew}
                  options={options}
                  //onChange={handleChangeEdit}
                />
              </div>


              </CardContent>
            
            
          </Card>
        </Grid>
        
      </Grid>
    
  );
};

export default Bitfinex;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import QuickGuide from './QuickGuide/QuickGuide'

import {
  Card,
  Typography,
  Grid,
  CardMedia,
  Divider,
  Tooltip,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    }
  },
  quoteContainer: {
    // [theme.breakpoints.down('md')]: {
    //   display: 'none'
    // }
  },
  quote: {
    padding: theme.spacing(3, 3, 3),
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteText: {
    color: theme.palette.white,
    textAlign: 'center',
    fontWeight: 300
  },
  quoteInner: {
    padding: theme.spacing(3, 3, 3),
    textAlign: 'center',
    color: theme.palette.white,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  card: {
    padding: theme.spacing(1, 1, 1),
    justifyContent: 'center',
    alignItems: 'center',
 },
//  grid: {
//   padding: theme.spacing(1, 1, 1),
//   justifyContent: 'center',
//   alignItems: 'center',
// },
 div: {
  padding: theme.spacing(5,5,5,5),
  backgroundImage: 'url(/images/automation.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1,1,1,1),
  }
},
textWhite: {
  color: theme.palette.white,
  //fontWeight: 300
},
actions: {
  justifyContent: 'flex-end'
}

}));



const LearnStart = props => {
  //const { className  } = props;
  
  const classes = useStyles();
  const { t } = useTranslation()
  const ref = useRef(null);
  
 
  return (

    
    <div className={classes.root}>
      
      
          <QuickGuide />
            
         
      <Divider />
    </div>

    
  );
};

LearnStart.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LearnStart;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const HowPoloniexcn = props => {
  
  

  const classes = useStyles();

  return (

    <div>
   
        
        <Alert severity={"info"}>
        <Typography>
            1．	登录Poloniex主页 https://www.poloniex.com/
            <br/>
            2．	点击下面的［API Keys］
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-1.png"} width="100%"/>
            </div>
            3．	点击下面的[Enable API]
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-2.png"} width="100%"/>
            </div>
            <br/>
            4. 单击后，经过两步验证，电子邮件验证等后，转到以下屏幕。然后单击[Create New Key]。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-3.png"} width="100%"/>
            </div>
            <br/>
            5．	[重要]请进行以下设置。 选中[Enable Trading]。 通过如下设置，该API不能在系统外部使用。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-4.png"} width="100%"/>
            </div>
            <br/>
            6．[重要]请务必保留此API Key和API secret的记录。您不能在Bitfinex主页上看到它。
            <br/>
            
            7．	接下来，返回到xLending系统的API设定
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
           
            9．	输入您的Poloniex API key和secret，如下所示。
            <br/>
            
            <div className={classes.image}>
              <img alt="" src={"/images/products/poloniex_start0-6.png"} width="100%"/>
            </div>
            <br/>
            10．输入Bitfinex APIAPI key和secret后，单击[测试BITFINEX]。
            <br/>

            11．[重要]最后，单击[更新]。
            <br/>
            
            </Typography>
          </Alert>
              
    </div>

    
  );
};

HowPoloniexcn.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowPoloniexcn;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
} from '@material-ui/core';




const WhatMinThreshold = props => {

  return (

    <div>
        
        <Alert severity={"info"}>
                <Typography>
                  Yes, the Bitfinex API expects an equivalent token amount of $50. 
                  We add an additional buffer on top of it to compensate for price fluctuations. 
                  This means the bot can only create a loan offer if you have $55 or more in your funding wallet. 
                  For Poloniex, the following are the minimum thresholds:
                  <br/>
                    Bitcoin: 0.01 BTC<br/>
                    Ethereum: 0.1 ETH<br/>
                    Dash: 0,1 DASH<br/>
                    Clams: 5 CLAM<br/>
                    Factom: 5 FCT<br/>
                    Stellar: 100 STR<br/>
                    Dogecoin: 100 DOGE<br/>
                    Bitcoin Cash: 0.1 BAB<br/>
                    Bitcoin SV: 0.1 BSV<br/>

                  </Typography>
                </Alert>
    </div>

    
  );
};

WhatMinThreshold.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default WhatMinThreshold;

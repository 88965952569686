import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import { StatusBullet } from 'components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Setting from '../Setting/Setting'
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardContent,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  CardHeader,
  Avatar,
  
} from '@material-ui/core';

const statusColors = {
  true: 'success',
  false: 'danger'
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    height: "100%"
 
  },
  content: {
    marginTop: theme.spacing(2)
  },
  tablecell: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  }
}));


const BotAvailables = props => {
  const { className, userplan, subsPlan, symbols,  ...rest } = props;
  const classes = useStyles();
  //const { className, ...rest } = props;
  const { t } = useTranslation()
  const ref = useRef(null);

  // const { user } = useAuth()
  
 
  const handleClick = (exchange) => event => {

    ref.current.handleOpen(exchange);
  }

  const getSymbolCollection = () => {

    const mysymbols = []
    if(symbols === undefined) return mysymbols

    symbols.forEach(s => {
      if(s.enable === true){
        mysymbols.push({
          symbol: s.code,
          strategy: s.strategy,
          atLeastLow : s.offer.atLeastLow,
          exchange : s.exchange
        })
      }
    });
  
    //console.log(mysymbols)
    return mysymbols

  }

  // const getIcons = (symbol) => {
    
  //   let iconname = "crypto crypto-" + symbol.toLowerCase()
  //   return <i class={iconname} ></i>
  // }

 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Setting 
        ref={ref} 
        subsPlan={subsPlan}
        userplan={userplan}
        symbols={symbols}
        />

      <Card>
      <CardHeader 
        title= {t('totalbotactive.label')}
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
        avatar={
            <Avatar
              className={classes.avatar}
              //src='/images/avatars/poloniex.png'
            >
                <StatusBullet
                        className={classes.status}
                        color={statusColors[true]}
                        size="md"
                      />
            </Avatar>  
        }
      />

        <CardContent>
        <PerfectScrollbar>
        <Table>
            <TableHead>
              <TableRow>
              <TableCell>{t('botsetup.exchange')}</TableCell>
              {/* <TableCell>{""}</TableCell> */}
                <TableCell>{t('botsetup.symbol')}</TableCell>
                <TableCell className={classes.tablecell}>{t('botsetup.strategy')}</TableCell>
                <TableCell className={classes.nameContainer}>{t('botsetup.mininterest')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSymbolCollection().map((s, i) => (
                  <TableRow
                  hover
                  key={s.symbol + i}
                  onClick={handleClick(s.exchange)}
                  >
                  <TableCell className={classes.nameContainer}>
                          <Avatar
                              className={classes.avatar}
                              src={xlendingUtils.getAvatarSource(s.exchange)}
                            ></Avatar>
                    </TableCell>
                  <TableCell align="left">
                      {s.symbol}
                  </TableCell>
                  
                  <TableCell className={classes.tablecell}>{s.strategy}</TableCell>
                  <TableCell className={classes.nameContainer}>{s.atLeastLow}%</TableCell>
                  
                            
                </TableRow>
                
              ))}
            </TableBody>
          </Table>
          </PerfectScrollbar>
        </CardContent>
      </Card>
        
        
    </Card>
  );
};

export default BotAvailables;

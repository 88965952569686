import React, { useState, useEffect, useRef  } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Avatar, LinearProgress, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withRouter } from 'react-router-dom';
import PopupMessage from '../../../PopupMessage/PopupMessage'



const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

function getCounts(_symbols) {

  var active = 0;
  var inactive = 0;
  for (var i = 0; i < _symbols.length; i++) {
    if (_symbols[i].enable) {
      active = active + 1
    } else {
      inactive = inactive + 1
    }
  }
  return { active, inactive }
}

const TotalBots = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { t } = useTranslation()
  const { user } = useAuth()
  const ref = useRef(null);

  let { uid: userId } = user || {}
  const [values, setValues] = useState({
    active: 0,
    inactive: 0
  })
  const [isLoading, setIsLoading] = React.useState(true);
  

  useEffect(() => {
    
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        setIsLoading(true)
        userId = myuser.uid
        const unsubscribe = firebase
          .firestore()
          .collection('symbols_config')
          .where('user', '==', userId)
          //.where('pay_time', '>=', firstDay.toLocaleDateString())
          //.where('pay_time', '<=', lastDay)
          .onSnapshot((snapshot) => {
            const newValues = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))

            let totalCount = getCounts(newValues)
            setValues({
              active: totalCount.active,
              inactive: totalCount.inactive
            })
            setIsLoading(false)
          })
          return () => { unsubscribe() }
        }
      })
      
  }, [])

  const getLabelCondition = () => {

    if(isLoading){
      return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    }else{
      //return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
      return t('totalbotactive.label') + ": " + values.active
    }
  }

  const getInactiveLabelCondition = () => {

    if(isLoading){
      return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
    }else{
      //return <div className={classes.chartContainer}><LinearProgress color="secondary" /></div>
      return  t('totalbotinactive.label') + ":" + values.inactive
    }
  }

  const handleClick = event => {

    props.history.replace('/botsetup')
  }

  const handleClickHelp = e => {
    e.preventDefault();  
    let desc = t('botsetup.botdesc') //"The estimated profit is calculated based on accumulation of interest received in \n\n 'Local Symbol Amount' x 'BTC spot rate on the day' for the month x 'latest today JPY or USD rate'"
    ref.current.handleOpen(desc, "info");
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
      <CardHeader
        title= {getLabelCondition()}
        action={
          <IconButton 
            aria-label="settings"
            onClick={handleClickHelp}
            >
            <MoreVertIcon color="secondary" fontSize="small"/>
          </IconButton>
        }
        subheader={getInactiveLabelCondition()}
        avatar={
          <Tooltip title={t("sidebar.botsetup")} arrow='true'>
          <Avatar 
            onClick={handleClick}
            className={classes.avatar}>
            <AirplanemodeActiveIcon className={classes.icon}/>
          </Avatar>
          </Tooltip>
        }
        titleTypographyProps={{variant:'h4' }}
        subheaderTypographyProps={{variant:'body1' }}
      />
     
    </Card>
  );
};

TotalBots.propTypes = {
  className: PropTypes.string
};

export default withRouter(TotalBots);
import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import appGlobalConfig from "appGlobalConfig"

import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import firebase from "firebase/app";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PopupMessage from '../PopupMessage/PopupMessage'
import Circular from '../Circular/Circular'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const auth = useAuth()
  const { user } = useAuth()
  // const { uid: userId } = user || {}
  const classes = useStyles();

  const { t } = useTranslation()
  const [openVerify, setOpenVerify] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [popupmessage, setPopupmessage] = React.useState("");
  const [emailReset, setEmailReset] = React.useState("");
  // const [openProgress, setOpenProgress] = React.useState(false);
  
  const ref = useRef(null);
  const refCircular = useRef(null);
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (myuser && myuser.emailVerified) {
        props.history.replace('/dashboard')
        return
    }
  });

  
  const handleMessageOpen = (msg, status) => {
    //console.log(msg)
    ref.current.handleShowImage(true, "/images/products/startstop.png");
    ref.current.handleOpen(msg, status);
  }

  const handleClickOpen = (msg) => {
    //ref.current.handleOpen(msg);
    setOpen(true);
  };

  const handleClickOpenVerify = () => {
    //console.log("verifying open")
    setOpenVerify(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseVerify = () => {
    setOpenVerify(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  
  // const handleCloseProgress = () => {
  //   setOpenProgress(false);
  // };
  

  const handleSignIn = event => {
    event.preventDefault();
    
    refCircular.current.handleOpen(true)

    auth.signin(formState.values.email, formState.values.password)
      .then(response => {
        
        //console.log(response)
        if(response === undefined){
          refCircular.current.handleOpen(false)
          return
        }else{
          //console.log(response.emailVerified)
          if(response.emailVerified){
            props.history.replace('/dashboard')
          }else{
            handleClickOpenVerify()
          }
        } 

      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const handleForget = e => {
    
    e.preventDefault();
    let msg = t('signup.reset.msg') +" : "+ t('account.email')
    setPopupmessage(msg)
    handleClickOpen()
    
  }

  const handleClickSendEmail = e => {
    
    e.preventDefault();
    //console.log(emailReset)
    
    if(emailReset === ""){
      let msg = t('common.msg.blank') +" : "+ t('account.email')
      setOpen(false)
      handleMessageOpen(msg, "error")
      return
    }

    let actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: appGlobalConfig.actionCodeSettingsURL, // 'https://cryptolending-7b810.firebaseapp.com/sign-in',
      handleCodeInApp: false
    };

    firebase.auth().sendPasswordResetEmail(emailReset, actionCodeSettings)
      .then(result => {
        //console.log(result)
        handleClickOpen(t('signup.reset.msg'))
        handleClose()
      })
      .catch(error => {
        setOpen(false)
        handleMessageOpen(error.message, "error")
        //alert(error.message);
      });
  }

  const handleChangeEmailReset = event => {
    event.preventDefault();

    setEmailReset(event.target.value)
    
  };
  
  const handleClickSendVerification = event => {
    event.preventDefault();

    let actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: appGlobalConfig.actionCodeSettingsURL, // 'https://cryptolending-7b810.firebaseapp.com/sign-in',
      handleCodeInApp: false
    };

    auth.sendEmailVerification(actionCodeSettings)
    .then(response => {
      //console.log(response)
      if(response === "ok"){
          handleMessageOpen(t('signup.verify.msg'), "info")
          //alert(t('signup.verify.msg')) 
          setOpenVerify(false)
      }
    })  
    
  };

  

  return (
    <div className={classes.root}>
      <PopupMessage ref={ref} />
      <Circular ref={refCircular} />

      <Dialog
        open={openVerify}
        keepMounted
        fullWidth={true}
        maxWidth = {'md'}
        onClose={handleCloseVerify}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('signup.verify.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {t('signup.verification')}
              
          </DialogContentText>
        </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleCloseVerify} 
              color="primary"
              startIcon={<CancelIcon />}>
            {t('common.cancel.button')}
            </Button>
            <Button 
              onClick={handleClickSendVerification} 
              color="primary"
              startIcon={<VerifiedUserIcon />}>
            {t('common.verify.button')}
            </Button>
          </DialogActions>
        </Dialog>
        
      <Dialog
        open={open}
        keepMounted
        fullWidth={true}
        maxWidth = {'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('signin.resetpassword')}
          {/* {"Reset Password"} */}
          <IconButton 
                color="primary"
                //onClick={handleClickOpenGuide}
              >
                <VpnKeyIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('signin.inputemail')}
            {/* Please Input Your Email Address */}

            </DialogContentText>
            <Table>
              <TableRow className={classes.tableRow}>
                <TableCell>

                  <div className={classes.nameContainer}>
                    
                    <Typography variant="body1">
                    <TextField
                      className={classes.textField}
                      error={hasError('resetemail')}
                      fullWidth
                      helperText={
                        hasError('resetemail') ? emailReset : null
                      }
                      label={t('account.email')}
                      name="resetemail"
                      onChange={handleChangeEmailReset}
                      type="text"
                      value={emailReset || ''}
                      variant="outlined"
                    />

                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              color="primary"
              startIcon={<CancelIcon />}>
              {t('common.cancel.button')}
            </Button>
            <Button 
              onClick={handleClickSendEmail} 
              color="primary"
              startIcon={<LockOpenIcon />}>
                {t('common.reset.button')}
            </Button>
          </DialogActions>
        </Dialog>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >

                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  {t('signin.signin')  }
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  {t('signin.signinemail')  }
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  {/* <Grid item>
                   <Button
                   color="primary"
                   onClick={handleSignIn}
                   size="large"
                   variant="contained"
                   >
                   <FacebookIcon className={classes.socialIcon} />
                   Login with Facebook
                   </Button>
                   </Grid>
                   <Grid item>
                   <Button
                   onClick={handleSignIn}
                   size="large"
                   variant="contained"
                   >
                   <GoogleIcon className={classes.socialIcon} />
                   Login with Google
                   </Button>
                   </Grid> */}
                </Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  {/* or login with email address */}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={t('signin.email')}
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label={t('signin.password')}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in now
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {t('signin.donthaveaccount')}
                  {/* Don't have an account?{' '} */}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    {t('signin.signup')}
                    {/* Sign up */}
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {t('signin.forgetpassword')}
                  {/* Forget Password?{' '} */}
                  <Button 
                    color="primary"
                    onClick={handleForget}
                    >
                      {t('common.reset.button')}
                      {/* Reset */}
                  </Button>
                  
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Alert } from '@material-ui/lab';
import Popover from 'react-bootstrap/Popover'
import xlendingUtils from 'xlendingUtils'

import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useAuth } from "use-auth.js";


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 250,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));



const LatestOffers = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()


  let { uid: userId } = user || {}
  const [payments, setPayments] = useState([])
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
          const unsubscribe = firebase
            .firestore()
            .collection('offers')
            .where('user', '==', userId)
            .where('status', '==', "ACTIVE")
            //.orderBy("created_at", "desc")
            //.limit(10)
            .onSnapshot((snapshot) => {
              const newValues = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
              }))
              //console.log(newValues)
              //setValues(newValues);

              let newPays = []
              newValues.forEach(p => {
                  newPays.push({
                    id: p.id,
                    symbol: p.symbol,
                    exchange: p.exchange,
                    amount: p.amount,
                    status: p.status,
                    rate: p.rate,
                    created_at: moment(new Date(p.created_at.seconds * 1000)).format('lll')
                  })
              });

              if(newPays.length < 3){
                let remaincount = 3-newPays.length
                for(let x=0; x< remaincount; x++){
                  newPays.push({
                    id: x,
                    symbol: "",
                    exchange: "",
                    amount: 0,
                    status: "",
                    rate: 0,
                    created_at: ""
                  })
                } 
              }
              setPayments(newPays)

            })
            return () => { unsubscribe() }
          }
      })
  }, [])
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = name => event => {
    if (payments.length > getTotalShow()) {
      setOpen(true);
    }
  };

  const getTotalShow = () => {

    let totalShow = 3
    if (payments.length <= totalShow) {
      return payments.length
    }
    return totalShow
  }

  const popover = (p) => (

    <Popover id="popover-basic" className={classes.overlay}>
        <Popover.Content>
          <Alert severity="info">
          <div>
            <div>{t('trades.rate')}: {xlendingUtils.toPercent(p.rate, 4)}</div>
            <div>{t('trades.tradedate')}: {p.created_at}</div>
          </div>
          </Alert>
        </Popover.Content>
      </Popover>
  );
  

  
  return (
    <div>
<Dialog 
        open={open} 
        fullWidth={true}
        maxWidth = {'md'}
        keepMounted
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <CardHeader
            title={t('offers.top5latestoffer')}
          />
            <Divider />
            {/* <PerfectScrollbar> */}
            
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('botsetup.exchange')}</TableCell>
                    <TableCell>{t('botsetup.symbol')}</TableCell>
                    <TableCell>{t('trades.amount')}</TableCell>
                    <TableCell className={classes.cell}>{t('trades.rate')}</TableCell>
                    <TableCell className={classes.cell}>{t('trades.tradedate')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((p, i) => (
                    <TableRow
                        hover
                        key={p.id}
                      >
                      <TableCell>
                        <Avatar
                          className={classes.avatar}
                          src={xlendingUtils.getAvatarSource(p.exchange)}
                        >
                          {p.exchange===""? "N":p.exchange}
                        </Avatar>  
                        
                        
                      </TableCell>
                      <TableCell>{p.symbol}</TableCell>
                      <TableCell>{xlendingUtils.roundTo(p.amount, 4)}</TableCell>
                      <TableCell className={classes.cell}>{xlendingUtils.toPercent(p.rate, 4)}</TableCell>
                      <TableCell className={classes.cell}>{p.created_at}</TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          
          {/* </PerfectScrollbar> */}


          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary"
            startIcon={<CancelIcon />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>


    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
            <CardContent className={classes.content}>
          
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('botsetup.exchange')}</TableCell>
                  <TableCell>{t('botsetup.symbol')}</TableCell>
                  <TableCell>{t('trades.amount')}</TableCell>
                  <TableCell className={classes.cell}>{t('trades.rate')}</TableCell>
                  <TableCell className={classes.cell}>{t('payments.paydate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {payments.slice(0, getTotalShow()).map((p) => (
                /* {payments.map((p, i) => ( }*/
                  <OverlayTrigger  key={p.id} trigger="hover" placement="right" overlay={popover(p)}>

                    <TableRow
                        key={p.id}
                        hover
                      >
                      <TableCell>
                        <Avatar
                          className={classes.avatar}
                          src={xlendingUtils.getAvatarSource(p.exchange)}
                        >
                          {p.exchange===""? "N":p.exchange}
                        </Avatar>  
                        
                        
                      </TableCell>
                      <TableCell>{p.symbol}</TableCell>
                      <TableCell>{xlendingUtils.roundTo(p.amount, 4)}</TableCell>
                      <TableCell className={classes.cell}>{xlendingUtils.toPercent(p.rate, 4)}</TableCell>
                      <TableCell className={classes.cell}>{p.created_at}</TableCell>
                      
                    </TableRow>
                  </OverlayTrigger> 
                ))}
              </TableBody>
            </Table>
         
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={handleClickOpen()}
        >
        View all <ArrowRightIcon />
        </Button>
        </CardActions>
    </Card>
    </div>

  );
};

LatestOffers.propTypes = {
  className: PropTypes.string
};

export default LatestOffers;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert,  } from '@material-ui/lab';

import {
  Typography,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0,0,0)
  },
  image: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      //display: 'none'
      padding: theme.spacing(0),
    }
  },
}));



const HowBitfinex = props => {
  
  const classes = useStyles();

  return (

    <div className={classes.root}>
        
        <Alert severity={"info"}>
            <Typography>
            1．	Bitfinexのホームページにログインする　https://www.bitfinex.com/
            <br/>
            2．	下記、［API Keys］をクリックする
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-1.png"} width="100%"/>
            </div>
            3．	下記、［Create New Key（新しいキーを作成）］をクリックする
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-2.png"} width="100%"/>
            </div>
            <br/>
            4.【重要】下記のように設定してください。Margin FundingをReadにする／ Margin FundingをWriteにする／WalletsをReadにする。下記のように設定すると、APIがシステム外で使用できないようになります。
            <br/>
            5．	上記の［Label Your API Key（名前をください）］に xLendingと入力してください。
            <br/>
            6．	上記の［Generate API Key（API Keyを発行する）］を押し、API Keyを発行する。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-3.png"} width="100%"/>
            </div>
            <br/>
            6．下記のように発行されます。【重要】ここで発行されたAPI KeyとAPI Key Secretは、必ず、お手元に記録をしてください。Bitfinexのホームページ上では2度と確認できません。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-4.png"} width="100%"/>
            </div>
            <br/>
            7．	次に、xLendingのダッシュボードに戻ります。
            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-5.png"} width="100%"/>
            </div>
            <br/>
            8．	上記のように、 xLendingダッシュボードの［APIの設定］をクリックします。
            <br/>
            9．	下記にように、Bitfinex APIキーとシークレットコードを入力します。
            <br/>
            ＊QRコードリーダーを活用できます。特に、スマートフォンやパットで操作をされているかたは便利です。 QRコードをスキャンすると、APIキーとシークレットキーが自動的に入力されます

            <br/>
            <div className={classes.image}>
              <img alt="" src={"/images/products/bitfinex_start1-6.png"} width="100%"/>
            </div>
            <br/>
            10．Bitfinex APIキーとシークレットコードを入力したら、［テストBITFINEX］をクリックしてください。
            <br/>

            11．【重要】最後に、［更新］をクリックしてください。
            <br/>
            12．BitfinexにてAPIを設定し、xLendingと連結が終了しました。

            <br/>


            
            </Typography>
          </Alert>
        
    </div>

    
  );
};

HowBitfinex.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default HowBitfinex;
